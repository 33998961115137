import { TabsProps } from 'antd';
import { IServiceComplex, ServiceFormTabTypes, ServiceType } from 'features/Service/index';
import { UITabs } from 'features/UI';
import { useMemo } from 'react';
import { useStationRoles } from '../../../Station/Station.hooks';
import { useServiceIsAdmin } from '../../Service.hook';
import { ServiceComplexBasicForm } from '../ServiceComplexBasicForm/ServiceComplexBasicForm';
import { ServiceStationTable } from '../ServiceStationTable/ServiceStationTable';

export function ServiceComplexForm({ serviceType, model, onFinish, onRemove }: {
  serviceType: ServiceType;
  model?: IServiceComplex;
  onFinish: (values: IServiceComplex) => any;
  onRemove?: () => any;
}) {
  const isAdmin = useServiceIsAdmin(serviceType);
  const stationRoles = useStationRoles();

  const tabs = useMemo((): TabsProps['items'] => [
    {
      key: ServiceFormTabTypes.BasicForm,
      label: 'Основные',
      children: (
        <ServiceComplexBasicForm
          model={model}
          isAdmin={isAdmin}
          onFinish={onFinish}
          onRemove={onRemove}
        />
      ),
    },
    {
      key: ServiceFormTabTypes.StationTable,
      label: 'Вокзалы',
      disabled: !model || stationRoles.length === 0,
      children: (
        <ServiceStationTable
          serviceType={serviceType}
          serviceSlug={model?.slug}
          serviceId={model?.id}
        />
      ),
    },
  ], [isAdmin, model, onFinish, onRemove, serviceType, stationRoles]);

  return (
    <UITabs
      defaultActiveKey={ServiceFormTabTypes.BasicForm}
      items={tabs}
    />
  );
}
