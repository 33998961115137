import { App } from 'antd';
import { LayoutContent } from 'features/Layout';
import { UseParamsWithId } from 'features/Router';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useNewsLoadQuery, useNewsUpdateMutation } from '../../News.api';
import { NewsMutationBase } from '../../News.interfaces';
import { NewsForm } from '../NewsForm/NewsForm';

export function NewsEdit() {
  const { id } = useParams<UseParamsWithId>();
  const { message } = App.useApp();
  const navigate = useNavigate();
  const { data, isLoading } = useNewsLoadQuery(id);
  const [newsUpdate] = useNewsUpdateMutation();

  const handleFinish = useCallback(async (values: NewsMutationBase) => {
    await newsUpdate({
      ...values,
      id: data.id,
    }).unwrap();
    message.success('Новость обновлена');
    navigate('/news');
  }, [newsUpdate, data, message, navigate]);

  return (
    <LayoutContent
      title="Редактирование новости"
      backUrl="/news"
      isLoading={isLoading}
      render={() => (
        <NewsForm
          model={data}
          onFinish={handleFinish}
        />
      )}
    />
  );
}
