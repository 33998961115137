import { Menu } from 'antd';
import { featureFlags } from 'App.environment';
import { appMenu } from 'App.model';
import { AuthLogoutButton, useAuthRoles } from 'features/Auth';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export function Navbar() {
  const [selected, setSelected] = useState('');
  const navigate = useNavigate();
  const roles = useAuthRoles();

  const userMenu = useMemo(() => {
    if (featureFlags.isDev) {
      console.log('user roles:', roles);
    }
    return appMenu
      .filter((item) => {
        if (!item.canView) {
          return true;
        }
        return item.canView(roles);
      })
      .map(({ canView, ...rest }) => rest);
  }, [roles]);

  const handleClick = useCallback(({ key, keyPath }) => {
    setSelected(key);
    const path = keyPath.slice().reverse().join('/');
    navigate(`./${path}`);
  }, [navigate]);

  return (
    <div className="px-3 pt-6 pb-4">
      <AuthLogoutButton />
      <Menu
        style={{ border: 'none' }}
        onClick={handleClick}
        className="bg-slate-300 pt-4"
        selectedKeys={[selected]}
        mode="inline"
        items={userMenu}
      />
    </div>
  );
}
