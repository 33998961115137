import { App } from 'antd';
import { LayoutContent } from 'features/Layout';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { objectIsEmpty, objectUpdateDiff } from 'utils/object';
import { ServiceType } from '../../../Service';
import { useServiceItemInfoUpdate, useServiceItemLoad, useServiceItemUpdate } from '../../../Service/Service.hook';
import { serviceCRUDMap } from '../../../Service/Service.model';
import { ISimple } from '../../Simple.interface';
import { SimpleForm } from '../SimpleForm/SimpleForm';

export function SimpleEdit() {
  const { message } = App.useApp();
  const navigate = useNavigate();
  const { slug } = useParams();
  const config = serviceCRUDMap[ServiceType.Simple];
  const { data, isLoading } = useServiceItemLoad(ServiceType.Simple, slug);
  const [serviceUpdate] = useServiceItemUpdate(ServiceType.Simple);
  const [serviceInfoUpdate] = useServiceItemInfoUpdate(ServiceType.Simple);

  const handleFinish = useCallback(async (values: ISimple) => {
    const { info, ...base } = values;
    const { info: infoDiff, ...baseDiff } = objectUpdateDiff(data, values);
    if (!objectIsEmpty(baseDiff)) {
      await serviceUpdate(base).unwrap();
    }
    if (!objectIsEmpty(infoDiff)) {
      await serviceInfoUpdate({
        slug,
        ...info,
      }).unwrap();
    }
    message.success(config.labelEditSuccess);
    navigate('/services');
  }, [config, data, message, navigate, serviceInfoUpdate, serviceUpdate, slug]);

  return (
    <LayoutContent
      title={config.labelEditTitle}
      backUrl="/services"
      isLoading={isLoading}
      render={() => (
        <SimpleForm
          model={data}
          onFinish={handleFinish}
        />
      )}
    />
  );
}
