import { App as AntdApp } from 'antd';
import { appStore } from 'App.store';
import { AuthProvider } from 'features/Auth';
import { Router } from 'features/Router';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { appClientList } from './App.client';
import { appRoutes } from './App.routes';
import { ConfigProvider } from 'antd';
import { appLocale, appTheme } from './App.theme';

export function App() {
  return (
    <ConfigProvider theme={appTheme} locale={appLocale}>
      <AuthProvider clientList={appClientList}>
        <StoreProvider store={appStore}>
          <BrowserRouter>
            <AntdApp className="min-h-full h-full">
              <Router routes={appRoutes} />
            </AntdApp>
          </BrowserRouter>
        </StoreProvider>
      </AuthProvider>
    </ConfigProvider>
  );
}
