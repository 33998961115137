import YouTube from 'react-youtube';
import { Editor } from 'slate';
import { getYoutubeId, isURLYoutube } from 'utils/url';
import { RichTextVoid } from '../components/RichTextVoid/RichTextVoid';
import { ISlateElementYoutube, SlateElementTypes, SlateRenderElementProps } from '../RichText.interface';
import { RichTextEditor } from './RichText.common';

export function RichTextYoutube({ children, element, attributes }: Partial<SlateRenderElementProps<ISlateElementYoutube>>) {
  return (
    <RichTextVoid element={element} attributes={attributes}>
      {children}
      <YouTube videoId={element.id} />
    </RichTextVoid>
  );
}

function createElementYoutube(id: string): ISlateElementYoutube {
  return {
    type: SlateElementTypes.Youtube,
    id,
    children: [{ text: '' }],
  };
}

export function withYoutube(editor: Editor): Editor {
  const { insertText } = editor;

  editor.insertText = (text: string) => {
    // parse youtube link
    if (isURLYoutube(text)) {
      const id = getYoutubeId(text);
      if (id) {
        editor.insertFragment([createElementYoutube(id)]);
        RichTextEditor.moveForward(editor);
        return;
      }
    }

    insertText(text);
  };

  return editor;
}
