import { useMemo } from 'react';
import { UITable } from 'features/UI';
import { useRoleMapLoadQuery } from '../../Role.api';
import { roleTableColumnList } from '../../Role.model';

export function RoleTable() {
  const { data: roleMap, isLoading } = useRoleMapLoadQuery();
  const roleList = useMemo(() => roleMap ? Object.entries(roleMap) : [], [roleMap]);

  return (
    <UITable
      loading={isLoading}
      columns={roleTableColumnList}
      dataSource={roleList}
      pagination={false}
    />
  );
}
