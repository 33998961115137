import { UserOutlined } from '@ant-design/icons';
import { useKeycloak } from '@react-keycloak/web';
import { Button } from 'antd';
import { useCallback } from 'react';

export function AuthLoginButton() {
  const { keycloak } = useKeycloak();

  const handleLogin = useCallback(() => {
    keycloak.login();
  }, [keycloak]);

  return (
    <Button
      size="large"
      type="primary"
      icon={<UserOutlined />}
      onClick={handleLogin}
    >
      Выполнить вход в систему
    </Button>
  )
}
