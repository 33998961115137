import { DropdownMenuProps } from '@radix-ui/react-dropdown-menu';
import { collapseSelection, focusEditor, getNodeEntries, isBlock, toggleNodeType, useEditorRef, useEditorSelector } from '@udecode/plate-common';
import { ELEMENT_H1, ELEMENT_H2, ELEMENT_H3 } from '@udecode/plate-heading';
import { toggleList, unwrapList } from '@udecode/plate-list';
import { ELEMENT_PARAGRAPH } from '@udecode/plate-paragraph';
import React from 'react';
import { DropdownMenu, DropdownMenuContent, DropdownMenuLabel, DropdownMenuRadioGroup, DropdownMenuRadioItem, DropdownMenuTrigger, useOpenState } from './dropdown-menu';
import { Icons } from './icons';
import { ToolbarButton } from './toolbar';

const items = [
  {
    value: ELEMENT_PARAGRAPH,
    label: 'Текст',
    description: 'Текст',
    icon: Icons.Paragraph,
  },
  {
    value: ELEMENT_H1,
    label: 'Заголовок 1',
    description: 'Заголовок 1',
    icon: Icons.H1,
  },
  {
    value: ELEMENT_H2,
    label: 'Заголовок 2',
    description: 'Заголовок 2',
    icon: Icons.H2,
  },
  {
    value: ELEMENT_H3,
    label: 'Заголовок 3',
    description: 'Заголовок 3',
    icon: Icons.H3,
  },
  {
    value: 'ul',
    label: 'Список',
    description: 'Список',
    icon: Icons.UL,
  },
  {
    value: 'ol',
    label: 'Нумерованный список',
    description: 'Нумерованный список',
    icon: Icons.OL,
  },
];

const defaultItem = items.find((item) => item.value === ELEMENT_PARAGRAPH)!;

export function TurnIntoDropdownMenu(props: DropdownMenuProps) {
  const value: string = useEditorSelector((editor) => {
    let initialNodeType: string = ELEMENT_PARAGRAPH;
    let allNodesMatchInitialNodeType = false;
    const codeBlockEntries = getNodeEntries(editor, {
      match: (n) => isBlock(editor, n),
      mode: 'highest',
    });
    const nodes = Array.from(codeBlockEntries);

    if (nodes.length > 0) {
      initialNodeType = nodes[0][0].type as string;
      allNodesMatchInitialNodeType = nodes.every(([node]) => {
        const type: string = (node?.type as string) || ELEMENT_PARAGRAPH;

        return type === initialNodeType;
      });
    }

    return allNodesMatchInitialNodeType ? initialNodeType : ELEMENT_PARAGRAPH;
  }, []);

  const editor = useEditorRef();
  const openState = useOpenState();

  const selectedItem =
    items.find((item) => item.value === value) ?? defaultItem;
  const { icon: SelectedItemIcon, label: selectedItemLabel } = selectedItem;

  return (
    <DropdownMenu modal={false} {...openState} {...props}>
      <DropdownMenuTrigger asChild>
        <ToolbarButton
          pressed={openState.open}
          tooltip="Заменить на"
          isDropdown
          className="bg-white lg:min-w-[130px]"
        >
          <SelectedItemIcon className="size-5 lg:hidden" />
          <span className="max-lg:hidden">{selectedItemLabel}</span>
        </ToolbarButton>
      </DropdownMenuTrigger>

      <DropdownMenuContent align="start" className="min-w-0">
        <DropdownMenuLabel>Заменить на</DropdownMenuLabel>

        <DropdownMenuRadioGroup
          className="flex flex-col gap-0.5"
          value={value}
          onValueChange={(type) => {
            if (type === 'ul' || type === 'ol') {
              // if (settingsStore.get.checkedId(KEY_LIST_STYLE_TYPE)) {
              //   toggleIndentList(editor, {
              //     listStyleType: type === 'ul' ? 'disc' : 'decimal',
              //   });
              // } else if (settingsStore.get.checkedId('list')) {
              toggleList(editor, { type });
              // }
            } else {
              unwrapList(editor);
              toggleNodeType(editor, { activeType: type });
            }

            collapseSelection(editor);
            focusEditor(editor);
          }}
        >
          {items.map(({ value: itemValue, label, icon: Icon }) => (
            <DropdownMenuRadioItem
              key={itemValue}
              value={itemValue}
              className="min-w-[180px]"
            >
              <Icon className="mr-2 size-5" />
              {label}
            </DropdownMenuRadioItem>
          ))}
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
