import { withRef } from '@udecode/cn';
import { useLinkToolbarButton, useLinkToolbarButtonState } from '@udecode/plate-link';
import React from 'react';
import { Icons } from './icons';
import { ToolbarButton } from './toolbar';

export const LinkToolbarButton = withRef<typeof ToolbarButton>((rest, ref) => {
  const state = useLinkToolbarButtonState();
  const { props } = useLinkToolbarButton(state);

  return (
    <ToolbarButton ref={ref} tooltip="Ссылка" {...props} {...rest}>
      <Icons.Link />
    </ToolbarButton>
  );
});
