import { ColumnsType } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import { IRole, IUserRoleTable, RoleEntityType } from './Role.interface';

export const roleTableColumnList: ColumnsType<IUserRoleTable> = [
  {
    title: 'Entity',
    dataIndex: 0,
  },
  {
    title: 'Roles',
    dataIndex: 1,
    render: (value: IRole[]) => {
      return (
        <div>
          {value.map((item) => (
            <div key={item.id}>
              <Link to={`/role/${item.name}`}>{item.description || item.name}</Link>
            </div>
          ))}
        </div>
      );
    },
  },
];

export const roleEntityLabelMap: Record<RoleEntityType, string> = {
  [RoleEntityType.News]: 'Новости',
  [RoleEntityType.Directorate]: 'Подразделения',
  [RoleEntityType.Station]: 'Станции',
  [RoleEntityType.Service]: 'Услуги',
  [RoleEntityType.User]: 'Пользователи',
  [RoleEntityType.Pages]: 'Контент',
};

export const ROLE_ADMIN_SUFFIX = 'admin';
export const ROLE_EDITOR_SUFFIX = 'editor';
