import { App } from 'antd';
import { LayoutContent } from 'features/Layout';
import { UseParamsWithId } from 'features/Router';
import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useUserLoadQuery, useUserRemoveMutation, useUserUpdateMutation } from '../../User.api';
import { IUserForm, UserTabTypes } from '../../User.interface';
import { userFormBackUrl } from '../../Users.model';
import { UserForm } from '../UserForm/UserForm';

export function UserEdit() {
  const navigate = useNavigate();
  const { message } = App.useApp();
  const { id } = useParams<UseParamsWithId>();
  const { data, isLoading } = useUserLoadQuery(id);
  const backUrl = useMemo(() => userFormBackUrl(UserTabTypes.UserTable), []);
  const [userRemove] = useUserRemoveMutation();
  const [userUpdate] = useUserUpdateMutation();

  const handleFinish = useCallback(async (values: IUserForm) => {
    const { password, ...user } = values;
    await userUpdate({
      ...user,
      id: data.id,
    }).unwrap();
    message.success('Пользователь обновлен');
    navigate(backUrl);
  }, [backUrl, data, message, navigate, userUpdate]);

  const handleRemove = useCallback(async () => {
    await userRemove(data.id).unwrap();
    message.success('Пользователь удален');
    navigate(backUrl);
  }, [backUrl, data, message, navigate, userRemove]);

  return (
    <LayoutContent
      title="Редактировать пользователя"
      backUrl={backUrl}
      isLoading={isLoading}
      render={() => (
        <UserForm
          model={data}
          onFinish={handleFinish}
          onRemove={handleRemove}
        />
      )}
    />
  );
}
