import { ColumnsType } from 'antd/lib/table';
import { formFilter } from 'features/Form';
import type { ServiceType } from 'features/Service';
import { KeycloakRoles } from 'keycloak-js';
import { Link } from 'react-router-dom';
import { createURL } from 'utils/url';
import { canViewDirectorate } from '../Directorate/Directorate.model';
import { ROLE_ADMIN_SUFFIX } from '../Role/Role.model';
import { IStation } from './Station.interface';

export enum StationTabTypes {
  BasicForm = 'basic',
  ServiceTable = 'service'
}

export const stationTableColumnList: ColumnsType<IStation> = [
  {
    title: 'Вокзал',
    dataIndex: ['info', 'name'],
    render: (name, item) => {
      return (
        <Link to={`/stations/${item.slug}`}>
          {name}
        </Link>
      );
    },
  },
  {
    title: 'Город',
    dataIndex: ['info', 'city'],
  },
];

export const stationTableFilter = (search: string, item: IStation) => formFilter(search, item.info.name) || formFilter(search, String(item.esr_id));

export const stationEditUrl = (stationSlug: string, tab: StationTabTypes, serviceType: ServiceType) => createURL(`/stations/${stationSlug}`, {
  tab,
  type: serviceType,
});

export const stationServiceCreateUrl = (stationSlug: string, serviceType: ServiceType) => createURL(`/stations/${stationSlug}/${serviceType}/create`);
export const stationServiceEditUrl = (stationSlug: string, serviceSlug: string, serviceType: ServiceType) => createURL(`/stations/${stationSlug}/${serviceType}/${serviceSlug}`);

// roles

export const STATION_ROLE_PREFIX = 'roles_stations';
export const STATION_ADMIN_ROLE = `${STATION_ROLE_PREFIX}_${ROLE_ADMIN_SUFFIX}`;

export function getStationRoleAdmin(slug: string) {
  return `${STATION_ROLE_PREFIX}_${slug}_${ROLE_ADMIN_SUFFIX}`;
}

export function canViewStationTable(roles: KeycloakRoles['roles']): boolean {
  return canViewDirectorate(roles) || roles.some((item) => item.startsWith(STATION_ROLE_PREFIX));
}
