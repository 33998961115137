import React from 'react';
import { Outlet } from 'react-router-dom';
import { Navbar } from '../Navbar/Navbar';
import { Layout as AntLayout } from 'antd';

export function Layout() {
  return (
    <AntLayout className="min-h-full">
      <AntLayout.Sider width="auto">
        <Navbar />
      </AntLayout.Sider>
      <AntLayout.Content>
        <Outlet />
      </AntLayout.Content>
    </AntLayout>
  );
}
