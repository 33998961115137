export function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

type PromiseMapIteratorFunc<T> = (item: T) => T | Promise<T>;

export function promiseMap<T>(value: T[], iterator: PromiseMapIteratorFunc<T>): Promise<T[]> {
  const promises = value.map(iterator);
  return Promise.all(promises);
}
