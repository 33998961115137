import { TabsProps } from 'antd';
import { UITabs } from 'features/UI';
import { useMemo } from 'react';
import { IUser, IUserForm, UserFormTabTypes } from '../../User.interface';
import { UserBasicForm } from '../UserBasicForm/UserBasicForm';
import { UserRoleForm } from '../UserRoleForm/UserRoleForm';

export function UserForm({ model, onFinish, onRemove }: {
  model?: IUser;
  onFinish: (values: IUserForm) => any;
  onRemove?: Function;
}) {
  const tabs = useMemo((): TabsProps['items'] => [
    {
      key: UserFormTabTypes.BasicForm,
      label: 'Основные',
      children: (
        <UserBasicForm
          model={model}
          onFinish={onFinish}
          onRemove={onRemove}
        />
      )
    },
    {
      key: UserFormTabTypes.RoleForm,
      label: 'Роли',
      children: (
        <UserRoleForm />
      ),
      disabled: !model,
    },
  ], [model, onFinish, onRemove]);

  return (
    <UITabs
      defaultActiveKey={UserFormTabTypes.BasicForm}
      items={tabs}
    />
  );
}
