import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Flex, Typography } from 'antd';
import { Spinner } from 'features/UI';
import { PropsWithChildren, ReactNode, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export function LayoutContent({ title, isLoading, backUrl, render, children }: PropsWithChildren<{
  title: ReactNode;
  backUrl?: string;
  isLoading?: boolean;
  render?: Function;
}>) {
  const navigate = useNavigate();

  const handleBack = useCallback(() => {
    navigate(backUrl);
  }, [backUrl, navigate]);

  return (
    <div className="p-6">
      <Flex gap="middle" align="center" className="pb-4">
        {backUrl && (
          <Button
            onClick={handleBack}
            size="middle"
            type="text"
            shape="circle"
            icon={<ArrowLeftOutlined />}
          />
        )}
        {title && (
          <Typography.Title level={3} style={{ margin: 0 }}>
            {title}
          </Typography.Title>
        )}
      </Flex>
      {isLoading
        ? <Spinner />
        : render?.() || children}
    </div>
  );
}
