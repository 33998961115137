import { createURL } from 'utils/url';
import { ClientMethodTypes, ClientRequestOptions } from './Client.model';

export class ClientFetchService {
  bearerToken: string = null;

  constructor(readonly baseURL: string) {}

  setBearerToken(value: string) {
    this.bearerToken = value;
  }

  private getHeaders(custom: any) {
    const headers: any = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...custom,
    };
    if (this.bearerToken) {
      headers['Authorization'] = `Bearer ${this.bearerToken}`;
    }
    return headers;
  }

  async get<T>(url: string, params?: any, fallback?: T): Promise<T> {
    try {
      return (await this.createRequest(ClientMethodTypes.GET, url, { params }));
    } catch (e) {
      if (fallback) {
        return fallback;
      }
      throw e;
    }
  }

  post<T>(url: string, data?: any, headers?: any): Promise<T> {
    return this.createRequest(ClientMethodTypes.POST, url, { data, headers });
  }

  put<T>(url: string, data?: any): Promise<T> {
    return this.createRequest(ClientMethodTypes.PUT, url, { data });
  }

  patch<T>(url: string, data?: any): Promise<T> {
    return this.createRequest(ClientMethodTypes.PATCH, url, { data });
  }

  delete<T>(url: string, data?: any): Promise<T> {
    return this.createRequest(ClientMethodTypes.DELETE, url, { data });
  }

  async createRequest<T>(method: ClientMethodTypes, url: string, options: Partial<ClientRequestOptions>): Promise<T> {
    const init: RequestInit = {
      mode: 'cors',
      method,
      headers: this.getHeaders(options.headers),
    };

    if (options.data instanceof FormData) {
      // browser will set body's boundary automatic
      delete init.headers['Content-Type'];
      init.body = options.data;
    } else {
      init.body = JSON.stringify(options.data);
    }

    const fullUrl = createURL(`${this.baseURL}${url}`, options.params);

    const response = await fetch(fullUrl.toString(), init);
    if (!response.ok) {
      if (!response.json) {
        throw response;
      }

      const error = await response.json();
      throw new Error(error?.message);
    }
    try {
      return response.json();
    } catch (e) {
      console.error('failed to parse response', e);
      return null;
    }
  }
}
