import { stationApi } from '../Station/Station.api';
import { ITimeTable } from './TimeTable.interface';

export const timeTableApi = stationApi.injectEndpoints({
  endpoints: (builder) => ({
    timeTableListLoad: builder.query<ITimeTable[], void>({
      query: () => '/online_table_sources',
    }),
  }),
});

export const {
  useTimeTableListLoadQuery,
} = timeTableApi;
