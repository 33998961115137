import { ColumnsType } from 'antd/lib/table';
import { ServiceType } from 'features/Service';
import { Link } from 'react-router-dom';
import { createURL } from '../../utils/url';
import { stationServiceEditUrl } from '../Station/Station.model';
import { IStationService, IStationServiceSimple } from './StationService.intefrace';

export enum StationServiceTabTypes {
  Basic = 'basic',
  SimpleTable = 'simple'
}

export const stationServiceSimpleCreateUrl = (serviceType: ServiceType, serviceSlug: string, stationSlug: string) => createURL(`/services/${serviceType}/${serviceSlug}/station/${stationSlug}/simple/create`);
export const stationServiceSimpleEditUrl = (serviceType: ServiceType, serviceSlug: string, stationSlug: string, id: number) => createURL(`/services/${serviceType}/${serviceSlug}/station/${stationSlug}/simple/${id}`);
export const stationServiceSimpleFromBackUrl = (serviceType: ServiceType, serviceSlug: string, stationSlug: string) => createURL(`/services/${serviceType}/${serviceSlug}/station/${stationSlug}`, {
  tab: StationServiceTabTypes.SimpleTable,
});

export const stationTableColumnList = (stationSlug: string, serviceType: ServiceType): ColumnsType<IStationService> => [
  {
    title: 'Название',
    dataIndex: ['service_base_info', 'info', 'title'],
    render: (name, item) => {
      return (
        <Link to={stationServiceEditUrl(stationSlug, item.service_base_info.slug, serviceType)}>
          {name}
        </Link>
      );
    },
  },
];

export const stationServiceSimpleTableColumnList = (serviceType: ServiceType, serviceSlug: string, stationSlug: string): ColumnsType<IStationServiceSimple> => [
  {
    title: 'Услуга',
    dataIndex: ['service_base_info', 'info', 'title'],
    render: (name, item) => {
      return (
        <Link to={stationServiceSimpleEditUrl(serviceType, serviceSlug, stationSlug, item.id)}>
          {name}
        </Link>
      );
    },
  },
];
