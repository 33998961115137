import { App } from 'antd';
import { LayoutContent } from 'features/Layout';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSimpleCreateMutation, useSimpleInfoUpdateMutation } from '../../Simple.api';
import { ISimple } from '../../Simple.interface';
import { SimpleForm } from '../SimpleForm/SimpleForm';

export function SimpleCreate() {
  const navigate = useNavigate();
  const { message } = App.useApp();
  const [serviceCreate] = useSimpleCreateMutation();
  const [serviceInfoUpdate] = useSimpleInfoUpdateMutation();

  const handleFinish = useCallback(async (values: ISimple) => {
    const { info, ...base } = values;
    // create station
    const result = await serviceCreate(base).unwrap();
    // update info
    await serviceInfoUpdate({
      ...info,
      slug: result.slug,
    }).unwrap();
    message.success('Услуга создана');
    navigate('/services');
  }, [message, navigate, serviceCreate, serviceInfoUpdate]);

  return (
    <LayoutContent
      title="Создать услугу"
      backUrl="/services"
    >
      <SimpleForm onFinish={handleFinish} />
    </LayoutContent>
  );
}
