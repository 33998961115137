import { App } from 'antd';
import { LANGUAGE_DEFAULT } from 'features/Language';
import { LayoutContent } from 'features/Layout';
import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useServiceItemLoad } from '../../../Service/Service.hook';
import { useStationServiceSimpleLoad } from '../../StationService.hooks';
import { IStationServiceSimpleForm, StationServiceSimpleEditParams } from '../../StationService.intefrace';
import { stationServiceSimpleFromBackUrl } from '../../StationService.model';
import { useStationServiceSimpleRemoveMutation, useStationServiceSimpleUpdateMutation } from '../../StationServiceSimple.api';
import { StationServiceSimpleForm } from '../StationServiceSimpleForm/StationServiceSimpleForm';

export function StationServiceSimpleEdit() {
  const navigate = useNavigate();
  const { message } = App.useApp();
  const { stationSlug, serviceType, serviceSlug, simpleId } = useParams<StationServiceSimpleEditParams>();
  const backUrl = useMemo(() => stationServiceSimpleFromBackUrl(serviceType, serviceSlug, stationSlug), [serviceSlug, serviceType, stationSlug]);
  const [stationServiceSimpleRemove] = useStationServiceSimpleRemoveMutation();
  const { data: service } = useServiceItemLoad(serviceType, serviceSlug);

  const params = useMemo(() => !!service && ({
    station_slug: stationSlug,
    service_type: serviceType,
    service_id: service?.id,
  }), [serviceType, stationSlug, service]);
  const [stationService, stationServiceSimple] = useStationServiceSimpleLoad(Number(simpleId), params);
  const [stationServiceSimpleInfoUpdate] = useStationServiceSimpleUpdateMutation();

  const handleFinish = useCallback(async (values: IStationServiceSimpleForm) => {
    await stationServiceSimpleInfoUpdate({
      station_service_id: stationServiceSimple.station_service_id,
      simple_service_id: stationServiceSimple.simple_service_id,
      lang: LANGUAGE_DEFAULT,
      content: values.content,
      title: '',
    });
    message.success('Услуга обновлена');
    navigate(backUrl);
  }, [backUrl, message, navigate, stationServiceSimple, stationServiceSimpleInfoUpdate]);

  const handleRemove = useCallback(async () => {
    await stationServiceSimpleRemove({
      station_service_id: stationServiceSimple.station_service_id,
      simple_service_id: stationServiceSimple.simple_service_id,
    }).unwrap();
    message.success('Услуга удалена');
    navigate(backUrl);
  }, [backUrl, message, navigate, stationServiceSimple, stationServiceSimpleRemove]);

  return (
    <LayoutContent
      title="Редактирование услуги на флагманской"
      backUrl={backUrl}
      isLoading={!stationServiceSimple}
      render={() => (
        <StationServiceSimpleForm
          model={stationServiceSimple}
          stationService={stationService}
          onFinish={handleFinish}
          onRemove={handleRemove}
        />
      )}
    />
  );
}
