import { useMemo } from 'react';
import { IPageConfig, PageViewTypes } from './Page.interface';
import { pageConfig } from './Page.model';

export function usePageConfig(type: PageViewTypes): [IPageConfig, IPageConfig['Form']] {
  return useMemo(() => {
    const config = pageConfig[type];
    return [config, config.Form];
  }, [type]);
}
