import { Collapse, CollapseProps, Flex, Form, Input, Typography } from 'antd';
import { FormCommonError, FormForm, formRuleRequired } from 'features/Form';
import { Spinner } from 'features/UI';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useLazyNewsListLoadQuery, useTagRemoveMutation } from '../../News.api';
import { INewsTag, INewsTagForm, newsTagTabUrl } from '../../News.interfaces';

export function NewsTagForm({ model, onFinish }: {
  model?: INewsTag;
  onFinish: (values: INewsTag) => any;
}) {
  const navigate = useNavigate();
  const [form] = Form.useForm<INewsTagForm>();
  const [newsRelatedList, setNewsRelatedList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [newsRelatedListLoad] = useLazyNewsListLoadQuery();
  const [tagRemove]= useTagRemoveMutation();

  const initialValues = useMemo((): INewsTagForm => {
    if (!model) {
      return {
        title: '',
      };
    }
    return {
      title: model.title,
    };
  }, [model]);

  const newsRelatedContent = useMemo((): CollapseProps['items'] => !model ? [] : [
    {
      key: 1,
      label: `Связанные новости (${newsRelatedList.length})`,
      children: (
        <Flex gap={8} vertical>
          {newsRelatedList.length > 0
           ? newsRelatedList.map((item) => (
              <Link key={item.id} to={`/news/news/${item.id}`}>{item.title}</Link>
            ))
            : <Typography.Text italic>Не присутствует в новостях</Typography.Text>}
        </Flex>
      ),
    },
  ], [model, newsRelatedList]);

  const handleRemove = useCallback(async () => {
    // remove tag
    await tagRemove(model.id).unwrap();
    navigate(newsTagTabUrl);
  }, [model, navigate, tagRemove]);

  const handleFinish = useCallback(async (values: INewsTagForm) => {
    try {
      await onFinish({
        ...model,
        title: values.title,
      })
    } catch (e) {
      throw new FormCommonError();
    }
  }, [model, onFinish]);

  const handleNewRelatedListLoad = useCallback(async () => {
    try {
      if (!model) {
        return;
      }
      const result = await newsRelatedListLoad({
        tag: model.id,
      }).unwrap();
      setNewsRelatedList(result);
    } finally {
      setIsLoading(false);
    }
  }, [model, newsRelatedListLoad]);

  useEffect(() => {
    handleNewRelatedListLoad();
  }, [handleNewRelatedListLoad]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <FormForm
      initialValues={initialValues}
      form={form}
      onFinish={handleFinish}
      onRemove={model && handleRemove}
    >
      <Form.Item
        label="Название"
        name="title"
        validateTrigger="onBlur"
        rules={[formRuleRequired('Введите название')]}
      >
        <Input />
      </Form.Item>

      {model && <Collapse items={newsRelatedContent} className="mt-6" />}
    </FormForm>
  );
}
