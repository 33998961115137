import { Form, Input } from 'antd';
import { FormFileUpload, formRuleRequired } from 'features/Form';
import { PlateEditor } from 'features/RictText2';
import { IPagePageEditFormProps } from '../../Page.interface';

export function PagePageFormBasic({ initialValues, onFormUpdate }: IPagePageEditFormProps) {
  return (
    <div>
      <Form.Item
        label="Заголовок"
        name="title"
        validateTrigger="onBlur"
        rules={[formRuleRequired('Введите заголовок')]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Баннер"
        name="banner"
      >
        <div className="mb-2">
          <FormFileUpload
            value={initialValues.banner}
            onChange={onFormUpdate('banner')}
          />
        </div>
      </Form.Item>

      <Form.Item
        label="Контент"
        name="content"
        rules={[formRuleRequired('Добавьте контент')]}
      >
        <PlateEditor />
      </Form.Item>
    </div>
  );
}
