import { combineReducers } from '@reduxjs/toolkit';
import { catalogApi } from './features/Catalog/Catalog.api';
import { directorateApi } from './features/Directorate/Directorate.api';
import { newsApi } from './features/News/News.api';
import { pageApi } from './features/Page/Page.api';
import { serviceBaseApi } from './features/Service/ServiceBase.api';
import { stationApi } from './features/Station/Station.api';
import { userApi } from './features/User/User.api';

export const reducer = combineReducers({
  [userApi.reducerPath]: userApi.reducer,
  [newsApi.reducerPath]: newsApi.reducer,
  [directorateApi.reducerPath]: directorateApi.reducer,
  [stationApi.reducerPath]: stationApi.reducer,
  [serviceBaseApi.reducerPath]: serviceBaseApi.reducer,
  [catalogApi.reducerPath]: catalogApi.reducer,
  [pageApi.reducerPath]: pageApi.reducer,
});
