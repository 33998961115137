import { Collapse, CollapseProps, Flex, Form, Input } from 'antd';
import { CatalogEsrSelector, CatalogExpressSelector } from 'features/Catalog';
import { ContactGroupList } from 'features/Contact';
import { FormCommonError, FormFileUpload, FormForm, formRuleRequired, useFormForm } from 'features/Form';
import { mediaAttachmentToUploadFile, mediaUploadFiles } from 'features/Media';
import { PlateEditor, richText2SerializeContent } from 'features/RictText2';
import { useCallback, useMemo } from 'react';
import { createSlug } from 'utils/string';
import { DirectorateSelector } from '../../../Directorate/components/DirectorateSelector/DirectorateSelector';
import { useDirectorateIsAdmin } from '../../../Directorate/Directorate.hook';
import { useStationIsAdmin } from '../../Station.hooks';
import { IStation, IStationBasicForm } from '../../Station.interface';

export function StationBasicForm({ model, onRemove, onFinish }: {
  model?: IStation;
  onRemove?: Function;
  onFinish: (values: IStation) => any;
}) {
  const [form, handleFormUpdate] = useFormForm<IStationBasicForm>();
  const isDirectorateAdmin = useDirectorateIsAdmin(model?.directorate_slug);
  const isAdmin = useStationIsAdmin(model?.slug);

  // parse values
  const initialValues = useMemo((): IStationBasicForm => {
    if (!model) {
      return {
        name: '',
        directorate: null,
        nameShort: '',
        city: '',
        content: '',
        banner: [],
        contacts: [],
        esrId: '',
        expressId: '',
        longitude: '',
        latitude: '',
      };
    }

    return {
      name: model.info.name,
      directorate: model.directorate_slug,
      nameShort: model.info.name_short,
      city: model.info.city,
      banner: mediaAttachmentToUploadFile(model.info.attachments),
      content: model.info.content,
      contacts: model.info.contact_groups,
      esrId: model.esr_id,
      longitude: model.longitude,
      latitude: model.latitude,
      expressId: model.express_id,
    };
  }, [model]);

  // serialize values
  const handleFinish = useCallback(async (newValues: IStationBasicForm) => {
    const values = {
      ...initialValues,
      ...newValues,
    };
    // calculate slug
    const slug = model?.slug || createSlug(values.name)
      .replace('vokzal', '')
      .replace('--', '-');

    const [attachmentList, content] = await Promise.all([
      mediaUploadFiles('station', values.banner),
      richText2SerializeContent('station', values.content),
    ]);

    const attachments = attachmentList.map((item) => ({
      ...item,
      alt: values.name,
    }));

    try {
      await onFinish?.({
        ...model,
        slug,
        directorate_slug: values.directorate,
        esr_id: values.esrId,
        express_id: values.expressId,
        longitude: values.longitude,
        latitude: values.latitude,
        info: {
          ...model?.info,
          name: values.name,
          name_short: values.nameShort,
          city: values.city,
          contact_groups: values.contacts,
          content,
          attachments,
        },
      });
    } catch (e) {
      console.error('form error', e);
      throw new FormCommonError();
    }
  }, [model, onFinish, initialValues]);

  const panelContent = useMemo((): CollapseProps['items'] => [
    {
      key: 1,
      label: 'Настройки',
      children: (
        <Flex vertical>
          <CatalogExpressSelector name="expressId" label="Express Id" />
          <Form.Item
            className="w-1/3"
            label="Longitude"
            name="longitude"
            rules={[formRuleRequired('Введите Longitude')]}
            validateTrigger="onBlur"
          >
            <Input />
          </Form.Item>
          <Form.Item
            className="w-1/3"
            label="Latitude"
            name="latitude"
            rules={[formRuleRequired('Введите Latitude')]}
            validateTrigger="onBlur"
          >
            <Input />
          </Form.Item>
        </Flex>
      ),
    },
    {
      key: 2,
      label: 'Баннер',
      children: (
        <Form.Item
          name="banner"
          rules={[formRuleRequired('Добавьте баннер')]}
          required
        >
          <div className="mb-2">
            <FormFileUpload
              value={initialValues.banner}
              onChange={handleFormUpdate('banner')}
              maxCount={10}
              multiple
            />
          </div>
        </Form.Item>
      ),
    },
    {
      key: 4,
      label: 'Контакты',
      children: (
        <ContactGroupList name="contacts" />
      ),
    },
    {
      key: 5,
      label: 'Онлайн табло',
      children: (
        <CatalogEsrSelector name="esrId" label="ESR" />
      ),
    },
  ], [handleFormUpdate, initialValues]);

  return (
    <FormForm
      form={form}
      initialValues={initialValues}
      onRemove={(isAdmin || isDirectorateAdmin) && model && onRemove}
      onFinish={handleFinish}
      saveLabel="Сохранить вокзал"
    >
      <Form.Item
        label="Название вокзала"
        name="name"
        validateTrigger="onBlur"
        rules={[formRuleRequired('Введите название вокзала')]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Подразделение"
        name="directorate"
        validateTrigger="onBlur"
        rules={[formRuleRequired('Выберите подразделение')]}
      >
        <DirectorateSelector />
      </Form.Item>

      <Form.Item
        label="Короткое название"
        name="nameShort"
        className="w-1/3"
      >
        <Input />
      </Form.Item>

      <Form.Item
        className="w-1/3"
        label="Город"
        name="city"
        validateTrigger="onBlur"
        rules={[formRuleRequired('Введите название города')]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="О вокзале"
        name="content"
        rules={[formRuleRequired('Добавьте описание')]}
      >
        <PlateEditor />
      </Form.Item>

      <Collapse
        items={panelContent}
        defaultActiveKey={model ? [] : [1, 2, 5]}
      />
    </FormForm>
  );
}
