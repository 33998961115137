import { RouteProps } from 'react-router-dom';
import { UserCreate } from './components/UserCreate/UserCreate';
import { UserEdit } from './components/UserEdit/UserEdit';
import { UserTable } from './components/UserTable/UserTable';

export const userRouter: RouteProps[] = [
  {
    path: '/users',
    Component: UserTable,
  },
  {
    path: '/users/create',
    Component: UserCreate,
  },
  {
    path: '/users/:id',
    Component: UserEdit,
  },
];
