import { App } from 'antd';
import { useCallback } from 'react';

type OnRemoveFunc = (...args: any[]) => any;

export function useLayoutConfirmRemove(callback: OnRemoveFunc) {
  const { modal } = App.useApp();

  return  useCallback(() => {
    modal.confirm({
      title: 'Удалить?',
      onOk: callback,
      okText: 'Да',
      cancelText: 'Нет',
    });
  }, [callback, modal]);
}
