import { LANGUAGE_DEFAULT } from 'features/Language';
import { QueryMethodTypes } from 'features/Query';
import { stationApi, StationApiTagTypes } from '../Station/Station.api';
import { IStationService, IStationServiceInfo, StationServiceCreateRequest, StationServiceCreateResponse, StationServiceInfoUpdateRequest, StationServiceListRequest, StationServiceUpdateRequest } from './StationService.intefrace';

const STATION_SERVICE_LIST_TAG = {
  type: StationApiTagTypes.StationService,
  id: 'stationServiceList',
};

export const stationServiceApi = stationApi.injectEndpoints({
  endpoints: (builder) => ({
    // --- station services ---

    // service list
    stationServiceListLoad: builder.query<IStationService[], StationServiceListRequest>({
      query: (params) => ({
        url: '/station_services',
        params: {
          lang: LANGUAGE_DEFAULT,
          ...params,
        },
      }),
      providesTags: (res, err) => err ? [] : [
        STATION_SERVICE_LIST_TAG,
        ...res.map((item) => ({
          type: StationApiTagTypes.StationService,
          id: String(item.id),
        })),
      ],
    }),
    // service one
    stationServiceLoad: builder.query<IStationService, number>({
      query: (id) => `/station_services/${id}`,
      providesTags: (res, err, req) => err ? [] : [{
        type: StationApiTagTypes.StationService,
        id: String(res.id),
      }],
    }),
    stationServiceCreate: builder.mutation<StationServiceCreateResponse, StationServiceCreateRequest>({
      query: (body) => ({
        url: '/station_services',
        method: QueryMethodTypes.POST,
        body,
      }),
    }),
    stationServiceUpdate: builder.mutation<StationServiceCreateResponse, StationServiceUpdateRequest>({
      query: ({ stationServiceId, ...body }) => ({
        url: `/station_services/${stationServiceId}`,
        method: QueryMethodTypes.PATCH,
        body,
      }),
    }),
    stationServiceRemove: builder.mutation<void, number>({
      query: (id) => ({
        url: `/station_services/${id}`,
        method: QueryMethodTypes.DELETE,
      }),
      invalidatesTags: (res, err, req) => err ? [] : [
        STATION_SERVICE_LIST_TAG,
        {
          type: StationApiTagTypes.StationService,
          id: String(req),
        }],
    }),
    // info
    stationServiceInfoUpdate: builder.mutation<IStationServiceInfo, StationServiceInfoUpdateRequest>({
      query: ({ serviceId, lang, ...body }) => ({
        url: `/station_services/${serviceId}/info/${lang}`,
        method: QueryMethodTypes.PATCH,
        body,
      }),
      invalidatesTags: (res, err, req) => err ? [] : [
        STATION_SERVICE_LIST_TAG,
        {
          type: StationApiTagTypes.StationService,
          id: String(req.serviceId),
        }],
    }),
  }),
});

export const {
  useStationServiceListLoadQuery,
  useLazyStationServiceListLoadQuery,

  // query don't have filters by station/service/type
  useStationServiceLoadQuery,
  useStationServiceCreateMutation,
  useStationServiceRemoveMutation,
  useStationServiceUpdateMutation,
  // info
  useStationServiceInfoUpdateMutation,
} = stationServiceApi;
