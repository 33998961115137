import { App } from 'antd';
import { LayoutContent } from 'features/Layout';
import { UseParamsWithId } from 'features/Router';
import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UserTabTypes } from '../../../User/User.interface';
import { userFormBackUrl } from '../../../User/Users.model';
import { useRoleLoadQuery, useRoleUpdateMutation } from '../../Role.api';
import { IRole } from '../../Role.interface';
import { RoleForm } from '../RoleForm/RoleForm';

export function RoleEdit() {
  const { id } = useParams<UseParamsWithId>();
  const { message } = App.useApp();
  const navigate = useNavigate();
  const { data, isLoading } = useRoleLoadQuery(id);
  const backUrl = useMemo(() => userFormBackUrl(UserTabTypes.RoleTable), []);
  const [roleUpdate] = useRoleUpdateMutation();

  const handleFinish = useCallback(async (values: IRole) => {
    await roleUpdate({
      description: values.description,
      name: data.name,
    }).unwrap();
    message.success('Роль обновлена');
    navigate(backUrl);
  }, [backUrl, data, message, navigate, roleUpdate]);

  return (
    <LayoutContent
      title="Редактирование роли"
      backUrl={backUrl}
      isLoading={isLoading}
      render={() => (
        <RoleForm
          model={data}
          onFinish={handleFinish}
        />
      )}
    />
  );
}
