import { App } from 'antd';
import { LayoutContent } from 'features/Layout';
import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDirectorateInfoUpdateMutation, useDirectorateLoadQuery } from '../../Directorate.api';
import { IDirectorate, IDirectorateEditParams } from '../../Directorate.interface';
import { directorateFormBackUrl } from '../../Directorate.model';
import { DirectorateForm } from '../DirectorateForm/DirectorateForm';

export function DirectorateEdit() {
  const { message } = App.useApp();
  const navigate = useNavigate();
  const { slug } = useParams<IDirectorateEditParams>();
  const { data, isLoading } = useDirectorateLoadQuery(slug);
  const [directorateUpdate] = useDirectorateInfoUpdateMutation();
  const backUrl = useMemo(() => directorateFormBackUrl(slug), [slug]);

  const handleFinish = useCallback(async (values: IDirectorate) => {
    await directorateUpdate({
      ...values.info,
      slug,
    }).unwrap();
    message.success('Дирекция обновлена');
    navigate(backUrl);
  }, [backUrl, directorateUpdate, message, navigate, slug]);

  return (
    <LayoutContent
      title="Редактировать подразделение"
      backUrl={backUrl}
      isLoading={isLoading}
      render={() => (
        <DirectorateForm
          model={data}
          onFinish={handleFinish}
        />
      )}
    />
  );
}
