import { ExportOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { APP_PUBLIC_URL } from 'App.environment';
import type { KeycloakRoles } from 'keycloak-js';
import { Link } from 'react-router-dom';
import { INews, INewsTag, NewsRoleTypes } from './News.interfaces';

export const newsTableColumnList: ColumnsType<INews> = [
  {
    title: 'Заголовок',
    dataIndex: 'title',
    render: (title, item) => (
      <Link to={`/news/news/${item.id}`}>{title}</Link>
    ),
  },
  {
    title: 'Начало',
    dataIndex: 'started_at',
    render: (value) => {
      if (!value) {
        return null;
      }
      const date = new Date(value);
      return `${date.toLocaleDateString('ru-RU')} ${date.toLocaleTimeString('ru-RU')}`;
    },
  },
  {
    title: 'Окончание',
    dataIndex: 'finished_at',
    render: (value) => {
      if (!value) {
        return null;
      }
      const date = new Date(value);
      return `${date.toLocaleDateString('ru-RU')} ${date.toLocaleTimeString('ru-RU')}`;
    },
  },
  {
    dataIndex: 'id',
    render: (id) => {
      return (
        <Link to={`${APP_PUBLIC_URL}/news/${id}`} target="_blank">
          <ExportOutlined />
        </Link>
      );
    },
  },
];

export const newsTagColumnList: ColumnsType<INewsTag> = [
  {
    title: 'Название',
    dataIndex: 'title',
    render: (title, item) => (
      <Link to={`/news/tag/${item.id}`}>{title}</Link>
    ),
  },
];

export function canViewNews(roles: KeycloakRoles['roles']) {
  return roles.includes(NewsRoleTypes.Admin);
}
