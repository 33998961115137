import { RouteProps } from 'react-router-dom';
import { NewsCreate } from './components/NewsCreate/NewsCreate';
import { NewsEdit } from './components/NewsEdit/NewsEdit';
import { NewsTable } from './components/NewsTable/NewsTable';
import { NewsTagCreate } from './components/NewsTagCreate/NewsTagCreate';
import { NewsTagEdit } from './components/NewsTagEdit/NewsTagEdit';

export const newsRouter: RouteProps[] = [
  {
    path: '/news',
    Component: NewsTable,
  },
  {
    path: '/news/news/create',
    Component: NewsCreate,
  },
  {
    path: '/news/news/:id',
    Component: NewsEdit,
  },
  {
    path: '/news/tag/create',
    Component: NewsTagCreate,
  },
  {
    path: '/news/tag/:id',
    Component: NewsTagEdit,
  },
];
