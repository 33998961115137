import { Form, Input } from 'antd';
import { CatalogCategorySelector } from 'features/Catalog';
import { FormCommonError, FormFileUpload, FormForm, formRuleRequired, formRuleUrl, useFormForm } from 'features/Form';
import { mediaArrayToUploadFile, mediaAttachmentToUploadFile, mediaUploadFiles } from 'features/Media';
import { PlateEditor, richText2SerializeContent } from 'features/RictText2';
import { useCallback, useMemo } from 'react';
import { createSlug } from 'utils/string';
import { IServiceComplex, IServiceComplexForm, serviceComplexFormEmpty } from '../../Service.interface';

export function ServiceComplexBasicForm({ model, isAdmin, onFinish, onRemove }: {
  model?: IServiceComplex;
  onFinish: (values: IServiceComplex) => any;
  onRemove?: () => any;
  isAdmin: boolean;
}) {
  const [form, handleFormUpdate] = useFormForm<IServiceComplexForm>();

  const initialValues = useMemo((): IServiceComplexForm => {
    if (!model) {
      return serviceComplexFormEmpty;
    }
    return {
      icon: mediaArrayToUploadFile([model.icon]),
      title: model.info.title,
      gisId: model.gis_id,
      catalogId: model.service_catalog_id,
      banner: mediaAttachmentToUploadFile(model.info.attachments),
      content: model.info.content,
      externalLink: model.external_link,
    };
  }, [model]);

  const handleFinish = useCallback(async (values: IServiceComplexForm) => {
    const slug = model?.slug || createSlug(values.title);
    const [attachments, content, image] = await Promise.all([
      mediaUploadFiles('service', values.banner),
      richText2SerializeContent('service', values.content),
      mediaUploadFiles('service', values.icon),
    ]);

    try {
      await onFinish({
        ...model,
        slug,
        icon: image[0]?.link,
        gis_id: values.gisId,
        service_catalog_id: values.catalogId,
        external_link: values.externalLink,
        info: {
          ...model?.info,
          title: values.title,
          attachments,
          content,
        },
      });
    } catch (e) {
      console.error('form error', e);
      throw new FormCommonError();
    }
  }, [model, onFinish]);

  return (
    <FormForm
      initialValues={initialValues}
      form={form}
      onFinish={handleFinish}
      onRemove={isAdmin && model && onRemove}
    >
      <Form.Item
        label="Название"
        name="title"
        validateTrigger="onBlur"
        rules={[formRuleRequired('Введите название')]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Gis ID"
        name="gisId"
        validateTrigger="onBlur"
        rules={[formRuleRequired('Введите Gis ID')]}
        className="w-1/3"
      >
        <Input />
      </Form.Item>

      <CatalogCategorySelector
        label="Catalog ID"
        name="catalogId"
      />

      <Form.Item
        label="Внешняя ссылка"
        name="externalLink"
        className="w-2/3"
        rules={[formRuleUrl('Введите корректную ссылку')]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Иконка (svg)"
        name="icon"
      >
        <div className="mb-2">
          <FormFileUpload
            value={initialValues.icon}
            onChange={handleFormUpdate('icon')}
            accept="image/svg+xml"
          />
        </div>
      </Form.Item>

      <Form.Item
        label="Баннер"
        name="banner"
      >
        <div className="mb-2">
          <FormFileUpload
            value={initialValues.banner}
            onChange={handleFormUpdate('banner')}
            maxCount={10}
            multiple
          />
        </div>
      </Form.Item>

      <Form.Item
        label="Описание"
        name="content"
        rules={[formRuleRequired('Добавьте описание')]}
      >
        <PlateEditor />
      </Form.Item>
    </FormForm>
  );
}
