import { createApi } from '@reduxjs/toolkit/query/react';
import { APP_API_ADMIN_URL } from 'App.environment';
import { authFetchBaseQuery } from 'features/Auth';
import { QueryMethodTypes } from 'features/Query';
import { IDirectorate, IDirectorateInfo, IDirectorateManager, IDirectorateManagerCreateRequest, IDirectorateManagerCreateResponse, IDirectorateManagerInfo, IDirectorateManagerInfoUpdateRequest, IDirectorateManagerLoadRequest, IDirectorateManagerRemoveRequest, IDirectorateManagerSortRequest, IDirectorateManagerUpdateRequest, IDirectorateUpdateRequest } from './Directorate.interface';

enum DirectorateApiTagTypes {
  Directorate = 'directorate',
  Manager = 'manager',
}

const DIRECTORATE_LIST_TAG = {
  type: DirectorateApiTagTypes.Directorate,
  id: 'directorateList',
};

const DIRECTORATE_MANAGER_LIST_TAG = {
  type: DirectorateApiTagTypes.Manager,
  id: 'directorateManagerList',
};

export const directorateApi = createApi({
  reducerPath: 'directorateApi',
  tagTypes: [
    DirectorateApiTagTypes.Directorate,
    DirectorateApiTagTypes.Manager,
  ],
  baseQuery: authFetchBaseQuery(`${APP_API_ADMIN_URL}/directorates/api/v1`),
  endpoints: (builder) => ({
    // directorate list
    directorateListLoad: builder.query<IDirectorate[], void>({
      query: () => '/directorates',
      providesTags: (result = []) => [
        DIRECTORATE_LIST_TAG,
        ...result.map((item) => ({
          type: DirectorateApiTagTypes.Directorate,
          id: item.slug,
        })),
      ],
    }),
    // directorate one
    directorateLoad: builder.query<IDirectorate, string>({
      query: (slug) => `/directorates/${slug}`,
      providesTags: (result, err) => err ? [] : [
        {
          type: DirectorateApiTagTypes.Directorate,
          id: result.slug,
        },
      ],
    }),
    directorateInfoUpdate: builder.mutation<IDirectorateInfo, IDirectorateUpdateRequest>({
      query: ({ slug, lang, ...body }) => ({
        url: `/directorates/${slug}/info/${lang}`,
        method: QueryMethodTypes.PATCH,
        body,
      }),
      invalidatesTags: (res, err, req) => err ? [] : [
        DIRECTORATE_LIST_TAG,
        {
          type: DirectorateApiTagTypes.Directorate,
          id: req.slug,
        },
      ],
    }),
    // manager list
    directorateManagerListLoad: builder.query<IDirectorateManager[], string>({
      query: (slug) => `/directorates/${slug}/managers`,
      providesTags: (result, err) => err ? [] : [
        DIRECTORATE_MANAGER_LIST_TAG,
        ...result.map((item) => ({
          type: DirectorateApiTagTypes.Manager,
          id: item.id,
        })),
      ],
    }),
    // manager crud
    directorateManagerLoad: builder.query<IDirectorateManager, IDirectorateManagerLoadRequest>({
      query: ({ slug, manager_id }) => `/directorates/${slug}/managers/${manager_id}`,
      providesTags: (result, err) => err ? [] : [{
        type: DirectorateApiTagTypes.Manager,
        id: result.id,
      }],
    }),
    directorateManagerCreate: builder.mutation<IDirectorateManagerCreateResponse, IDirectorateManagerCreateRequest>({
      query: ({ slug, ...body }) => ({
        url: `/directorates/${slug}/managers`,
        method: QueryMethodTypes.POST,
        body,
      }),
    }),
    directorateManagerUpdate: builder.mutation<IDirectorate, IDirectorateManagerUpdateRequest>({
      query: ({ slug, manager_id, ...body }) => ({
        url: `/directorates/${slug}/managers/${manager_id}`,
        method: QueryMethodTypes.PATCH,
        body,
      }),
    }),
    directorateManagerInfoUpdate: builder.mutation<IDirectorateManagerInfo, IDirectorateManagerInfoUpdateRequest>({
      query: ({ slug, manager_id, lang, ...body }) => ({
        url: `/directorates/${slug}/managers/${manager_id}/info/${lang}`,
        method: QueryMethodTypes.PATCH,
        body,
      }),
      invalidatesTags: () => [DIRECTORATE_MANAGER_LIST_TAG],
    }),
    directorateManagerRemove: builder.mutation<void, IDirectorateManagerRemoveRequest>({
      query: ({ slug, manager_id }) => ({
        url: `/directorates/${slug}/managers/${manager_id}`,
        method: QueryMethodTypes.DELETE,
      }),
      invalidatesTags: () => [DIRECTORATE_MANAGER_LIST_TAG],
    }),
    directorateManagerSort: builder.mutation<IDirectorateManager['id'][], IDirectorateManagerSortRequest>({
      query: ({ slug, indexes }) => ({
        url: `/directorates/${slug}/managers_indexes`,
        method: QueryMethodTypes.PATCH,
        body: indexes,
      }),
      invalidatesTags: () => [DIRECTORATE_MANAGER_LIST_TAG],
    }),
  }),
});

export const {
  // directorate list
  useDirectorateListLoadQuery,
  // directorate one
  useDirectorateLoadQuery,
  useDirectorateInfoUpdateMutation,
  // manager list
  useDirectorateManagerListLoadQuery,
  // manager crud
  useDirectorateManagerLoadQuery,
  useDirectorateManagerCreateMutation,
  useDirectorateManagerUpdateMutation,
  useDirectorateManagerInfoUpdateMutation,
  useDirectorateManagerRemoveMutation,
  // additional
  useDirectorateManagerSortMutation,
} = directorateApi;
