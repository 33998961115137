import { Form, Input, Select, Typography } from 'antd';
import { featureFlags } from 'App.environment';
import { FormCommonError, FormForm, formRuleAZ, formRuleRequired, useFormForm } from 'features/Form';
import { mediaArrayToUploadFile, mediaUploadFiles } from 'features/Media';
import { useCallback, useMemo } from 'react';
import { createSlug } from 'utils/string';
import { IPageBlockForm, PageBlockTemplateTypes, PageFormProps } from '../../Page.interface';
import { pageBlockTemplateConfigMap } from '../../Page.model';

export function PageBlockForm({ model, templateOptions, onFinish, onRemove }: PageFormProps) {
  const [form, handleFormUpdate] = useFormForm<IPageBlockForm>();
  const EditForm = useMemo(() => pageBlockTemplateConfigMap[model?.template]?.Form, [model]);

  const initialValues = useMemo((): IPageBlockForm => {
    if (!model) {
      return {
        slug: '',
        title: '',
        content: '',
        template: PageBlockTemplateTypes.Text,
        link: '',
        banner: [],
      };
    }

    const parser = pageBlockTemplateConfigMap[model.template].contentParser;
    const content = parser
      ? parser(model.info.content)
      : model.info.content;

    return {
      slug: model.slug,
      link: model.link,
      title: model.info.title,
      template: model.template,
      content,
      banner: mediaArrayToUploadFile(model.info.attachments || []),
    };
  }, [model]);

  const handleFinish = useCallback(async (values: IPageBlockForm) => {
    const slug = model?.slug || createSlug(`block-${values.slug}`);
    const attachments = await mediaUploadFiles('pages', values.banner);

    const serializer = pageBlockTemplateConfigMap[model?.template]?.contentSerializer;
    const content = serializer
      ? await serializer(values.content)
      : values.content;

    try {
      await onFinish({
        slug,
        attachments: attachments.map((item) => item.link),
        link: values.link,
        template: values.template,
      }, {
        content,
        title: values.title,
      });
    } catch (e) {
      throw new FormCommonError();
    }
  }, [model, onFinish]);

  return (
    <FormForm
      initialValues={initialValues}
      form={form}
      onFinish={handleFinish}
      onRemove={featureFlags.isDev && model && onRemove}
    >
      {(featureFlags.isDev || !model) && (
        <>
          <Form.Item
            label="ID"
            name="slug"
            validateTrigger="onBlur"
            rules={[
              formRuleAZ('Допустимы только английские символы и дефис (-)'),
              formRuleRequired('Введите ID'),
            ]}
          >
            <Input
              addonBefore={!model && 'block-'}
              disabled={!!model}
            />
          </Form.Item>

          <Form.Item
            label="Описание"
            name="title"
            validateTrigger="onBlur"
            rules={[formRuleRequired('Введите описание')]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Тип"
            name="template"
            required
          >
            <Select options={templateOptions} />
          </Form.Item>
        </>
      )}

      {model?.template && EditForm && (
        <div>
          <Typography.Paragraph strong>
            {model.info.title}
          </Typography.Paragraph>

          <EditForm
            model={model}
            initialValues={initialValues}
            onFormUpdate={handleFormUpdate}
            form={form}
          />
        </div>
      )}
    </FormForm>
  );
}
