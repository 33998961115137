import type { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import type { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { draggableTableRow, UIDragHandle, UIDragSortable } from '../UIDrag/UIDrag';
import { UITable } from './UITable';
import { UITableProps } from './UITable.interface';

export function UITableSortable({ dataSource, columns, onDragEnd, ...props }: {
  onDragEnd: Function;
} & UITableProps) {
  const [data, setData] = useState(dataSource);

  const columnsWithHandle = useMemo((): ColumnsType => [
    ...columns,
    {
      key: 'sort',
      align: 'center',
      width: 80,
      render: () => <UIDragHandle />,
    },
  ], [columns]);

  const handleDragEnd = useCallback(({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setData((prev) => {
        const activeIndex = prev.findIndex((record) => record.id === active?.id);
        const overIndex = prev.findIndex((record) => record.id === over?.id);
        const next = arrayMove([...prev], activeIndex, overIndex);
        onDragEnd(next);
        return next;
      });
    }
  }, [onDragEnd]);

  useEffect(() => {
    setData(dataSource);
  }, [dataSource]);

  return (
    <UIDragSortable
      items={data.map((item) => item.id)}
      onDragEnd={handleDragEnd}
    >
      <UITable
        {...props}
        components={draggableTableRow}
        columns={columnsWithHandle}
        dataSource={data}
      />
    </UIDragSortable>
  );
}
