import { formFilter } from 'features/Form';
import { LayoutContent } from 'features/Layout';
import { UITable } from 'features/UI';
import { useDirectorateListWithRoles } from '../../Directorate.hook';
import { IDirectorate } from '../../Directorate.interface';
import { directorateColumnList } from '../../Directorate.model';

const filter = (search: string, item: IDirectorate) => formFilter(search, item.info.name);

export function DirectorateTable() {
  const { data = [], isLoading } = useDirectorateListWithRoles();

  return (
    <LayoutContent title="Структурные подразделения">
      <UITable
        filter={filter}
        loading={isLoading}
        columns={directorateColumnList}
        dataSource={data}
        className="pb-6"
        rowKey="slug"
        pagination={false}
      />
    </LayoutContent>
  );
}
