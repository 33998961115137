import { DeleteOutlined } from '@ant-design/icons';
import { Button, Flex, FormListFieldData, FormListOperation } from 'antd';
import { FC, MouseEventHandler, PropsWithChildren, useCallback } from 'react';
import { FormListItemModeTypes, FormListItemProps, FormListOptions } from '../../Form.interface';

export function FormListItem({ onRemove, children, mode }: PropsWithChildren<{
  onRemove: MouseEventHandler;
  mode: FormListItemModeTypes;
}>) {
  switch (mode) {
    case FormListItemModeTypes.Normal:
      return (
        <div className="flex border-1 rounded-md divide-x">
          <div className="flex-1 p-3">
            {children}
          </div>
          <div className="flex items-center p-3">
            <Button
              type="text"
              shape="circle"
              icon={<DeleteOutlined />}
              onClick={onRemove}
            />
          </div>
        </div>
      );
    case FormListItemModeTypes.Inline:
      return (
        <Flex gap={16} align="center">
          <div>
            {children}
          </div>
          <Button
            type="text"
            shape="circle"
            icon={<DeleteOutlined />}
            onClick={onRemove}
          />
        </Flex>
      );
    case FormListItemModeTypes.Compact:
      return (
        <Flex gap={8}>
          <div className="flex-1">
            {children}
          </div>
          <Button
            type="text"
            shape="circle"
            icon={<DeleteOutlined />}
            onClick={onRemove}
          />
        </Flex>
      );
  }
}

export function FormList<T>({ fields, onAdd, onRemove, component: Component, addLabel, mode = FormListItemModeTypes.Normal, isAdd = true, model = {} as T, form, meta }: {
  fields: FormListFieldData[];
  onAdd: FormListOperation['add'];
  onRemove: FormListOperation['remove'];
  component: FC<FormListItemProps>;
  mode?: FormListItemModeTypes;
} & Partial<FormListOptions<T>>) {
  const handleAdd = useCallback(() => {
    meta?.onAdd?.(model);
    return onAdd(model);
  }, [onAdd, model, meta]);

  const handleRemove = useCallback((name: FormListFieldData['name']) => () => {
    meta?.onRemove?.(model);
    onRemove(name);
  }, [meta, model, onRemove]);

  return (
    <Flex gap={8} vertical>
      {/* rows */}
      {fields.map((item) => (
        <FormListItem
          key={item.key}
          onRemove={handleRemove(item.name)}
          mode={mode}
        >
          <Component field={item} form={form} meta={meta} />
        </FormListItem>
      ))}
      {isAdd && (
        <div>
          <Button onClick={handleAdd}>
            + {addLabel}
          </Button>
        </div>
      )}
    </Flex>
  );
}

export function createFormList<T = any>(Component: FC<FormListItemProps>, options: Partial<FormListOptions<T>>) {
  return function (fields: FormListFieldData[], actions: FormListOperation) {
    return (
      <FormList<T>
        fields={fields}
        onAdd={actions.add}
        onRemove={actions.remove}
        component={Component}
        {...options}
      />
    );
  };
}
