import { Flex, Form, Input, Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { formFilterOptionByLabel, FormOption, formRuleRequired } from 'features/Form';
import { useMemo } from 'react';
import { useCategoryStationListLoadQuery } from '../../Catalog.api';

export function CatalogEsrSelector({ name, label, ...props }: {
  name: string;
  label: string;
} & SelectProps) {
  const { data = [], isLoading } = useCategoryStationListLoadQuery();

  const options = useMemo(() => {
    if (isLoading) {
      return [];
    }
    return data.map((item): FormOption => ({
      label: item.vokzal,
      value: item.esr,
    }));
  }, [data, isLoading]);

  return (
    <Flex align="end" className="w-full">
      <Form.Item
        className="w-2/3"
        label={label}
        name={name}
        {...props}
      >
        <Select
          options={options}
          filterOption={formFilterOptionByLabel}
          showSearch
          loading={isLoading}
          {...props}
        />
      </Form.Item>
      <Form.Item
        name={name}
        validateFirst
        validateTrigger="onBlur"
        rules={[formRuleRequired(`Введите ${label}`)]}
        className="w-1/3"
      >
        <Input />
      </Form.Item>
    </Flex>
  );
}
