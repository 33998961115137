import { Typography } from 'antd';
import { Editor, Range, Selection } from 'slate';
import { ISlateElement, ISlateElementParagraph, SlateMarkTypes, SlateRenderElementProps } from '../RichText.interface';
import { createElementParagraph, slateInlineList, slateVoidList } from '../RichText.model';

export function RichTextParagraph({ attributes, children }: Partial<SlateRenderElementProps<ISlateElementParagraph>>) {
  return (
    <Typography.Paragraph {...attributes}>
      {children}
    </Typography.Paragraph>
  );
}

export function RichTextQuote({ attributes, children }: Partial<SlateRenderElementProps<ISlateElementParagraph>>) {
  return (
    <blockquote {...attributes}>
      {children}
    </blockquote>
  );
}

export const RichTextEditor = {
  // custom commands
  markToggle: (editor: Editor, mark: SlateMarkTypes) => {
    const current = !!editor.getMarks()?.[mark];
    current
      ? editor.removeMark(mark)
      : editor.addMark(mark, true);
  },
  isCollapsed: (selection: Selection) => selection && Range.isCollapsed(selection),
  moveForward: (editor: Editor) => {
    const next = editor.next();
    if (next) {
      editor.move({ distance: 1 });
    } else {
      editor.insertNodes(createElementParagraph());
    }
  },
};

export function withRichTextCommon(editor: Editor): Editor {
  const { isInline, isVoid, insertData } = editor;

  // global overrides
  editor.isInline = (element: ISlateElement) => slateInlineList.includes(element.type) || isInline(element);
  editor.isVoid = (element: ISlateElement) => slateVoidList.includes(element.type) || isVoid(element);

  editor.insertData = (data) => {
    // parse copy-pasted content as plain text (this avoids html parsing)
    const text = data.getData('text/plain');
    if (text) {
      editor.insertText(text);
      return;
    }
    insertData(data);
  };

  return editor;
}
