import { useAuthRoles } from 'features/Auth';
import { ServiceType } from './Service.interface';
import { getServiceRoleAdmin, SERVICE_ROLE_PREFIX, serviceCRUDMap } from './Service.model';

export function useServiceListLoad(type: ServiceType) {
  const { useListLoad } = serviceCRUDMap[type];
  return useListLoad();
}

export function useServiceItemLoad(type: ServiceType, slug: string) {
  const { useItemLoad } = serviceCRUDMap[type];
  return useItemLoad(slug);
}

export function useServiceItemCreate(type: ServiceType) {
  const { useItemCreate } = serviceCRUDMap[type];
  return useItemCreate();
}

export function useServiceItemRemove(type: ServiceType) {
  const { useItemRemove } = serviceCRUDMap[type];
  return useItemRemove();
}

export function useServiceItemUpdate(type: ServiceType) {
  const { useItemUpdate } = serviceCRUDMap[type];
  return useItemUpdate();
}

export function useServiceItemInfoUpdate(type: ServiceType) {
  const { useItemInfoUpdate } = serviceCRUDMap[type];
  return useItemInfoUpdate();
}

// roles

export function useServiceIsAdmin(type: ServiceType) {
  const roles = useAuthRoles(SERVICE_ROLE_PREFIX);
  return roles.includes(getServiceRoleAdmin(type));
}
