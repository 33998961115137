import { Select } from 'antd';
import { formFilterOptionByLabel, FormOption } from 'features/Form';
import { IRole } from 'features/Role';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { arraySortBy } from 'utils/array';
import { useDirectorateListLoadQuery } from '../../Directorate.api';
import { DIRECTORATE_HEAD_ROLE_ADMIN, DIRECTORATE_SLUG_HEAD, getDirectorateRoleAdmin } from '../../Directorate.model';

export function DirectorateRoleSelector({ value, roles, onChange }: {
  value?: string[];
  roles: IRole[];
  onChange?: (value: string[], directorateList: string[], isReset: boolean) => any;
}) {
  const { data = [], isLoading } = useDirectorateListLoadQuery();
  const [isInit, setIsInit] = useState(false);

  const options = useMemo(() => {
    if (isLoading) {
      return [];
    }

    return roles
      .map((role): FormOption => {
        // fix label from directorate name
        const directorate = data.find((item) => getDirectorateRoleAdmin(item.slug) === role.name);
        return {
          label: role.description || directorate?.info?.name,
          value: role.name,
        };
      })
      .sort(arraySortBy('label'));
  }, [data, isLoading, roles]);

  const handleChange = useCallback((value: string[]) => {
    const filtered = data
      .filter((item) => (value || []).includes(getDirectorateRoleAdmin(item.slug)))
      .map((item) => item.slug);

    if (value.includes(DIRECTORATE_HEAD_ROLE_ADMIN)) {
      filtered.push(DIRECTORATE_SLUG_HEAD);
    }
    onChange(value, filtered, isInit);
  }, [data, onChange, isInit]);

  useEffect(() => {
    if (isInit || isLoading) {
      return;
    }
    setIsInit(true);
    // 'init' will be 'false' here
    handleChange(value);
  }, [handleChange, isInit, isLoading, value]);

  return (
    <Select
      value={value}
      mode="multiple"
      allowClear
      filterOption={formFilterOptionByLabel}
      options={options}
      onChange={handleChange}
      loading={isLoading}
    />
  );
}
