import { cn, withRef, withVariants } from '@udecode/cn';
import { ResizeHandle as ResizeHandlePrimitive } from '@udecode/plate-resizable';
import { cva } from 'class-variance-authority';

const resizeHandleVariants = cva(cn('absolute z-40'), {
  variants: {
    direction: {
      bottom: 'w-full cursor-row-resize',
      left: 'h-full cursor-col-resize',
      right: 'h-full cursor-col-resize',
      top: 'w-full cursor-row-resize',
    },
  },
});

const ResizeHandleVariants = withVariants(
  ResizeHandlePrimitive,
  resizeHandleVariants,
  ['direction']
);

export const ResizeHandle = withRef<typeof ResizeHandlePrimitive>(
  (props, ref) => (
    <ResizeHandleVariants
      direction={props.options?.direction}
      ref={ref}
      {...props}
    />
  )
);
