import { appMenu } from 'App.model';
import { useAuthRoles } from 'features/Auth';
import { ReactElement, useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

export function RoleGuard({ children }: {
  children: ReactElement;
}) {
  const { pathname } = useLocation();
  const roles = useAuthRoles();

  const isRestricted = useMemo(() => {
    const path = pathname.substring(1);
    const found = appMenu.find((item) => path.startsWith(String(item.key)));
    // unknown view rules
    if (!found?.canView) {
      return false;
    }
    // check rules
    return !found.canView(roles);
  }, [pathname, roles]);

  return isRestricted
    ? <Navigate to="/" />
    : children;
}
