import { LockTwoTone } from '@ant-design/icons';
import { Form, Input, Space, Typography } from 'antd';
import { FormForm } from 'features/Form';
import { IRole } from '../../Role.interface';

export function RoleForm({ model, onFinish }: {
  model: IRole;
  onFinish: (values: IRole) => any;
}) {
  const [form] = Form.useForm<IRole>();

  return (
    <FormForm
      initialValues={model}
      form={form}
      onFinish={onFinish}
    >
      <div className="mb-6">
        <Space>
          <Typography.Text>Название:</Typography.Text>
          <Typography.Text italic>
            {model?.name}
          </Typography.Text>
          <LockTwoTone />
        </Space>
      </div>
      <Form.Item
        label="Описание"
        name="description"
      >
        <Input />
      </Form.Item>
    </FormForm>
  );
}
