import { App } from 'antd';
import { LANGUAGE_DEFAULT } from 'features/Language';
import { LayoutContent } from 'features/Layout';
import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStationLoadQuery } from '../../../Station/Station.api';
import { stationEditUrl, StationTabTypes } from '../../../Station/Station.model';
import { useStationServiceCreateMutation, useStationServiceInfoUpdateMutation } from '../../StationService.api';
import { IStationServiceForm, StationServiceEditParams } from '../../StationService.intefrace';
import { StationServiceBasicForm } from '../StationServiceBasicForm/StationServiceBasicForm';

export function StationServiceCreate() {
  const { message } = App.useApp();
  const navigate = useNavigate();
  const { stationSlug, serviceType } = useParams<StationServiceEditParams>();
  const { data: station } = useStationLoadQuery(stationSlug);
  const [stationServiceCreate] = useStationServiceCreateMutation();
  const [stationServiceInfoUpdate] = useStationServiceInfoUpdateMutation();
  const backUrl = useMemo(() => stationEditUrl(stationSlug, StationTabTypes.ServiceTable, serviceType), [serviceType, stationSlug]);

  const handleFinish = useCallback(async (values: IStationServiceForm, attachments: string[]) => {
    const result = await stationServiceCreate({
      station_id: station.id,
      service_id: values.serviceId,
      external_link: values.externalLink,
      type: serviceType,
      attachments,
    }).unwrap();

    await stationServiceInfoUpdate({
      serviceId: result.id,
      lang: LANGUAGE_DEFAULT,
      content: values.content,
      title: values.title,
      contact_groups: values.contacts,
    }).unwrap();

    message.success('Услуга добавлена');
    navigate(backUrl);
  }, [backUrl, message, navigate, serviceType, station, stationServiceCreate, stationServiceInfoUpdate]);

  return (
    <LayoutContent
      title="Добавить услугу на вокзал"
      backUrl={backUrl}
      isLoading={!station}
      render={() => (
        <StationServiceBasicForm
          serviceType={serviceType}
          station={station}
          onFinish={handleFinish}
        />
      )}
    />
  );
}
