import { TabsProps } from 'antd';
import { UITabs } from 'features/UI';
import { useMemo } from 'react';
import { DirectorateFormTabTypes, IDirectorate } from '../../Directorate.interface';
import { DirectorateBasicForm } from '../DirectorateBasicForm/DirectorateBasicForm';
import { DirectorateManagerTable } from '../DirectorateManagerTable/DirectorateManagerTable';

export function DirectorateForm({ model, onFinish }: {
  model: IDirectorate;
  onFinish: (values: IDirectorate) => any;
}) {
  const tabs = useMemo((): TabsProps['items'] => [
    {
      key: DirectorateFormTabTypes.Basic,
      label: 'Основные',
      children: (
        <DirectorateBasicForm model={model} onFinish={onFinish} />
      ),
    },
    {
      key: DirectorateFormTabTypes.Manager,
      label: 'Сотрудники',
      disabled: !model,
      children: (
        <DirectorateManagerTable slug={model?.slug} />
      ),
    },
  ], [model, onFinish]);

  return (
    <UITabs
      defaultActiveKey={DirectorateFormTabTypes.Basic}
      items={tabs}
    />
  );
}
