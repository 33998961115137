import { ColumnsType } from 'antd/lib/table';
import { ROLE_ADMIN_SUFFIX } from 'features/Role';
import { KeycloakRoles } from 'keycloak-js';
import { Link } from 'react-router-dom';
import { createURL } from 'utils/url';
import { useSimpleCreateMutation, useSimpleInfoUpdateMutation, useSimpleListLoadQuery, useSimpleLoadQuery, useSimpleRemoveMutation, useSimpleUpdateMutation } from '../Simple/Simple.api';
import { IStationServiceTableItem } from '../StationService/StationService.intefrace';
import { IServiceBase, IStationLoader, ServiceFormTabTypes, ServiceType } from './Service.interface';
import { useFlagshipCreateMutation, useFlagshipInfoUpdateMutation, useFlagshipListLoadQuery, useFlagshipLoadQuery, useFlagshipRemoveMutation, useFlagshipUpdateMutation } from './ServiceFlagship.api';
import { usePremiumCreateMutation, usePremiumInfoUpdateMutation, usePremiumListLoadQuery, usePremiumLoadQuery, usePremiumRemoveMutation, usePremiumUpdateMutation } from './ServicePremium.api';

export const serviceFormBackUrl = (serviceType: ServiceType) => createURL(`/services`, {
  tab: serviceType,
});
export const serviceEditUrl = (serviceType: ServiceType, serviceSlug: string) => createURL(`/services/${serviceType}/${serviceSlug}`);
export const serviceStationCreateUrl = (serviceType: ServiceType, serviceSlug: string) => createURL(`/services/${serviceType}/${serviceSlug}/station/create`);
export const serviceStationFormBackUrl = (serviceType: ServiceType, serviceSlug: string) => createURL(`/services/${serviceType}/${serviceSlug}`, {
  tab: ServiceFormTabTypes.StationTable,
});

export function serviceTableColumnList(type: ServiceType): ColumnsType<IServiceBase> {
  return [
    {
      title: 'Иконка',
      dataIndex: 'icon',
      render: (icon) => icon && (
        <img src={icon} width={24} height={24} alt="" />
      ),
    },
    {
      title: 'Название',
      dataIndex: ['info', 'title'],
      sorter: (a, b) => a.info.title < b.info.title ? -1 : 1,
      defaultSortOrder: 'ascend',
      showSorterTooltip: false,
      render: (title, item) => (
        <Link className="w-full" to={serviceEditUrl(type, item.slug)}>{title || 'Без названия'}</Link>
      ),
    },
  ];
}

export const serviceStationTableColumnList: ColumnsType<IStationServiceTableItem> = [
  {
    title: 'Название',
    dataIndex: 'title',
    render: (title, item) => <Link to={item.url}>{title}</Link>,
  },
  {
    title: 'Вокзал',
    dataIndex: 'station',
  },
];

export const serviceCRUDMap: Record<ServiceType, IStationLoader> = {
  [ServiceType.Simple]: {
    label: 'Обычные',
    labelCreateTitle: 'Создать услугу',
    labelCreateSuccess: 'Услуга создана',
    labelEditTitle: 'Редактирование услуги',
    labelEditSuccess: 'Услуга обновлена',
    useListLoad: useSimpleListLoadQuery,
    useItemLoad: useSimpleLoadQuery,
    useItemCreate: useSimpleCreateMutation,
    useItemRemove: useSimpleRemoveMutation,
    useItemUpdate: useSimpleUpdateMutation,
    useItemInfoUpdate: useSimpleInfoUpdateMutation,
  },
  [ServiceType.Premium]: {
    label: 'Премиум',
    labelCreateTitle: 'Создать премиум услугу',
    labelCreateSuccess: 'Премиум услуга создана',
    labelEditTitle: 'Редактирование премиум услуги',
    labelEditSuccess: 'Премиум услуга обновлена',
    useListLoad: usePremiumListLoadQuery,
    useItemLoad: usePremiumLoadQuery,
    useItemCreate: usePremiumCreateMutation,
    useItemRemove: usePremiumRemoveMutation,
    useItemUpdate: usePremiumUpdateMutation,
    useItemInfoUpdate: usePremiumInfoUpdateMutation,
  },
  [ServiceType.Flagship]: {
    label: 'Флагманские',
    labelCreateTitle: 'Создать флагманскую услугу',
    labelCreateSuccess: 'Флагманская услуга создана',
    labelEditTitle: 'Редактирование флагманской услуги',
    labelEditSuccess: 'Флагманская услуга обновлена',
    useListLoad: useFlagshipListLoadQuery,
    useItemLoad: useFlagshipLoadQuery,
    useItemCreate: useFlagshipCreateMutation,
    useItemRemove: useFlagshipRemoveMutation,
    useItemUpdate: useFlagshipUpdateMutation,
    useItemInfoUpdate: useFlagshipInfoUpdateMutation,
  },
};

// roles

export const SERVICE_ROLE_PREFIX = 'roles_services';

export function getServiceRoleAdmin(type: ServiceType) {
  return `${SERVICE_ROLE_PREFIX}_${type}_${ROLE_ADMIN_SUFFIX}`;
}

const serviceTypeRoleMap: Record<ServiceType, string> = {
  [ServiceType.Simple]: getServiceRoleAdmin(ServiceType.Simple),
  [ServiceType.Premium]: getServiceRoleAdmin(ServiceType.Premium),
  [ServiceType.Flagship]: getServiceRoleAdmin(ServiceType.Flagship),
};


export function canViewServices(roles: KeycloakRoles['roles']): boolean {
  return roles.some((item) => item.startsWith(SERVICE_ROLE_PREFIX));
}

export function canViewServiceTable(roles: KeycloakRoles['roles'], type: ServiceType): boolean {
  return roles.includes(serviceTypeRoleMap[type]);
}
