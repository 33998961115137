import { ServiceType } from 'features/Service';
import { useCallback, useEffect, useState } from 'react';
import { useLazyStationServiceListLoadQuery } from './StationService.api';
import { IStationService, IStationServiceSimple, StationServiceListRequest } from './StationService.intefrace';
import { useLazyStationServiceSimpleListLoadQuery, useStationServiceSimpleRemoveMutation } from './StationServiceSimple.api';

export function useStationServiceLoad(params: StationServiceListRequest): [IStationService, any] {
  const [stationServiceLoad, flags] = useLazyStationServiceListLoadQuery();
  const [stationService, setStationService] = useState<IStationService>();

  const handleStationServiceListLoad = useCallback(async () => {
    if (!params) {
      return;
    }

    try {
      const res = await stationServiceLoad(params).unwrap();
      setStationService(res[0]);
    } catch (e) {
      console.error('error station service load', e);
    }
  }, [params, stationServiceLoad]);

  useEffect(() => {
    handleStationServiceListLoad();
  }, [handleStationServiceListLoad]);

  return [stationService, flags];
}

export function useStationServiceSimpleLoad(id: number, params: StationServiceListRequest): [IStationService, IStationServiceSimple, any] {
  const [stationService] = useStationServiceLoad(params);
  const [stationServiceSimpleListLoad, flags] = useLazyStationServiceSimpleListLoadQuery();
  const [stationServiceSimple, setStationServiceSimple] = useState<IStationServiceSimple>();

  const handleStationServiceSimpleLoad = useCallback(async () => {
    if (!stationService) {
      return;
    }
    const res = await stationServiceSimpleListLoad(stationService.id).unwrap();
    const found = res.find((item) => item.id === id);
    setStationServiceSimple(found);
  }, [id, stationService, stationServiceSimpleListLoad]);

  useEffect(() => {
    handleStationServiceSimpleLoad();
  }, [handleStationServiceSimpleLoad]);

  return [stationService, stationServiceSimple, flags];
}

export function useStationServiceSimpleListRemove() {
  const [stationServiceSimpleListLoad] = useLazyStationServiceSimpleListLoadQuery();
  const [stationServiceSimpleRemove] = useStationServiceSimpleRemoveMutation();

  return useCallback(async (stationService: IStationService) => {
    if (stationService.type !== ServiceType.Flagship) {
      return;
    }
    const simpleList = await stationServiceSimpleListLoad(stationService.id).unwrap();
    const promises = simpleList.map((item) => stationServiceSimpleRemove({
      simple_service_id: item.simple_service_id,
      station_service_id: item.station_service_id,
    }).unwrap());
    await Promise.all(promises);
  }, [stationServiceSimpleListLoad, stationServiceSimpleRemove]);
}
