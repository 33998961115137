import { Form } from 'antd';
import { FormFileUpload } from 'features/Form';
import { IPagePageEditFormProps } from '../../Page.interface';

export function PagePageFormInternal({ initialValues, onFormUpdate }: IPagePageEditFormProps) {
  return (
    <div>
      <Form.Item
        label="Баннер"
        name="banner"
      >
        <div className="mb-2">
          <FormFileUpload
            value={initialValues.banner}
            onChange={onFormUpdate('banner')}
          />
        </div>
      </Form.Item>
    </div>
  );
}
