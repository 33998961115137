import { FileImageOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useCallback, useRef } from 'react';
import { ReactEditor, useSlate } from 'slate-react';
import { insertImagesFromFiles } from './RichText.image';

export function RichTextImageButton() {
  const editor = useSlate();
  const inputRef = useRef<HTMLInputElement>();

  const handleFileSelect = useCallback(() => {
    inputRef.current.click();
  }, []);

  const handleUpload = useCallback((e: any) => {
    e.preventDefault();
    const { files } = e.target;
    if (files.length === 0) {
      return;
    }

    insertImagesFromFiles(editor, files);
    ReactEditor.focus(editor);
  }, [editor]);

  return (
    <>
      <Button
        onClick={handleFileSelect}
        icon={<FileImageOutlined />}
        title="Добавить изображение"
        size="small"
        type="text"
      />
      <input
        className="display-none"
        ref={inputRef}
        onChange={handleUpload}
        type="file"
        multiple
        accept="image/*"
      />
    </>
  );
}
