import { FormInstance, FormListFieldData, FormListOperation } from 'antd';
import { ReactNode } from 'react';

export interface FormOption {
  label: string;
  value: number | string;
  disabled?: boolean;
}

export interface FormListItemMeta {
  groupName: string;
}

export interface FormListItemProps<T = any> {
  field: FormListFieldData;
  form: FormInstance;
  meta: T;
}

export interface FormListMetaBase {
  onAdd: FormListOperation['add'];
  onRemove: FormListOperation['remove'];
}

export interface FormListOptions<T> {
  addLabel: string;
  addButton: ReactNode;
  isAdd: boolean;
  mode: FormListItemModeTypes;
  model: T;
  form: FormInstance;
  meta: FormListMetaBase & any;
}

export enum FormListItemModeTypes {
  Normal= 'normal',
  Inline = 'inline',
  Compact = 'compact',
}
