import { Button, Table } from 'antd';
import { useNewsTagListLoadQuery } from '../../News.api';
import { newsTagColumnList } from '../../News.model';

export function NewsTagTable() {
  const { data = [], isLoading } = useNewsTagListLoadQuery();

  return (
    <div>
      <Button href="/news/tag/create" className="mb-6">
        Создать тэг
      </Button>
      <Table
        loading={isLoading}
        columns={newsTagColumnList}
        dataSource={data}
        rowKey="id"
        pagination={false}
      />
    </div>
  );
}
