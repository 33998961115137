import { ThemeConfig } from 'antd';
import locale from 'antd/locale/ru_RU';
import dayjs from 'dayjs';

import 'dayjs/locale/ru';

dayjs.locale('ru');

export const appLocale = locale;

export const appTheme: ThemeConfig = {
  components: {
    Layout: {
      bodyBg: '#fff',
      siderBg: '#cbd5e1',
    },
  },
};
