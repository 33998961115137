import { useMemo } from 'react';
import { createEditor, Editor } from 'slate';
import { withHistory } from 'slate-history';
import { withReact } from 'slate-react';
import { withImage } from './plugins/image/RichText.image';
import { withRichTextCommon } from './plugins/RichText.common';
import { withYoutube } from './plugins/RichText.youtube';
import { withURL } from './plugins/url/RichText.url';
import { PluginFunc, RichTextEditorOptions } from './RichText.interface';

export function useRichTextEditor(options: RichTextEditorOptions): Editor {
  return useMemo(() => {
    // base
    const pluginList: PluginFunc[] = [withReact, withHistory, withRichTextCommon];

    if (options.url) {
      pluginList.push(withURL);
    }

    // must be after url
    if (options.youtube) {
      pluginList.push(withYoutube);
    }
    if (options.image) {
      pluginList.push(withImage);
    }

    return pluginList.reduce((memo, item) => item(memo), createEditor());
  }, [options]);
}
