import { useAuthRoles } from 'features/Auth';
import { useMemo } from 'react';
import { useDirectorateListWithRoles } from '../Directorate/Directorate.hook';
import { canViewDirectorate } from '../Directorate/Directorate.model';
import { useStationListLoadQuery } from './Station.api';
import { getStationRoleAdmin, STATION_ADMIN_ROLE, STATION_ROLE_PREFIX } from './Station.model';

export function useStationRoles() {
  return useAuthRoles(STATION_ROLE_PREFIX);
}

export function useStationIsAdmin(slug?: string) {
  const roles = useStationRoles();
  const isStationAdmin = roles.includes(STATION_ADMIN_ROLE);
  return slug
    ? isStationAdmin || roles.includes(getStationRoleAdmin(slug))
    : isStationAdmin;
}

export function useStationIsCreate() {
  const roles = useAuthRoles();
  const isAdmin = useStationIsAdmin();
  return isAdmin || canViewDirectorate(roles);
}

export function useStationListWithRoles() {
  const { data = [], isLoading: isStationListLoading } = useStationListLoadQuery();
  const isAdmin = useStationIsAdmin();
  const { data: directorateList, isLoading: isDirectorateListLoading } = useDirectorateListWithRoles();

  const filtered = useMemo(() => {
    if (isAdmin) {
      return data;
    }
    const directorateSlugList = directorateList.map((item) => item.slug);
    return data.filter((item) => directorateSlugList.includes(item.directorate_slug));
  }, [data, directorateList, isAdmin]);

  return {
    data: filtered,
    isLoading: isStationListLoading || isDirectorateListLoading,
  };
}
