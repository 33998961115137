import { Typography } from 'antd';
import { Fragment } from 'react';
import { SlateRenderLeafProps } from '../../RichText.interface';

export function RichTextLeaf({ attributes, children, leaf }: Partial<SlateRenderLeafProps>) {
  if (!children) {
    // placeholder for empty content, must have attributes
    return (
      <Fragment {...attributes}>
        {children}
      </Fragment>
    );
  }

  return (
    <Typography.Text
      strong={leaf.bold}
      italic={leaf.italic}
      underline={leaf.underline}
      delete={leaf.strikethrough}
      {...attributes}
    >
      {children}
    </Typography.Text>
  );
}
