import { BoldOutlined, ItalicOutlined, StrikethroughOutlined, UnderlineOutlined } from '@ant-design/icons';
import { Button, Flex } from 'antd';
import type { ButtonType } from 'antd/es/button/buttonHelpers';
import { KeyboardEvent, MouseEvent, useCallback, useMemo, useState } from 'react';
import { Editor, Element } from 'slate';
import { Editable, ReactEditor, Slate } from 'slate-react';
import { RichTextImageButton } from '../../plugins/image/RichTextImageButton';
import { RichTextEditor } from '../../plugins/RichText.common';
import { RichTextLinkButton } from '../../plugins/url/RichTextLinkButton';
import { useRichTextEditor } from '../../RichText.hook';
import { ISlateDescendant, RichTextEditorOptions, SlateMarkTypes } from '../../RichText.interface';
import { createElementParagraph, richTextOptionDefault } from '../../RichText.model';
import { RichTextRenderElement } from '../RichTextElement/RichTextElement';
import { RichTextLeaf } from '../RichTextLeaf/RichTextLeaf';
import { RichTextView } from '../RichTextView/RichTextView';

function getButtonType(editor: Editor, mark: SlateMarkTypes): ButtonType {
  return !!Editor.marks(editor)?.[mark] ? 'default' : 'text';
}

export function RichText({ value, options: initialOptions, onChange, isDev = false }: {
  value: string;
  options?: Partial<RichTextEditorOptions>;
  onChange: (value: string) => any;
  isDev?: boolean;
}) {
  const [result, setResult] = useState<string>();

  const options = useMemo((): RichTextEditorOptions => ({
    ...richTextOptionDefault,
    ...initialOptions,
  }), [initialOptions]);

  const editor = useRichTextEditor(options);

  // parse value
  const initialValue = useMemo((): ISlateDescendant[] => {
    const empty = [createElementParagraph()];
    if (!value) {
      return empty;
    }
    try {
      return JSON.parse(value);
    } catch (e) {
      return empty;
    }
  }, [value]);

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    // TODO @dkchv: review again hot keys: meta/ctrl for mac/win
    if (!options.formatting || !event.metaKey) {
      return;
    }

    switch (event.key) {
      case 'b': {
        RichTextEditor.markToggle(editor, 'bold');
        break;
      }
      case 'i': {
        RichTextEditor.markToggle(editor, 'italic');
        break;
      }
    }
  }, [editor, options]);

  const toggleMark = useCallback((mark: SlateMarkTypes) => (e: MouseEvent) => {
    e.preventDefault();
    RichTextEditor.markToggle(editor, mark);
  }, [editor]);

  const handleChange = useCallback((value: ISlateDescendant[]) => {
    const isEmpty = value.every((item) => Element.isElement(item) && editor.isEmpty(item));
    const content = isEmpty ? '' : JSON.stringify(value);
    if (isDev) {
      setResult(content);
    }
    onChange?.(content);
  }, [editor, isDev, onChange]);

  const handleCloseModal = useCallback(() => {
    ReactEditor.focus(editor);
  }, [editor]);

  return (
    <Slate
      editor={editor}
      initialValue={initialValue}
      onValueChange={handleChange}
    >
      {/* toolbar */}
      <Flex gap={4} className="mb-2">
        {options.formatting && (
          <Flex gap={2}>
            <Button
              onClick={toggleMark('bold')}
              icon={<BoldOutlined />}
              title="Жирный"
              size="small"
              type={getButtonType(editor, 'bold')}
            />
            <Button
              onClick={toggleMark('italic')}
              icon={<ItalicOutlined />}
              title="Курсивный"
              size="small"
              type={getButtonType(editor, 'italic')}
            />
            <Button
              onClick={toggleMark('underline')}
              icon={<UnderlineOutlined />}
              title="Подчеркнутый"
              size="small"
              type={getButtonType(editor, 'underline')}
            />
            <Button
              onClick={toggleMark('strikethrough')}
              icon={<StrikethroughOutlined />}
              title="Зачеркнутый"
              size="small"
              type={getButtonType(editor, 'strikethrough')}
            />
          </Flex>
        )}

        <Flex gap={2}>
          {options.link && <RichTextLinkButton editor={editor} onClose={handleCloseModal} />}
          {options.image && <RichTextImageButton />}
        </Flex>
      </Flex>

      {/* editor */}
      <Editable
        className="border-1 rounded-md pt-2 px-2 pb-1 min-h-20"
        renderElement={RichTextRenderElement}
        renderLeaf={RichTextLeaf}
        onKeyDown={handleKeyDown}
      />

      {isDev && (
        <div className="mt-2">
          <div>result:</div>
          <div className="border-1">
            <RichTextView value={result} />
          </div>
        </div>
      )}

    </Slate>
  );
}
