import { UploadFile } from 'antd';
import { clientMediaUpload } from './Media.client';
import { IMediaAttachment } from './Media.interface';

export const PUBLIC_MEDIA_HOST = 'https://mgn.dzvr.ru';

export function getMediaUrl(src: string = ''): string {
  // workaround for urls without origin
  return String(src).startsWith('/media')
    ? `${PUBLIC_MEDIA_HOST}${src}`
    : src;
}

function createMediaAttach(link: string = '', isAlt: boolean): IMediaAttachment {
  return isAlt
    ? {
      type: 'img',
      link,
      alt: '',
    }
    : {
      type: 'img',
      link,
    };
}

export function createUploadFile(url: string, index?: number | string): UploadFile {
  return {
    uid: String(index || url),
    name: url,
    url: getMediaUrl(url),
    status: 'done',
  };
}

export function mediaAttachmentToUploadFile(list: IMediaAttachment[]): UploadFile[] {
  return list.map((item, index) => createUploadFile(item.link, index));
}

export function mediaSingleToUploadFile(link?: string): UploadFile[] {
  if (!link) {
    return [];
  }
  return [createUploadFile(link)];
}

export function mediaArrayToUploadFile(list: string[] = []): UploadFile[] {
  return list.map((item, index) => createUploadFile(item, index));
}

async function mediaUploadFile(prefix: string, file: File, isAlt: boolean): Promise<IMediaAttachment> {
  const link = await clientMediaUpload(prefix, file);
  return createMediaAttach(link, isAlt);
}

export class MediaUploadError extends Error {
  constructor() {
    super('Ошибка при загрузке изображений');
  }
}

export function mediaUploadFiles(prefix: string, files: UploadFile[], isAlt = false): Promise<IMediaAttachment[]> {
  if (!files) {
    return Promise.resolve([]);
  }
  try {
    const promises: Promise<IMediaAttachment>[] = files.map((item): Promise<IMediaAttachment> => {
      if (item.url) {
        return Promise.resolve(createMediaAttach(item.url, isAlt));
      }
      return mediaUploadFile(prefix, item.originFileObj, isAlt);
    });
    return Promise.all(promises);
  } catch (e) {
    throw new MediaUploadError();
  }
}
