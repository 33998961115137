import { App } from 'antd';
import { LANGUAGE_DEFAULT } from 'features/Language';
import { LayoutContent } from 'features/Layout';
import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { usePageCreateMutation, usePageInfoUpdateMutation } from '../../Page.api';
import { usePageConfig } from '../../Page.hooks';
import { IPageCreateFormParams, IPageFormBase, IPageFormInfo } from '../../Page.interface';
import { pageEditUrl, pageTableUrl } from '../../Page.model';

export function PageCreate() {
  const { type } = useParams<IPageCreateFormParams>();
  const navigate = useNavigate();
  const { message } = App.useApp();
  const [pageCreate] = usePageCreateMutation();
  const [pageInfoUpdate] = usePageInfoUpdateMutation();
  const backUrl = useMemo(() => pageTableUrl(type), [type]);
  const [config, PageForm] = usePageConfig(type);

  const handleFinish = useCallback(async (base: IPageFormBase, info: IPageFormInfo) => {
    const result = await pageCreate({
      ...base,
      view_type: type,
    }).unwrap();
    await pageInfoUpdate({
      ...base,
      ...info,
      lang: LANGUAGE_DEFAULT,
      view_type: type,
    }).unwrap();
    message.success(config.createSuccess);
    navigate(pageEditUrl(type, result.slug));
  }, [message, navigate, pageCreate, pageInfoUpdate, type, config]);

  return (
    <LayoutContent
      title={config.createLabel}
      backUrl={backUrl}
    >
      <PageForm
        templateOptions={config.formTemplateOptions}
        onFinish={handleFinish}
      />
    </LayoutContent>
  );
}
