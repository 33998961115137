import { Typography } from 'antd';
import { Ancestor, Editor, Element, Node, Text } from 'slate';
import { isURL } from 'utils/url';
import { ISlateElementLink, SlateElementTypes, SlateRenderElementProps } from '../../RichText.interface';
import { RichTextEditor } from '../RichText.common';

export function RichTextURL({ attributes, children, element }: Partial<SlateRenderElementProps<ISlateElementLink>>) {
  return (
    <Typography.Link href={element.url} {...attributes}>
      {children}
    </Typography.Link>
  );
}

const linkStopBreakList = [' ', ',', ';', '.'];
const linkStartParseList = [' ', ',', ';'];

function isElementLink(el: Ancestor) {
  return Element.isElement(el) && el.type === SlateElementTypes.Link;
}

export function createLinkNode(url: string, text?: string): ISlateElementLink {
  return {
    type: SlateElementTypes.Link,
    url,
    children: [{ text: text || url }],
  };
}

export function withURL(editor: Editor): Editor {
  const { insertText, deleteBackward } = editor;

  editor.insertText = (text: string) => {
    const isTextURL = isURL(text);
    const isEmptySelection = RichTextEditor.isCollapsed(editor.selection);

    // paste url as plain text
    if (isTextURL && isEmptySelection) {
      const content: Node[] = [createLinkNode(text)];

      // add space at the end of url if not exists
      const next = editor.after(editor.selection);
      const nextText = editor.string({
        anchor: editor.selection.anchor,
        focus: next || editor.selection.focus,
      });
      if (nextText !== ' ') {
        content.push({ text: ' ' });
      }
      editor.insertFragment(content);
      return;
    }

    // wrap selected text with link
    if (isTextURL && !isEmptySelection) {
      editor.wrapNodes(createLinkNode(text), {
        split: true,
      });
      return;
    }

    // check last text block is url and wrap it into link
    if (isEmptySelection && linkStartParseList.includes(text)) {
      const { selection } = editor;
      const [node, path] = editor.node(selection);
      const nodeText = (node as Text).text;
      const beforeWithLink = nodeText.substring(0, selection.anchor.offset);
      const after = nodeText.substring(editor.selection.anchor.offset, nodeText.length);
      const lastSpace = beforeWithLink.lastIndexOf(' ');
      const lastWord = beforeWithLink.substring(lastSpace + 1, beforeWithLink.length);
      const before = beforeWithLink.substring(0, lastSpace + 1);
      const offset = after.length > 0 ? 1 : 0;

      if (isURL(lastWord)) {
        editor.removeNodes({ at: path });
        editor.insertNodes([
          { text: before },
          createLinkNode(lastWord),
          { text: `${text}${after}` },
        ], {
          at: path,
        });
        const newPath = [...selection.anchor.path];
        newPath[newPath.length - 1] += 2;
        const point = { path: newPath, offset };
        editor.setSelection({
          anchor: point,
          focus: point,
        });
        return;
      }
    }

    // stop link block at the end of url if pressed break symbol
    if (isEmptySelection && linkStopBreakList.includes(text)) {
      const [parent] = editor.parent(editor.selection);
      if (isElementLink(parent)) {
        editor.insertFragment([{ text }]);
        return;
      }
    }

    insertText(text);
  };

  editor.deleteBackward = (...args) => {
    // for single character only
    if (RichTextEditor.isCollapsed(editor.selection)) {
      // not at start
      const prev = editor.before(editor.selection);
      if (prev) {
        const [parent, parentPath] = editor.parent(prev);
        // remove link from prev link el
        if (isElementLink(parent)) {
          editor.unwrapNodes({ at: parentPath });
          return;
        }
      }
    }

    deleteBackward(...args);
  };

  return editor;
}
