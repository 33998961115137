import { cva } from 'class-variance-authority';
import { Bold, Check, ChevronDown, ChevronRight, Combine, ExternalLink, Film, GripVertical, Heading1, Heading2, Heading3, Heading4, Heading5, Heading6, Image, Italic, Link2, Link2Off, List, ListOrdered, Minus, Pen, Pilcrow, Plus, RectangleHorizontal, RectangleVertical, Strikethrough, Table, Text, Trash, Underline, Ungroup } from 'lucide-react';

export const Icons = {
  Add: Plus,
  ArrowDown: ChevronDown,
  Bold: Bold,
  Check: Check,
  ChevronRight: ChevronRight,
  Column: RectangleVertical,
  Combine: Combine,
  Delete: Trash,
  DragHandle: GripVertical,
  Editing: Pen,
  Embed: Film,
  ExternalLink: ExternalLink,
  H1: Heading1,
  H2: Heading2,
  H3: Heading3,
  H4: Heading4,
  H5: Heading5,
  H6: Heading6,
  Image: Image,
  Italic: Italic,
  Link: Link2,
  Media: Image,
  Minus: Minus,
  OL: ListOrdered,
  Paragraph: Pilcrow,
  Row: RectangleHorizontal,
  Strikethrough: Strikethrough,
  Table: Table,
  Text: Text,
  Trash: Trash,
  UL: List,
  Underline: Underline,
  Ungroup: Ungroup,
  Unlink: Link2Off,
};

export const iconVariants = cva('', {
  defaultVariants: {},
  variants: {
    size: {
      md: 'mr-2 size-6',
      sm: 'mr-2 size-4',
    },
    variant: {
      menuItem: 'mr-2 size-5',
      toolbar: 'size-5',
    },
  },
});
