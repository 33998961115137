// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body, #root {
  height: 100%;
}

[data-slate-editor] .ant-typography {
  margin-bottom: 4px;
}

/* TODO: why this dont work with tailwind? */
.min-h-20 {
  min-height: 5rem;
}

.border-1 {
  border-width: 1px;
}

.display-none {
  display: none !important;
}

/* move upload button to the end of file list */
.upload-list-custom {
  display: flex;
  flex-direction: column-reverse;
  gap: 16px;
}


/* for plate */
.bg-background {
  background-color: #fff;
}

.bg-popover {
  background-color: #fff;
}

.bg-border {
  background-color: #ccc;
}

.text-primary {
  color: #1677ff;
}

.before\\:bg-muted:before {
  background-color: rgba(0,0,0,0.03);
}

.timetable_late {
  width: 0.75rem;
  height: 0.75rem;
  background-color: #ffc000;
  border-radius: 50%;
  line-height: 1;
}
`, "",{"version":3,"sources":["webpack://./src/styles/custom.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;AACpB;;AAEA,4CAA4C;AAC5C;EACE,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA,+CAA+C;AAC/C;EACE,aAAa;EACb,8BAA8B;EAC9B,SAAS;AACX;;;AAGA,cAAc;AACd;EACE,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,cAAc;EACd,eAAe;EACf,yBAAyB;EACzB,kBAAkB;EAClB,cAAc;AAChB","sourcesContent":["html, body, #root {\n  height: 100%;\n}\n\n[data-slate-editor] .ant-typography {\n  margin-bottom: 4px;\n}\n\n/* TODO: why this dont work with tailwind? */\n.min-h-20 {\n  min-height: 5rem;\n}\n\n.border-1 {\n  border-width: 1px;\n}\n\n.display-none {\n  display: none !important;\n}\n\n/* move upload button to the end of file list */\n.upload-list-custom {\n  display: flex;\n  flex-direction: column-reverse;\n  gap: 16px;\n}\n\n\n/* for plate */\n.bg-background {\n  background-color: #fff;\n}\n\n.bg-popover {\n  background-color: #fff;\n}\n\n.bg-border {\n  background-color: #ccc;\n}\n\n.text-primary {\n  color: #1677ff;\n}\n\n.before\\:bg-muted:before {\n  background-color: rgba(0,0,0,0.03);\n}\n\n.timetable_late {\n  width: 0.75rem;\n  height: 0.75rem;\n  background-color: #ffc000;\n  border-radius: 50%;\n  line-height: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
