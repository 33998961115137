import { Flex, Input, Table, TableProps } from 'antd';
import { ReactNode, useCallback, useMemo, useState } from 'react';

export function UITable({ filter, createButton, dataSource, ...props }: {
  createButton?: ReactNode;
  filter?: (search: string, item: any) => boolean;
} & TableProps) {
  const [search, setSearch] = useState('');

  const filtered = useMemo(() => filter && !!search
    ? dataSource.filter((item) => filter(search, item))
    : dataSource, [dataSource, filter, search]);

  const handleChange = useCallback((e: any) => {
    setSearch(e.target.value);
  }, []);

  return (
    <>
      <Flex className="mb-6" justify="space-between">
        {createButton || <div />}
        {filter && (
          <div className="grow-0 w-1/3">
            <Input.Search
              onChange={handleChange}
              placeholder="Найти"
              allowClear
            />
          </div>
        )}
      </Flex>

      <Table
        dataSource={filtered}
        rowKey="id"
        className="pb-6"
        {...props}
      />
    </>
  );
}
