export enum ContactBlockTypes {
  Email = 'email',
  Phone = 'phone',
  Address = 'address',
  Link = 'link',
}

export interface ContactBlock {
  type: ContactBlockTypes;
  text: string;
  link?: string;
  details?: string;
  sub_details?: string;
}

export interface ContactGroups {
  title: string;
  sub_title?: string;
  contacts: ContactBlock[];
}

// form
export interface IContactForm {
  contacts: ContactGroups[];
}
