import { createApi } from '@reduxjs/toolkit/query/react';
import { APP_API_ADMIN_URL, AppApiDevUrl, featureFlags } from 'App.environment';
import { authFetchBaseQuery } from 'features/Auth';
import { ServiceType } from './Service.interface';

const baseUrl = featureFlags.isServiceDev ? AppApiDevUrl.Service : APP_API_ADMIN_URL;

export const serviceBaseApi = createApi({
  reducerPath: 'serviceApi',
  tagTypes: [
    ServiceType.Simple,
    ServiceType.Premium,
    ServiceType.Flagship,
  ],
  baseQuery: authFetchBaseQuery(`${baseUrl}/services/api/v1`),
  endpoints: () => ({}),
});
