import { App } from 'antd';
import { LANGUAGE_DEFAULT } from 'features/Language';
import { LayoutContent } from 'features/Layout';
import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDirectorateManagerCreateMutation, useDirectorateManagerInfoUpdateMutation } from '../../Directorate.api';
import { IDirectorateEditParams, IDirectorateManagerBaseUpdateBody, IDirectorateManagerInfoUpdateBody } from '../../Directorate.interface';
import { directorateManagerTableUrl } from '../../Directorate.model';
import { DirectorateManagerForm } from '../DirectorateManagerForm/DirectorateManagerForm';

export function DirectorateManagerCreate() {
  const navigate = useNavigate();
  const { slug } = useParams<IDirectorateEditParams>();
  const { message } = App.useApp();
  const [directorateManagerCreate] = useDirectorateManagerCreateMutation();
  const [directorateManagerInfoUpdate] = useDirectorateManagerInfoUpdateMutation();
  const backUrl = useMemo(() => directorateManagerTableUrl(slug), [slug]);

  const handleFinish = useCallback(async (base: IDirectorateManagerBaseUpdateBody, info: IDirectorateManagerInfoUpdateBody) => {
    const result = await directorateManagerCreate({
      ...base,
      slug,
    }).unwrap();
    await directorateManagerInfoUpdate({
      ...info,
      slug,
      manager_id: result.id,
      lang: LANGUAGE_DEFAULT,
    }).unwrap();
    message.success('Сотрудник создан');
    navigate(backUrl);
  }, [backUrl, directorateManagerCreate, directorateManagerInfoUpdate, message, navigate, slug]);

  return (
    <LayoutContent
      title="Создать сотрудника"
      backUrl={backUrl}
    >
      <DirectorateManagerForm onFinish={handleFinish} />
    </LayoutContent>
  );
}
