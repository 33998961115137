import { Form, Input } from 'antd';
import { featureFlags } from '../../../../App.environment';
import { FormFileUpload, formRuleRequired, formRuleUrl } from '../../../Form';
import { IPageBlockEditFormProps } from '../../Page.interface';
import { PAGE_BLOCK_LINK_NONE } from '../../Page.model';

export function PageBlockFormImage({ model, initialValues, onFormUpdate }: IPageBlockEditFormProps) {
  return (
    <div>
      <Form.Item
        label="Картинка"
        name="banner"
        rules={[formRuleRequired('Добавьте картинку')]}
        required
      >
        <div className="mb-2">
          <FormFileUpload
            value={initialValues.banner}
            onChange={onFormUpdate('banner')}
          />
        </div>
      </Form.Item>

      {(featureFlags.isDev || model.link !== PAGE_BLOCK_LINK_NONE) && (
        <Form.Item
          label="Дополнительная ссылка"
          name="link"
          rules={!featureFlags.isDev ? [formRuleUrl('Некорректная ссылка')] : []}
        >
          <Input />
        </Form.Item>
      )}

    </div>
  );
}
