import { App } from 'antd';
import { LANGUAGE_DEFAULT } from 'features/Language';
import { LayoutContent } from 'features/Layout';
import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDirectorateManagerInfoUpdateMutation, useDirectorateManagerLoadQuery, useDirectorateManagerRemoveMutation, useDirectorateManagerUpdateMutation } from '../../Directorate.api';
import { IDirectorateManagerBaseUpdateBody, IDirectorateManagerEditParams, IDirectorateManagerInfoUpdateBody, IDirectorateManagerLoadRequest } from '../../Directorate.interface';
import { directorateManagerTableUrl } from '../../Directorate.model';
import { DirectorateManagerForm } from '../DirectorateManagerForm/DirectorateManagerForm';

export function DirectorateManagerEdit() {
  const { slug, manager_id } = useParams<IDirectorateManagerEditParams>();
  const { message } = App.useApp();
  const navigate = useNavigate();
  const [managerRemove] = useDirectorateManagerRemoveMutation();

  const params = useMemo((): IDirectorateManagerLoadRequest => ({
    slug,
    manager_id,
  }), [slug, manager_id]);
  const { data, isLoading } = useDirectorateManagerLoadQuery(params);
  const backUrl = useMemo(() => directorateManagerTableUrl(slug), [slug]);
  const [managerUpdate] = useDirectorateManagerUpdateMutation();
  const [managerInfoUpdate] = useDirectorateManagerInfoUpdateMutation();

  const handleFinish = useCallback(async (base: IDirectorateManagerBaseUpdateBody, info: IDirectorateManagerInfoUpdateBody) => {
    await Promise.all([
      managerUpdate({
        ...base,
        slug,
        manager_id: data.id,
      }).unwrap(),
      managerInfoUpdate({
        ...info,
        slug,
        manager_id: data.id,
        lang: LANGUAGE_DEFAULT,
      }),
    ]);
    message.success('Данные обновлены');
    navigate(backUrl);
  }, [backUrl, data, managerInfoUpdate, managerUpdate, message, navigate, slug]);

  const handleRemove = useCallback(async () => {
    await managerRemove({
      slug,
      manager_id: data.id,
    }).unwrap();
    message.success('Сотрудник удален');
    navigate(backUrl);
  }, [managerRemove, slug, data, message, navigate, backUrl]);

  return (
    <LayoutContent
      title="Редактирование сотрудника"
      backUrl={backUrl}
      isLoading={isLoading}
      render={() => (
        <DirectorateManagerForm
          model={data}
          onFinish={handleFinish}
          onRemove={handleRemove}
        />
      )}
    />
  );
}
