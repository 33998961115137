import { Form, FormInstance } from 'antd';
import { NamePath } from 'rc-field-form/lib/interface';
import { useCallback } from 'react';

type UseFormFormReturn<T> = [
  FormInstance,
  (name: NamePath<keyof T>) => any,
];

export function useFormUpdate<T = any>(form: FormInstance) {
  return useCallback((name: NamePath<keyof T>) => (value: any) => {
    form.setFieldValue(name, value);
  }, [form]);
}

export function useFormForm<T = any>(): UseFormFormReturn<T> {
  const [form] = Form.useForm<T>();
  const handleUpdate = useFormUpdate<T>(form);
  return [form, handleUpdate];
}
