import { useAuthRoles } from 'features/Auth';
import { useMemo } from 'react';
import { useDirectorateListLoadQuery } from './Directorate.api';
import { DIRECTORATE_ROLE_PREFIX, getDirectorateRoleAdmin } from './Directorate.model';

export function useDirectorateListWithRoles() {
  const { data = [], ...rest } = useDirectorateListLoadQuery();
  const roles = useAuthRoles(DIRECTORATE_ROLE_PREFIX);

  const filtered = useMemo(() => data.filter((item) => roles.includes(getDirectorateRoleAdmin(item.slug))), [data, roles]);

  return {
    data: filtered,
    ...rest,
  };
}

export function useDirectorateIsAdmin(slug: string): boolean {
  const roles = useAuthRoles(DIRECTORATE_ROLE_PREFIX);
  return slug
    ? roles.includes(getDirectorateRoleAdmin(slug))
    : false;
}
