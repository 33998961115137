import { RouteProps } from 'react-router-dom';
import { contactRouter } from './features/Contact/Contact.router';
import { directorateRouter } from './features/Directorate/Directorate.router';
import { layoutRouter } from './features/Layout/Layout.router';
import { newsRouter } from './features/News/News.router';
import { pageRouter } from './features/Page/Page.router';
import { roleRouter } from './features/Role/Role.router';
import { serviceRouter } from './features/Service/Service.router';
import { stationRouter } from './features/Station/Station.router';
import { timeTableRouter } from './features/TimeTable/TimeTable.router';
import { userRouter } from './features/User/User.router';

export const appRoutes: RouteProps[] = [
  ...layoutRouter,
  ...newsRouter,
  ...userRouter,
  ...roleRouter,
  ...stationRouter,
  ...directorateRouter,
  ...serviceRouter,
  ...pageRouter,
  ...contactRouter,
  ...timeTableRouter,
];
