import { App } from 'antd';
import { LayoutContent } from 'features/Layout';
import { ServiceType } from 'features/Service';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { objectIsEmpty, objectUpdateDiff } from 'utils/object';
import { useStationServiceListLoadQuery, useStationServiceRemoveMutation } from '../../../StationService/StationService.api';
import { useStationInfoUpdateMutation, useStationLoadQuery, useStationRemoveMutation, useStationUpdateMutation } from '../../Station.api';
import { IStation } from '../../Station.interface';
import { StationForm } from '../StationForm/StationForm';

export function StationEdit() {
  const { slug } = useParams();
  const { message } = App.useApp();
  const navigate = useNavigate();
  const { data, isLoading } = useStationLoadQuery(slug);
  const {
    data: stationServiceList = [],
    isLoading: isStationServiceListLoading,
  } = useStationServiceListLoadQuery({
    station_slug: slug,
    service_type: ServiceType.Simple,
  });
  const [stationUpdate] = useStationUpdateMutation();
  const [stationRemove] = useStationRemoveMutation();
  const [stationInfoUpdate] = useStationInfoUpdateMutation();
  const [stationServiceRemove] = useStationServiceRemoveMutation();

  const handleStationFinish = useCallback(async (values: IStation) => {
    const { info, ...base } = values;
    //  check differences
    const { info: infoDiff, ...baseDiff } = objectUpdateDiff(data, values);

    // make requests
    if (!objectIsEmpty(baseDiff)) {
      await stationUpdate(base).unwrap();
    }
    if (!objectIsEmpty(infoDiff)) {
      await stationInfoUpdate({
        slug,
        ...info,
      }).unwrap();
    }

    message.success('Вокзал обновлен');
    navigate('/stations');
  }, [data, message, navigate, slug, stationInfoUpdate, stationUpdate]);

  const handleStationRemove = useCallback(async () => {
    // remove related services
    const promises = stationServiceList.map((item) => stationServiceRemove(item.id).unwrap());
    await Promise.all(promises);
    // remove station
    await stationRemove(data.slug).unwrap();
    navigate('/stations');
  }, [stationServiceList, stationRemove, data, navigate, stationServiceRemove]);

  return (
    <LayoutContent
      title="Редактирование вокзала"
      backUrl="/stations"
      isLoading={isLoading || isStationServiceListLoading}
      render={() => (
        <StationForm
          model={data}
          onRemove={handleStationRemove}
          onFinish={handleStationFinish}
        />
      )}
    >
    </LayoutContent>
  );
}
