import { LogoutOutlined } from '@ant-design/icons';
import { useKeycloak } from '@react-keycloak/web';
import { Button } from 'antd';
import { useCallback } from 'react';

export function AuthLogoutButton() {
  const { keycloak } = useKeycloak();

  const logout = useCallback(() => {
    keycloak.logout();
  }, [keycloak]);

  return (
    <Button type="link" icon={<LogoutOutlined />} onClick={logout}>
      Выйти из {keycloak.tokenParsed.preferred_username}
    </Button>
  );
}
