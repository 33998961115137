import { IRole } from 'features/Role';

export enum UserTabTypes {
  UserTable = 'userTable',
  RoleTable = 'roleTable',
}

export enum UserFormTabTypes {
  BasicForm = 'basic',
  RoleForm = 'roles',
}

export enum UserRoleTypes {
  Admin = 'roles_users_admin',
}

export interface IUser {
  id: string;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  enabled: boolean;
}

// form

export interface IUserForm extends Omit<IUser, 'id'> {
  password: string;
}

export type IUserRoleForm = Record<IRole['name'], string[]>;

// api

export interface IUserRoleMutationRequest {
  user_id: IUser['id'];
  id: IRole['id'];
  name: IRole['name'];
}
