import { withRef } from '@udecode/cn';
import { focusEditor, insertEmptyElement, useEditorRef } from '@udecode/plate-common';
import { ELEMENT_H1, ELEMENT_H2, ELEMENT_H3 } from '@udecode/plate-heading';
import React, { useCallback } from 'react';
import { Icons } from './icons';
import { ToolbarButton } from './toolbar';

export const HeadingH1Button = withRef<typeof ToolbarButton>((rest, ref) => {
  const editor = useEditorRef();

  const handleCLick = useCallback(() => {
    insertEmptyElement(editor, ELEMENT_H1, {
      select: true,
      nextBlock: true,
    });
    focusEditor(editor);
  }, [editor]);

  return (
    <ToolbarButton
      onClick={handleCLick}
      ref={ref}
      tooltip="Заголовок 1"
      {...rest}
    >
      <Icons.H1 />
    </ToolbarButton>
  );
});

export const HeadingH2Button = withRef<typeof ToolbarButton>((rest, ref) => {
  const editor = useEditorRef();

  const handleCLick = useCallback(() => {
    insertEmptyElement(editor, ELEMENT_H2, {
      select: true,
      nextBlock: true,
    });
    focusEditor(editor);
  }, [editor]);

  return (
    <ToolbarButton
      onClick={handleCLick}
      ref={ref}
      tooltip="Заголовок 2"
      {...rest}
    >
      <Icons.H2 />
    </ToolbarButton>
  );
});

export const HeadingH3Button = withRef<typeof ToolbarButton>((rest, ref) => {
  const editor = useEditorRef();

  const handleCLick = useCallback(() => {
    insertEmptyElement(editor, ELEMENT_H3, {
      select: true,
      nextBlock: true,
    });
    focusEditor(editor);
  }, [editor]);

  return (
    <ToolbarButton
      onClick={handleCLick}
      ref={ref}
      tooltip="Заголовок 3"
      {...rest}
    >
      <Icons.H3 />
    </ToolbarButton>
  );
});
