import { createApi } from '@reduxjs/toolkit/query/react';
import { authFetchBaseQuery } from 'features/Auth';
import { APP_API_PUBLIC_URL } from '../../App.environment';
import { ICatalogCategory, ICatalogStation } from './Catalog.interface';

enum CatalogTagTypes {
  Category = 'category',
  Station = 'station',
}

const CATALOG_CATEGORY_LIST_TAG = {
  type: CatalogTagTypes.Category,
  id: 'catalogCategoryList',
};

const CATALOG_STATION_LIST_TAG = {
  type: CatalogTagTypes.Station,
  id: 'catalogStationList',
};

export const catalogApi = createApi({
  reducerPath: 'catalogApi',
  tagTypes: [
    CatalogTagTypes.Category,
    CatalogTagTypes.Station,
  ],
  baseQuery: authFetchBaseQuery(`${APP_API_PUBLIC_URL}/services/api/v1/catalog_services`),
  endpoints: (builder) => ({
    // category list
    categoryListLoad: builder.query<ICatalogCategory[], void>({
      query: () => '/category_list',
      providesTags: () => [CATALOG_CATEGORY_LIST_TAG],
    }),
    categoryStationListLoad: builder.query<ICatalogStation[], void>({
      query: () => '/station_list',
      providesTags: () => [CATALOG_STATION_LIST_TAG],
    }),
  }),
});

export const {
  useCategoryListLoadQuery,
  useCategoryStationListLoadQuery,
} = catalogApi;
