import { App } from 'antd';
import { LANGUAGE_DEFAULT } from 'features/Language';
import { LayoutContent } from 'features/Layout';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTagCreateMutation } from '../../News.api';
import { INewsTagForm, newsTagTabUrl } from '../../News.interfaces';
import { NewsTagForm } from '../NewsTagForm/NewsTagForm';

export function NewsTagCreate() {
  const navigate = useNavigate();
  const { message } = App.useApp();
  const [tagCreate] = useTagCreateMutation();

  const handleFinish = useCallback(async (values: INewsTagForm) => {
    await tagCreate({
      title: values.title,
      lang: LANGUAGE_DEFAULT,
    }).unwrap();
    message.success('Тэг создан');
    navigate(newsTagTabUrl);
  }, [message, navigate, tagCreate]);

  return (
    <LayoutContent
      title="Создать тэг"
      backUrl={newsTagTabUrl}
    >
      <NewsTagForm onFinish={handleFinish} />
    </LayoutContent>
  );
}
