import { QueryMethodTypes } from 'features/Query';
import { IServiceBaseUpdateRequest, IServiceComplex, IServiceComplexInfo, IServiceComplexInfoBaseUpdateRequest, ServiceType } from './index';
import { serviceBaseApi } from './ServiceBase.api';

const FLAGSHIP_LIST_TAG = {
  type: ServiceType.Flagship,
  id: 'flagshipList',
};

const serviceFlagshipApi = serviceBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    // list
    flagshipListLoad: builder.query<IServiceComplex[], void>({
      query: () => '/flagship_services',
      providesTags: (res = []) => [
        FLAGSHIP_LIST_TAG,
        ...res.map((item) => ({
          type: ServiceType.Flagship,
          id: String(item.slug),
        })),
      ],
    }),
    // one
    flagshipLoad: builder.query<IServiceComplex, string>({
      query: (slug) => `/flagship_services/${slug}`,
      providesTags: (res, error) => error ? [] : [{
        type: ServiceType.Flagship,
        id: String(res.slug),
      }],
    }),
    flagshipCreate: builder.mutation<IServiceComplex, IServiceBaseUpdateRequest>({
      query: (body) => ({
        url: '/flagship_services',
        method: QueryMethodTypes.POST,
        body,
      }),
    }),
    flagshipUpdate: builder.mutation<IServiceComplex, IServiceBaseUpdateRequest>({
      query: ({ slug, ...body }) => ({
        url: `/flagship_services/${slug}`,
        method: QueryMethodTypes.PATCH,
        body,
      }),
      invalidatesTags: (res, err, req) => err
        ? []
        : [FLAGSHIP_LIST_TAG, {
          type: ServiceType.Flagship,
          id: String(req.slug),
        }],
    }),
    flagshipInfoUpdate: builder.mutation<IServiceComplexInfo, IServiceComplexInfoBaseUpdateRequest>({
      query: ({ slug, lang, ...body }) => ({
        url: `/flagship_services/${slug}/info/${lang}`,
        method: QueryMethodTypes.PATCH,
        body,
      }),
      invalidatesTags: (res, err, req) => err
        ? []
        : [FLAGSHIP_LIST_TAG, {
          type: ServiceType.Flagship,
          id: String(req.slug),
        }],
    }),
    flagshipRemove: builder.mutation<void, string>({
      query: (slug) => ({
        url: `/flagship_services/${slug}`,
        method: QueryMethodTypes.DELETE,
      }),
      invalidatesTags: (res, err) => err ? [] : [FLAGSHIP_LIST_TAG],
    }),
  }),
});

export const {
  // list
  useFlagshipListLoadQuery,
  // one
  useFlagshipLoadQuery,
  useFlagshipCreateMutation,
  useFlagshipUpdateMutation,
  useFlagshipInfoUpdateMutation,
  useFlagshipRemoveMutation,
} = serviceFlagshipApi;
