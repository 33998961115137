import { createApi } from '@reduxjs/toolkit/query/react';
import { APP_API_ADMIN_URL, AppApiDevUrl, featureFlags } from 'App.environment';
import { authFetchBaseQuery } from 'features/Auth';
import { QueryMethodTypes } from 'features/Query';
import { IStation, IStationInfo, StationInfoUpdateRequest, StationRequest, StationResponse } from './Station.interface';

export enum StationApiTagTypes {
  Station = 'station',
  StationService = 'stationService',
  StationServiceSimple = 'stationServiceSimple',
}

const STATION_LIST_TAG = {
  type: StationApiTagTypes.Station,
  id: 'stationList',
};

const baseUrl = featureFlags.isStationDev
  ? AppApiDevUrl.Station
  : APP_API_ADMIN_URL;

export const stationApi = createApi({
  reducerPath: 'stationApi',
  tagTypes: [
    StationApiTagTypes.Station,
    StationApiTagTypes.StationService,
    StationApiTagTypes.StationServiceSimple,
  ],
  baseQuery: authFetchBaseQuery(`${baseUrl}/stations/api/v1`),
  endpoints: (builder) => ({
    // station list
    stationListLoad: builder.query<IStation[], void>({
      query: () => '/stations',
      providesTags: (result = []) => [
        STATION_LIST_TAG,
        ...result.map((item) => ({
          type: StationApiTagTypes.Station,
          id: String(item.id),
        })),
      ],
    }),

    // one station
    stationLoad: builder.query<IStation, string>({
      query: (slug) => `/stations/${slug}`,
      providesTags: (result, err) => err ? [] : [{
        type: StationApiTagTypes.Station,
        id: String(result.slug),
      }],
    }),
    stationCreate: builder.mutation<StationResponse, StationRequest>({
      query: (body) => ({
        url: '/stations',
        method: QueryMethodTypes.POST,
        body,
      }),
      invalidatesTags: () => [STATION_LIST_TAG],
    }),
    stationUpdate: builder.mutation<StationResponse, StationRequest>({
      query: ({ slug, ...body }) => ({
        url: `/stations/${slug}`,
        method: QueryMethodTypes.PATCH,
        body,
      }),
      invalidatesTags: (res, err, req) => err
        ? []
        : [STATION_LIST_TAG, {
          type: StationApiTagTypes.Station,
          id: String(req.slug),
        }],
    }),
    stationRemove: builder.mutation<void, string>({
      query: (slug) => ({
        url: `/stations/${slug}`,
        method: QueryMethodTypes.DELETE,
      }),
      invalidatesTags: () => [STATION_LIST_TAG],
    }),
    stationInfoUpdate: builder.mutation<IStationInfo, StationInfoUpdateRequest>({
      query: ({ slug, lang, ...body }) => ({
        url: `/stations/${slug}/info/${lang}`,
        method: QueryMethodTypes.PATCH,
        body,
      }),
      invalidatesTags: (res, err, req) => err ? [] : [
        STATION_LIST_TAG, {
          type: StationApiTagTypes.Station,
          id: String(req.slug),
        }],
    }),
  }),
});

export const {
  useStationListLoadQuery,
  useStationLoadQuery,
  useStationCreateMutation,
  useStationUpdateMutation,
  useStationRemoveMutation,
  useStationInfoUpdateMutation,
} = stationApi;
