import { HolderOutlined } from '@ant-design/icons';
import { DndContext, UniqueIdentifier } from '@dnd-kit/core';
import type { DragEndEvent } from '@dnd-kit/core/dist/types';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button } from 'antd';
import type { TableComponents } from 'rc-table/lib/interface';
import React, { PropsWithChildren, useContext, useMemo } from 'react';
import { RowContextProps, RowProps } from './UIDrag.interface';

const RowContext = React.createContext<RowContextProps>({});

export function UIDragSortable({ items, children, onDragEnd }: PropsWithChildren<{
  items: UniqueIdentifier[];
  onDragEnd: (event: DragEndEvent) => any;
}>) {
  return (
    <DndContext
      modifiers={[restrictToVerticalAxis]}
      onDragEnd={onDragEnd}
    >
      <SortableContext
        items={items}
        strategy={verticalListSortingStrategy}
      >
        {children}
      </SortableContext>
    </DndContext>
  );
}

export function UIDragHandle() {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <Button
      type="text"
      size="small"
      icon={<HolderOutlined />}
      style={{ cursor: 'move' }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
}

function Row(props: RowProps) {
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  const contextValue = useMemo<RowContextProps>(() => ({
    setActivatorNodeRef,
    listeners,
  }), [setActivatorNodeRef, listeners]);

  return (
    <RowContext.Provider value={contextValue}>
      <tr
        {...props}
        ref={setNodeRef}
        style={style}
        {...attributes}
      />
    </RowContext.Provider>
  );
}

export const draggableTableRow: TableComponents<any> = {
  body: {
    row: Row,
  },
};
