import { QueryMethodTypes } from 'features/Query';
import { objectFlatValuesList } from 'utils/object';
import { userApi, UserApiTagTypes } from '../User/User.api';
import { IRole, IRoleMap, IRoleUpdateRequest } from './Role.interface';

const ROLE_LIST_TAG = {
  type: UserApiTagTypes.Role,
  id: 'userRoleList',
};

export const roleApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    // roles
    roleMapLoad: builder.query<IRoleMap, void>({
      query: () => '/roles',
      providesTags: (res, err) => {
        if (err) {
          return [];
        }
        return [
          ROLE_LIST_TAG,
          ...objectFlatValuesList(res).map((item) => ({
            type: UserApiTagTypes.Role,
            id: item.name,
          }))];
      },
    }),
    roleLoad: builder.query<IRole, IRole['name']>({
      query: (name) => `/roles/${name}`,
      providesTags: (res, err) => err ? [] : [{
        type: UserApiTagTypes.Role,
        id: res.name,
      }],
    }),
    roleUpdate: builder.mutation<IRole, IRoleUpdateRequest>({
      query: ({ name, ...body }) => ({
        url: `/roles/${name}`,
        method: QueryMethodTypes.PATCH,
        body,
      }),
      invalidatesTags: (res, err) => err ? [] : [ROLE_LIST_TAG, {
        type: UserApiTagTypes.Role,
        id: res.name,
      }],
    }),
  }),
});

export const {
  // all
  useRoleMapLoadQuery,
  // one
  useRoleLoadQuery,
  useRoleUpdateMutation,
} = roleApi;
