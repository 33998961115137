import { useKeycloak } from '@react-keycloak/web';
import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

export function AuthGuard({ children }: {
  children: ReactElement
}) {
  const { keycloak } = useKeycloak();

  return !keycloak.authenticated
    ? <Navigate to="/login" />
    : children;
}
