import { App } from 'antd';
import { LayoutContent } from 'features/Layout';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserCreateMutation } from '../../User.api';
import { IUserForm, UserFormTabTypes, UserTabTypes } from '../../User.interface';
import { userFormBackUrl, userFormUrl } from '../../Users.model';
import { UserForm } from '../UserForm/UserForm';

export function UserCreate() {
  const navigate = useNavigate();
  const { message } = App.useApp();
  const [userCreate] = useUserCreateMutation();

  const handleFinish = useCallback(async (values: IUserForm) => {
    const result = await userCreate(values).unwrap();
    message.success('Пользователь создан');
    navigate(userFormUrl(result.id, UserFormTabTypes.RoleForm));
  }, [message, navigate, userCreate]);

  return (
    <LayoutContent
      title="Создать пользователя"
      backUrl={userFormBackUrl(UserTabTypes.UserTable)}
      render={() => (
        <UserForm onFinish={handleFinish} />
      )}
    />
  );
}
