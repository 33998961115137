import { Form, Input } from 'antd';
import { formRuleRequired } from 'features/Form';

export function PageBlockFormText() {
  return (
    <Form.Item
      label="Текст"
      name="content"
      rules={[formRuleRequired('Добавьте описание')]}
    >
      <Input.TextArea />
    </Form.Item>
  );
}
