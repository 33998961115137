import { RouteProps } from 'react-router-dom';
import { DirectorateAdministrationTable } from './components/DirectorateAdministrationTable/DirectorateAdministrationTable';
import { DirectorateEdit } from './components/DirectorateEdit/DirectorateEdit';
import { DirectorateManagerCreate } from './components/DirectorateManagerCreate/DirectorateManagerCreate';
import { DirectorateManagerEdit } from './components/DirectorateManagerEdit/DirectorateManagerEdit';
import { DirectorateTable } from './components/DirectorateTable/DirectorateTable';

export const directorateRouter: RouteProps[] = [
  // directorates
  {
    path: '/directorate',
    Component: DirectorateTable,
  },
  {
    path: '/directorate/:slug',
    Component: DirectorateEdit,
  },
  {
    path: '/directorate/:slug/manager/create',
    Component: DirectorateManagerCreate,
  },
  {
    path: '/directorate/:slug/manager/:manager_id',
    Component: DirectorateManagerEdit,
  },
  // administration
  {
    path: '/administration',
    Component: DirectorateAdministrationTable,
  },
];
