import { Button, Table } from 'antd';
import { ServiceType } from 'features/Service';
import { useMemo } from 'react';
import { stationServiceSimpleCreateUrl, stationServiceSimpleTableColumnList } from '../../StationService.model';
import { useStationServiceSimpleListLoadQuery } from '../../StationServiceSimple.api';

export function StationServiceSimpleTable({ stationSlug,  serviceSlug, serviceType, stationServiceId }: {
  stationSlug: string;
  serviceSlug: string;
  serviceType: ServiceType;
  stationServiceId: number;
}) {
  const { data = [], isLoading } = useStationServiceSimpleListLoadQuery(stationServiceId);

  // TODO @dkchv: must be fixed on BE: remove SSSS item on service remove
  const filtered = useMemo(() => data.filter((item) => !!item.service_base_info), [data]);

  return (
    <div>
      <Button
        href={stationServiceSimpleCreateUrl(serviceType, serviceSlug, stationSlug)}
        className="mb-6"
        disabled={isLoading}
      >
        Добавить услугу
      </Button>
      <Table
        columns={stationServiceSimpleTableColumnList(serviceType, serviceSlug, stationSlug)}
        loading={isLoading}
        dataSource={filtered}
        rowKey="id"
        pagination={false}
      />
    </div>
  );
}
