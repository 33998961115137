import { QueryMethodTypes } from 'features/Query';
import { stationApi, StationApiTagTypes } from '../Station/Station.api';
import { IStationServiceSimple, IStationServiceSimpleInfo, StationServiceSimpleCreateResponse, StationServiceSimpleInfoUpdateRequest, StationServiceSimpleMutationRequest } from './StationService.intefrace';

const STATION_SERVICE_SIMPLE_LIST_TAG = {
  type: StationApiTagTypes.StationServiceSimple,
  id: 'stationServiceSimpleList',
};

export const stationServiceSimpleApi = stationApi.injectEndpoints({
  endpoints: (builder) => ({
    stationServiceSimpleListLoad: builder.query<IStationServiceSimple[], number>({
      query: (id) => `/station_services/${id}/simple_services`,
      providesTags: [STATION_SERVICE_SIMPLE_LIST_TAG],
    }),
    stationServiceSimpleCreate: builder.mutation<StationServiceSimpleCreateResponse, StationServiceSimpleMutationRequest>({
      query: (body) => ({
        url: `/station_services/${body.station_service_id}/simple_services`,
        method: QueryMethodTypes.POST,
        body,
      }),
    }),
    stationServiceSimpleUpdate: builder.mutation<IStationServiceSimpleInfo, StationServiceSimpleInfoUpdateRequest>({
      query: ({ station_service_id, simple_service_id, lang, ...body }) => ({
        url: `/station_services/${station_service_id}/simple_services/${simple_service_id}/info/${lang}`,
        method: QueryMethodTypes.PATCH,
        body,
      }),
      invalidatesTags: [STATION_SERVICE_SIMPLE_LIST_TAG],
    }),
    stationServiceSimpleRemove: builder.mutation<void, StationServiceSimpleMutationRequest>({
      query: ({ station_service_id, simple_service_id }) => ({
        url: `/station_services/${station_service_id}/simple_services/${simple_service_id}`,
        method: QueryMethodTypes.DELETE,
      }),
      invalidatesTags: [STATION_SERVICE_SIMPLE_LIST_TAG],
    }),
  }),
});

export const {
  useStationServiceSimpleListLoadQuery,
  useLazyStationServiceSimpleListLoadQuery,

  useStationServiceSimpleCreateMutation,
  useStationServiceSimpleUpdateMutation,
  useStationServiceSimpleRemoveMutation,
} = stationServiceSimpleApi;
