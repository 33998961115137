import { TabsProps } from 'antd';
import { LayoutContent } from 'features/Layout';
import { UITabs } from 'features/UI';
import { useMemo } from 'react';
import { NewsTabTypes } from '../../News.interfaces';
import { NewsNewsTable } from '../NewsNewsTable/NewsNewsTable';
import { NewsTagTable } from '../NewsTagTable/NewsTagTable';

export function NewsTable() {
  const tabs = useMemo((): TabsProps['items'] => [
    {
      key: NewsTabTypes.News,
      label: 'Новости',
      children: <NewsNewsTable />,
    },
    {
      key: NewsTabTypes.Tags,
      label: 'Тэги',
      children: <NewsTagTable />
    }
  ], []);

  return (
    <LayoutContent title="Новости">
      <UITabs
        defaultActiveKey={NewsTabTypes.News}
        items={tabs}
      />
    </LayoutContent>
  );
}
