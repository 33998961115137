import { useKeycloak } from '@react-keycloak/web';
import { useMemo } from 'react';

export function useAuthRoles(prefix = '') {
  const { keycloak } = useKeycloak();
  return useMemo(() => {
    const all = keycloak.tokenParsed.realm_access.roles;
    return all.filter((item) => item.startsWith(prefix));
  }, [keycloak, prefix]);
}
