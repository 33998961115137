import { Button } from 'antd';
import { UITableSortable } from 'features/UI';
import React, { useCallback } from 'react';
import { useDirectorateManagerListLoadQuery, useDirectorateManagerSortMutation } from '../../Directorate.api';
import { IDirectorateManager } from '../../Directorate.interface';
import { directorateManagerColumnList, directorateManagerCreateFormUrl } from '../../Directorate.model';

export function DirectorateManagerTable({ slug }: {
  slug: string;
}) {
  const { data = [], isLoading } = useDirectorateManagerListLoadQuery(slug);
  const [managerSort] = useDirectorateManagerSortMutation();

  const handleDragEnd = useCallback(async (value: IDirectorateManager[]) => {
    try {
      await managerSort({
        slug,
        indexes: value.map((item) => item.id),
      }).unwrap();
    } catch (e) {
      console.error('error manager sort', e);
    }
  }, [managerSort, slug]);

  return (
    <UITableSortable
      onDragEnd={handleDragEnd}
      createButton={(
        <Button href={directorateManagerCreateFormUrl(slug)}>
          Добавить сотрудника
        </Button>
      )}
      loading={isLoading}
      columns={directorateManagerColumnList(slug)}
      dataSource={data}
      className="pb-6"
      pagination={false}
    />
  );
}
