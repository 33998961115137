import { useParams } from 'react-router-dom';
import { StationServiceEdit } from '../../../StationService/components/StationServiceEdit/StationServiceEdit';
import { StationServiceEditParams } from '../../../StationService/StationService.intefrace';
import { serviceStationFormBackUrl } from '../../Service.model';

export function ServiceStationServiceEdit() {
  const { serviceType, serviceSlug, stationSlug } = useParams<StationServiceEditParams>();
  return (
    <StationServiceEdit
      serviceSlug={serviceSlug}
      serviceType={serviceType}
      stationSlug={stationSlug}
      backUrl={serviceStationFormBackUrl(serviceType, serviceSlug)}
    />
  );
}
