import { createApi } from '@reduxjs/toolkit/query/react';
import { APP_API_ADMIN_URL } from 'App.environment';
import { authFetchBaseQuery } from 'features/Auth';
import { QueryMethodTypes } from 'features/Query';
import { IRoleCreateResponse, IRoleMap } from 'features/Role';
import { IUser, IUserForm, IUserRoleMutationRequest } from './User.interface';

export enum UserApiTagTypes {
  User = 'user',
  Role = 'role',
  UserRole = 'userRole',
}

const USER_USER_LIST_TAG = {
  type: UserApiTagTypes.User,
  id: 'userList',
};

export const userApi = createApi({
  reducerPath: 'userApi',
  tagTypes: [
    UserApiTagTypes.User,
    UserApiTagTypes.Role,
    UserApiTagTypes.UserRole,
  ],
  baseQuery: authFetchBaseQuery(`${APP_API_ADMIN_URL}/users/api/v1`),
  endpoints: (builder) => ({
    // list
    userListLoad: builder.query<IUser[], void>({
      query: () => '/users',
      providesTags: () => [USER_USER_LIST_TAG],
    }),
    // one
    userLoad: builder.query<IUser, IUser['id']>({
      query: (id) => `/users/${id}`,
      providesTags: (res, error, id) => error ? [] : [{
        type: UserApiTagTypes.User,
        id,
      }],
    }),
    userCreate: builder.mutation<IUser, IUserForm>({
      query: (body) => ({
        url: '/users',
        method: QueryMethodTypes.POST,
        body,
      }),
    }),
    userUpdate: builder.mutation<IUser, IUser>({
      query: ({ id, ...body }) => ({
        url: `/users/${id}`,
        method: QueryMethodTypes.PATCH,
        body,
      }),
      invalidatesTags: (result, err) => [USER_USER_LIST_TAG, {
        type: UserApiTagTypes.User,
        id: result.id,
      }],
    }),
    userRemove: builder.mutation<void, IUser['id']>({
      query: (id) => ({
        url: `/users/${id}`,
        method: QueryMethodTypes.DELETE,
      }),
      invalidatesTags: () => [USER_USER_LIST_TAG],
    }),

    // user roles
    userRoleListLoad: builder.query<IRoleMap, IUser['id']>({
      query: (id) => ({
        url: `/users/${id}/roles`,
      }),
      providesTags: (res, err, req) => err ? [] : [{
        type: UserApiTagTypes.UserRole,
        id: req,
      }],
    }),
    userRoleCreate: builder.mutation<IRoleCreateResponse, IUserRoleMutationRequest>({
      query: ({ user_id, ...body }) => ({
        url: `/users/${user_id}/roles`,
        method: QueryMethodTypes.POST,
        body,
      }),
      invalidatesTags: (res, err, req) => err ? [] : [{
        type: UserApiTagTypes.UserRole,
        id: req.user_id,
      }],
    }),
    userRoleRemove: builder.mutation<void, IUserRoleMutationRequest>({
      query: ({ user_id, ...body }) => ({
        url: `/users/${user_id}/roles`,
        method: QueryMethodTypes.DELETE,
        body,
      }),
      invalidatesTags: (res, err, req) => err ? [] : [{
        type: UserApiTagTypes.UserRole,
        id: req.user_id,
      }],
    }),
  }),
});

export const {
  // user list
  useUserListLoadQuery,
  // user one
  useUserLoadQuery,
  useUserCreateMutation,
  useUserRemoveMutation,
  useUserUpdateMutation,
  // user roles
  useUserRoleListLoadQuery,
  useUserRoleCreateMutation,
  useUserRoleRemoveMutation,
} = userApi;
