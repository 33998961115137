import { TabsProps } from 'antd';
import { ServiceType } from 'features/Service';
import { StationServiceTable } from 'features/StationService';
import { UITabs } from 'features/UI';
import type { KeycloakRoles } from 'keycloak-js';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { canViewServiceTable, serviceCRUDMap } from '../../../Service/Service.model';

export function StationServiceTabs({ roles }: {
  roles: KeycloakRoles['roles'];
}) {
  const { slug } = useParams();

  const tabs = useMemo((): TabsProps['items'] => {
    return Object
      .values(ServiceType)
      .filter((item) => canViewServiceTable(roles, item))
      .map((item) => ({
      key: item,
      label: serviceCRUDMap[item].label,
      children: (
        <StationServiceTable
          stationSlug={slug}
          serviceType={item}
        />
      ),
    }));
  }, [roles, slug]);

  return (
    <UITabs defaultActiveKey={ServiceType.Simple} items={tabs} searchKey="type" />
  );
}
