import { FormCommonError, FormForm, useFormForm } from 'features/Form';
import { useCallback, useMemo } from 'react';
import { ContactGroups, IContactForm } from '../../Contact.interface';
import { ContactGroupList } from '../ContactGroupList/ContactGroupList';

export function ContactForm({ model, onFinish }: {
  model: ContactGroups[];
  onFinish: (values: ContactGroups[]) => any;
}) {
  const [form] = useFormForm<IContactForm>();

  const initialValues = useMemo(() => ({
    contacts: model,
  }), [model]);

  const handleFinish = useCallback(async (values: IContactForm) => {
    try {
      await onFinish(values.contacts);
    } catch (e) {
      throw new FormCommonError();
    }
  }, [onFinish]);

  return (
    <FormForm
      initialValues={initialValues}
      form={form}
      onFinish={handleFinish}
    >
      <ContactGroupList name="contacts" />
    </FormForm>
  );
}
