export interface IRole {
  id: string;
  name: string;
  description: string;
}

export enum RoleEntityType {
  News = 'news',
  Directorate = 'directorates',
  Station = 'stations',
  Service = 'services',
  User = 'users',
  Pages = 'pages',
}

export type IRoleMap = Record<RoleEntityType, IRole[]>

export type IUserRoleTable = [string, IRole[]];

// api

export type IRoleUpdateRequest = Omit<IRole, 'id'>;

export interface IRoleCreateResponse {
  role_id: string;
  user_id: string;
}
