import { Button, TabsProps } from 'antd';
import { useAuthRoles } from 'features/Auth';
import { formFilter } from 'features/Form';
import { LayoutContent } from 'features/Layout';
import { UITable, UITabs } from 'features/UI';
import { useMemo } from 'react';
import { useServiceIsAdmin, useServiceListLoad } from '../../Service.hook';
import { IServiceBase, ServiceType } from '../../Service.interface';
import { canViewServiceTable, serviceCRUDMap, serviceTableColumnList } from '../../Service.model';

const filter = (search: string, item: IServiceBase) => formFilter(search, item.info.title);

function ServiceTableByType({ serviceType }: {
  serviceType: ServiceType;
}) {
  const { data = [], isLoading } = useServiceListLoad(serviceType);
  const isAdmin = useServiceIsAdmin(serviceType);

  return (
    <UITable
      createButton={isAdmin && (
        <Button href={`/services/${serviceType}/create`} className="mb-6">
          Создать услугу
        </Button>
      )}
      filter={filter}
      loading={isLoading}
      columns={serviceTableColumnList(serviceType)}
      dataSource={data}
      pagination={false}
    />
  );
}

export function ServiceTable() {
  const roles = useAuthRoles();

  const tabs = useMemo((): TabsProps['items'] => Object
    .values(ServiceType)
    .filter((item) => canViewServiceTable(roles, item))
    .map((item) => ({
      key: item,
      label: serviceCRUDMap[item].label,
      children: <ServiceTableByType serviceType={item} />,
    })), [roles]);

  return (
    <LayoutContent title="Услуги">
      <UITabs defaultActiveKey={ServiceType.Simple} items={tabs} />
    </LayoutContent>
  );
}
