import { LANGUAGE_DEFAULT } from 'features/Language';
import { QueryMethodTypes } from 'features/Query';
import { IServiceBaseInfo, ServiceParentSourcesTypes, ServiceType } from 'features/Service';
import { serviceBaseApi } from '../Service/ServiceBase.api';
import { ISimple, ISimpleCreateRequest, ISimpleInfoUpdateRequest, ISimpleUpdateRequest } from './Simple.interface';

const SERVICE_SIMPLE_LIST_TAG = {
  type: ServiceType.Simple,
  id: 'simpleList',
};

const simpleApi = serviceBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    // simple service list
    simpleListLoad: builder.query<ISimple[], void>({
      query: () => ({
        url: '/simple_services',
        params: {
          parent_source: ServiceParentSourcesTypes.Station,
        },
      }),
      providesTags: (result = []) => [
        SERVICE_SIMPLE_LIST_TAG,
        ...result.map((item) => ({
          type: ServiceType.Simple,
          id: String(item.slug),
        })),
      ],
    }),

    // one simple service
    simpleLoad: builder.query<ISimple, string>({
      query: (slug) => `/simple_services/${slug}`,
      providesTags: (result, error) => error ? [] : [{
        type: ServiceType.Simple,
        id: String(result.slug),
      }],
    }),
    simpleCreate: builder.mutation<ISimple, ISimpleCreateRequest>({
      query: (body) => ({
        url: '/simple_services',
        method: QueryMethodTypes.POST,
        body,
      }),
      invalidatesTags: () => [SERVICE_SIMPLE_LIST_TAG],
    }),
    simpleUpdate: builder.mutation<ISimple, ISimpleUpdateRequest>({
      query: ({ slug, ...body }) => ({
        url: `/simple_services/${slug}`,
        method: QueryMethodTypes.PATCH,
        body,
      }),
      invalidatesTags: (res, err, req) => err
        ? []
        : [SERVICE_SIMPLE_LIST_TAG, {
          type: ServiceType.Simple,
          id: String(req.slug),
        }],
    }),
    simpleRemove: builder.mutation<void, string>({
      query: (slug) => ({
        url: `/simple_services/${slug}`,
        method: QueryMethodTypes.DELETE,
      }),
      invalidatesTags: (res, err) => err ? [] : [SERVICE_SIMPLE_LIST_TAG],
    }),
    simpleInfoUpdate: builder.mutation<IServiceBaseInfo, ISimpleInfoUpdateRequest>({
      query: ({ slug, ...body }) => ({
        url: `/simple_services/${slug}/info/${LANGUAGE_DEFAULT}`,
        method: QueryMethodTypes.PATCH,
        body,
      }),
      invalidatesTags: (res, err, req) => err
        ? []
        : [SERVICE_SIMPLE_LIST_TAG, {
          type: ServiceType.Simple,
          id: String(req.slug),
        }],
    }),
  }),
});

export const {
  // simple list
  useSimpleListLoadQuery,
  // simple one
  useSimpleLoadQuery,
  useSimpleUpdateMutation,
  useSimpleInfoUpdateMutation,
  useSimpleCreateMutation,
  useSimpleRemoveMutation,
} = simpleApi;
