import { Form, FormInstance, Input } from 'antd';
import { createFormList, FormFileUpload, FormListItemMeta, FormListItemProps, formRuleRequired, formRuleUrl } from 'features/Form';
import { useCallback, useState } from 'react';
import { IPageBlockCarouselItem, IPageBlockEditFormProps } from '../../Page.interface';
import { PAGE_BLOCK_CONTENT_FIELD } from '../../Page.model';

function CarouselGroupItem({ field, form, meta }: FormListItemProps<FormListItemMeta>) {
  const [initialBanner] = useState(form.getFieldValue([meta.groupName, field.name, 'banner']));

  const handleImageUpdate = useCallback(async (files: any) => {
    form.setFieldValue([meta.groupName, field.name, 'banner'], files);
  }, [field.name, form, meta]);

  return (
    <div>
      <Form.Item
        label="Картинка"
        name={[field.name, 'banner']}
        rules={[formRuleRequired('Добавьте картинку')]}
        required
      >
        <div className="mb-2">
          <FormFileUpload
            value={initialBanner}
            onChange={handleImageUpdate}
          />
        </div>
      </Form.Item>

      <Form.Item
        label="Заголовок"
        name={[field.name, 'title']}
        rules={[formRuleRequired('Добавьте заголовок')]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Ссылка"
        name={[field.name, 'link']}
        rules={[formRuleUrl('Некорректная ссылка'), formRuleRequired('Добавьте ссылку')]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Дополнительное описание"
        name={[field.name, 'description']}
      >
        <Input.TextArea />
      </Form.Item>
    </div>
  );
}

const createCarouselGroupList = (form: FormInstance, groupName: string) => createFormList<IPageBlockCarouselItem>(CarouselGroupItem, {
  addLabel: 'Добавить слайд',
  model: {
    banner: [],
    title: '',
    description: '',
    link: '',
  },
  form,
  meta: {
    groupName,
  },
});

export function PageBlockFormCarousel({ form }: IPageBlockEditFormProps) {
  return (
    <div>
      <Form.List name={PAGE_BLOCK_CONTENT_FIELD}>
        {createCarouselGroupList(form, PAGE_BLOCK_CONTENT_FIELD)}
      </Form.List>
    </div>
  );
}
