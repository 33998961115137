import imageExtensions from 'image-extensions';
import { useMemo } from 'react';
import { SetURLSearchParams, useSearchParams as useSearchParamsReact } from 'react-router-dom';
import { EmptyValues } from './object';

export const youtubeRegExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
export const urlRegExp = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/;
export const httpsRegExp = /^http(s)?:\/\//;

export function urlObjectToSearchParams(object: any): string {
  const fixed = {};

  // remove empty
  Object.entries(object).forEach(([k, v]) => {
    if (EmptyValues.includes(v)) {
      return;
    }
    fixed[k] = v;
  });
  return new URLSearchParams(fixed).toString();
}

export function urlSearchParamToObject<T>(query: string): T {
  const params: any = new URLSearchParams(query);
  return Object.fromEntries(params) as T;
}

// added types and serialization
export function useSearchParams<T = any>(): [T, SetURLSearchParams] {
  const [params, setParams] = useSearchParamsReact();
  const memo = useMemo(() => (Object.fromEntries(params) as T), [params]);
  return [memo, setParams];
}

export function createURL(url: string, search?: Object): string {
  return search
    ? `${url}?${urlObjectToSearchParams(search)}`
    : url;
}

export function prefixUrl(value: string = ''): string {
  if (!value) {
    return value;
  }
  return httpsRegExp.test(value)
    ? value
    : `https://${value}`;
}

export function isURL(string: string): boolean {
  try {
    const url = new URL(string);
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch (e) {
    return false;
  }
}

export function isURLYoutube(url: string): boolean {
  return isURL(url) && youtubeRegExp.test(url);
}

export function isURLImage(url: string): boolean {
  if (!isURL(url)) {
    return false;
  }
  const ext = new URL(url).pathname.split('.').pop();
  return imageExtensions.includes(ext);
}

export function getYoutubeId(url: string): string | null {
  const match = url.match(youtubeRegExp);
  return (match && match[7].length === 11)
    ? match[7]
    : null;
}
