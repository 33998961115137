import { Button, Table } from 'antd';
import { ServiceType } from 'features/Service';
import { useServiceListLoad } from '../../../Service/Service.hook';
import { stationServiceCreateUrl } from '../../../Station/Station.model';
import { useStationServiceListLoadQuery } from '../../StationService.api';
import { stationTableColumnList } from '../../StationService.model';

export function StationServiceTable({ stationSlug, serviceType }: {
  stationSlug: string;
  serviceType: ServiceType;
}) {
  const { data: stationServiceList = [], isLoading: isStationServiceListLoading } = useStationServiceListLoadQuery({
    station_slug: stationSlug,
    service_type: serviceType,
  });
  const { data: serviceList = [], isLoading: isServiceListLoading } = useServiceListLoad(serviceType);

  return (
    <div>
      <Button
        href={stationServiceCreateUrl(stationSlug, serviceType)}
        className="mb-6"
        disabled={isServiceListLoading || isStationServiceListLoading || stationServiceList.length === serviceList.length}
      >
        Добавить услугу
      </Button>
      <Table
        loading={isStationServiceListLoading || isServiceListLoading}
        columns={stationTableColumnList(stationSlug, serviceType)}
        dataSource={stationServiceList}
        rowKey="id"
        pagination={false}
        showHeader={false}
      />
    </div>
  );
}
