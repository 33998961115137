import { Button } from 'antd';
import { UITable } from 'features/UI';
import { useUserListLoadQuery } from '../../User.api';
import { userTableColumnList } from '../../Users.model';

export function UserUserTable() {
  const { data: userList = [], isLoading } = useUserListLoadQuery();

  return (
    <UITable
      createButton={(
        <Button href="/users/create">
          Создать пользователя
        </Button>
      )}
      loading={isLoading}
      columns={userTableColumnList}
      dataSource={userList}
      className="pb-6"
      pagination={false}
    />
  );
}
