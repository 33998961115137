import { Form, Input, Space } from 'antd';
import { FormCommonError, FormFileUpload, FormForm, formRuleRequired } from 'features/Form';
import { mediaSingleToUploadFile, mediaUploadFiles } from 'features/Media';
import { useCallback, useMemo } from 'react';
import { IDirectorateManager, IDirectorateManagerBaseUpdateBody, IDirectorateManagerForm, IDirectorateManagerInfoUpdateBody } from '../../Directorate.interface';
import { directorateManagerEmpty } from '../../Directorate.model';

export function DirectorateManagerForm({ model, onFinish, onRemove }: {
  model?: IDirectorateManager;
  onFinish: (base: IDirectorateManagerBaseUpdateBody, info: IDirectorateManagerInfoUpdateBody) => any;
  onRemove?: () => any;
}) {
  const [form] = Form.useForm<IDirectorateManagerForm>();

  const initialValues = useMemo((): IDirectorateManagerForm => {
    if (!model) {
      return directorateManagerEmpty;
    }

    return {
      firstName: model.info.first_name,
      lastName: model.info.last_name,
      patronymic: model.info.patronymic,
      position: model.info.position,
      image: mediaSingleToUploadFile(model.photo),
    };
  }, [model]);

  const handleUpdateFormValue = useCallback((name: keyof IDirectorateManagerForm) => (value: any) => {
    form.setFieldValue(name, value);
  }, [form]);

  const handleRemove = useCallback(() => {
    onRemove?.();
  }, [onRemove]);

  const handleFinish = useCallback(async (values: IDirectorateManagerForm) => {
    const photo = await mediaUploadFiles('directorate', values.image);

    try {
      await onFinish({
        photo: photo[0]?.link || '',
      }, {
        first_name: values.firstName,
        last_name: values.lastName,
        patronymic: values.patronymic,
        position: values.position,
      });
    } catch (e) {
      throw new FormCommonError();
    }
  }, [onFinish]);

  return (
    <FormForm
      initialValues={initialValues}
      form={form}
      onFinish={handleFinish}
      onRemove={model && handleRemove}
    >
      <Space>
        <Form.Item
          label="Фамилия"
          name="lastName"
          validateTrigger="onBlur"
          rules={[formRuleRequired('Введите фамилию')]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Имя"
          name="firstName"
          validateTrigger="onBlur"
          rules={[formRuleRequired('Введите имя')]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Отчество"
          name="patronymic"
        >
          <Input />
        </Form.Item>
      </Space>

      <Form.Item
        label="Должность"
        name="position"
        rules={[formRuleRequired('Введите должность')]}
        className="w-1/2"
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Фото"
        name="image"
        className="w-1/3"
      >
        <div className="mb-2">
          <FormFileUpload
            value={initialValues.image}
            onChange={handleUpdateFormValue('image')}
          />
        </div>
      </Form.Item>
    </FormForm>
  );
}
