import { App } from 'antd';
import { LayoutContent } from 'features/Layout';
import { useCallback, useMemo } from 'react';
import { useDirectorateInfoUpdateMutation, useDirectorateLoadQuery } from '../../../Directorate/Directorate.api';
import { DIRECTORATE_SLUG_HEAD } from '../../../Directorate/Directorate.model';
import { ContactGroups } from '../../Contact.interface';
import { ContactForm } from '../ContactForm/ContactForm';

export function ContactEdit() {
  const { message } = App.useApp();
  const { data, isLoading } = useDirectorateLoadQuery(DIRECTORATE_SLUG_HEAD);
  const [directorateUpdate] = useDirectorateInfoUpdateMutation();
  const model = useMemo(() => isLoading ? [] : data.info.contact_groups, [data, isLoading]);

  const handleFinish = useCallback(async (values: ContactGroups[]) => {
    await directorateUpdate({
      lang: data.info.lang,
      contact_groups: values,
      slug: data.slug,
    }).unwrap();
    message.success('Контакты обновлены');
  }, [directorateUpdate, message, data]);

  return (
    <LayoutContent
      title="Редактировать контакты"
      isLoading={isLoading}
      render={() => (
        <ContactForm
          model={model}
          onFinish={handleFinish}
        />
      )}
    />
  );
}
