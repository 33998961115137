import { Form, Input, Select, Space } from 'antd';
import { createFormList, FormListItemModeTypes, FormListItemProps } from 'features/Form';
import { ContactBlock, ContactBlockTypes } from '../../Contact.interface';
import { contactBlockOptions } from '../../Contact.model';

function ContactItem({ field }: FormListItemProps) {
  return (
    <Space>
      <Form.Item name={[field.name, 'details']} className="mb-0">
        <Input.TextArea placeholder="Описание" />
      </Form.Item>
      <Form.Item name={[field.name, 'type']} className="mb-0">
        <Select options={contactBlockOptions} />
      </Form.Item>
      <Form.Item name={[field.name, 'text']} className="mb-0">
        <Input.TextArea placeholder="Контакт" />
      </Form.Item>
    </Space>
  );
}

export const createContactList = createFormList<ContactBlock>(ContactItem, {
  addLabel: 'Добавить контакт',
  mode: FormListItemModeTypes.Inline,
  model: {
    type: ContactBlockTypes.Address,
    text: '',
  },
});
