import { useMemo } from 'react';
import { Node, Text } from 'slate';
import { ISlateDescendant } from '../../RichText.interface';
import { createElementParagraph, richTextNodeMap } from '../../RichText.model';
import { RichTextLeaf } from '../RichTextLeaf/RichTextLeaf';

function SlateNode(node: ISlateDescendant, index: number) {
  // leaf node
  if (Text.isText(node)) {
    return <RichTextLeaf key={index} children={node.text} leaf={node} />;
  }

  if (!Node.isNode(node)) {
    return null;
  }

  // recursive rendering of children
  const children = node.children.map((item, i) => SlateNode(item, i));
  const Element = richTextNodeMap[node.type] || richTextNodeMap.paragraph;
  return <Element key={index} children={children} element={node} />;
}

export function RichTextView({ value }: {
  value: string;
}) {
  // parse value
  const nodes: ISlateDescendant[] = useMemo(() => {
    try {
      return JSON.parse(value);
    } catch (e) {
      return [createElementParagraph()];
    }
  }, [value]);

  if (!nodes) {
    return null;
  }

  // render result
  return (
    <>
      {nodes.map(SlateNode)}
    </>
  );
}
