import { Collapse, CollapseProps, Form, Input } from 'antd';
import { CatalogCategorySelector } from 'features/Catalog';
import { FormCommonError, FormFileUpload, FormForm, formRuleRequired, formRuleUrl, useFormForm } from 'features/Form';
import { mediaArrayToUploadFile, mediaUploadFiles } from 'features/Media';
import { PlateEditor, richText2SerializeContent } from 'features/RictText2';
import { IServiceFormBase, ServiceType } from 'features/Service';
import { Spinner } from 'features/UI';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createSlug } from 'utils/string';
import { prefixUrl } from 'utils/url';
import { ServiceStationList } from '../../../Service/components/ServiceStationList/ServiceStationList';
import { useServiceIsAdmin } from '../../../Service/Service.hook';
import { useLazyStationServiceListLoadQuery, useStationServiceRemoveMutation } from '../../../StationService/StationService.api';
import { useSimpleRemoveMutation } from '../../Simple.api';
import { ISimple, simpleFormEmpty } from '../../Simple.interface';

export function SimpleForm({ model, onFinish }: {
  model?: ISimple;
  onFinish: (values: ISimple) => any;
}) {
  const navigate = useNavigate();
  const [form, handleFormUpdate] = useFormForm<IServiceFormBase>();
  const [simpleRemove] = useSimpleRemoveMutation();
  const [stationListLoad] = useLazyStationServiceListLoadQuery();
  const [stationServiceRemove] = useStationServiceRemoveMutation();
  const [stationServiceList, setStationServiceList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const isAdmin = useServiceIsAdmin(ServiceType.Simple);

  const handleStationListLoad = useCallback(async () => {
    try {
      if (!model) {
        return;
      }
      const result = await stationListLoad({
        service_id: model.id,
        service_type: ServiceType.Simple,
      }).unwrap();
      setStationServiceList(result);
    } finally {
      setIsLoading(false);
    }
  }, [model, stationListLoad]);

  const initialValues = useMemo((): IServiceFormBase => {
    if (!model) {
      return simpleFormEmpty;
    }
    return {
      icon: mediaArrayToUploadFile([model.icon]),
      title: model.info.title,
      content: model.info.content,
      gisId: model.gis_id,
      catalogId: model.service_catalog_id,
      externalLink: model.external_link,
    };
  }, [model]);

  const handleRemove = useCallback(async () => {
    // remove related services on stations
    const promises = stationServiceList.map((item) => stationServiceRemove(item.id).unwrap());
    await Promise.all(promises);
    // remove service
    await simpleRemove(model.slug).unwrap();
    navigate('/services');
  }, [model, navigate, simpleRemove, stationServiceList, stationServiceRemove]);

  const handleFinish = useCallback(async (values: IServiceFormBase) => {
    const slug = model?.slug || createSlug(values.title);
    const [image, content] = await Promise.all([
      mediaUploadFiles('simple', values.icon),
      richText2SerializeContent('simple', values.content),
    ]);

    try {
      await onFinish({
        ...model,
        slug,
        icon: image[0]?.link,
        gis_id: values.gisId,
        service_catalog_id: values.catalogId,
        external_link: prefixUrl(values.externalLink),
        info: {
          ...model?.info,
          title: values.title,
          content,
        },
      });
    } catch (e) {
      console.error('form error', e);
      throw new FormCommonError();
    }
  }, [model, onFinish]);

  const stationListContent = useMemo((): CollapseProps['items'] => !model ? [] : [
    {
      key: 1,
      label: `Связанные вокзалы`,
      children: (
        <div>
          <ServiceStationList stationList={stationServiceList} />
        </div>
      ),
    },
  ], [model, stationServiceList]);

  useEffect(() => {
    handleStationListLoad();
  }, [handleStationListLoad]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <FormForm
      initialValues={initialValues}
      form={form}
      onFinish={handleFinish}
      onRemove={isAdmin && model && handleRemove}
    >
      <Form.Item
        label="Название"
        name="title"
        validateTrigger="onBlur"
        rules={[formRuleRequired('Введите название')]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Gis ID"
        name="gisId"
        validateTrigger="onBlur"
        rules={[formRuleRequired('Введите Gis ID')]}
        className="w-1/3"
      >
        <Input />
      </Form.Item>

      <CatalogCategorySelector
        label="Catalog ID"
        name="catalogId"
      />

      <Form.Item
        label="Внешняя ссылка"
        name="externalLink"
        className="w-2/3"
        rules={[formRuleUrl('Введите корректную ссылку')]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Иконка (svg)"
        name="icon"
      >
        <div className="mb-2">
          <FormFileUpload
            value={initialValues.icon}
            onChange={handleFormUpdate('icon')}
            accept="image/svg+xml"
          />
        </div>
      </Form.Item>

      <Form.Item
        label="Общее описание"
        name="content"
      >
        <PlateEditor />
      </Form.Item>

      {model && <Collapse items={stationListContent} />}
    </FormForm>
  );
}
