import { Form, Input } from 'antd';
import { formRuleRequired } from 'features/Form';

export function PagePageFormInternalDev() {
  return (
    <div>
      <Form.Item
        label="Заголовок"
        name="title"
        validateTrigger="onBlur"
        rules={[formRuleRequired('Введите заголовок')]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Cсылка"
        name="link"
        rules={[formRuleRequired('Введите ссылку')]}
      >
        <Input />
      </Form.Item>
    </div>
  );
}
