import { Button } from 'antd';
import { formFilter } from 'features/Form';
import { UITable } from 'features/UI';
import { useMemo } from 'react';
import { usePageListLoadQuery } from '../../Page.api';
import { IPage, PageViewTypes } from '../../Page.interface';
import { pageConfig } from '../../Page.model';

const filter = (search: string, item: IPage) => formFilter(search, item.info.title);

export function PagePageTable({ type, isCreateButton = false }: {
  type: PageViewTypes;
  isCreateButton?: boolean;
}) {
  const { data = [], isLoading } = usePageListLoadQuery(type);
  const config = useMemo(() => pageConfig[type], [type]);

  return (
    <UITable
      createButton={isCreateButton && (
        <Button href={config.createUrl}>
          {config.createLabel}
        </Button>
      )}
      filter={filter}
      loading={isLoading}
      columns={config.tableColumnList}
      dataSource={data}
      rowKey="slug"
      className="pb-6"
      pagination={false}
    />
  );
}
