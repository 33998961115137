import { DeleteOutlined } from '@ant-design/icons';
import { App, Button, Flex, Form as AntForm, FormInstance } from 'antd';
import { useLayoutConfirmRemove } from 'features/Layout';
import { PropsWithChildren, ReactNode, useCallback, useState } from 'react';

export function FormForm<T>({ children, form, initialValues, actions, saveLabel, onFinish, onRemove }: PropsWithChildren<{
  onFinish: (values: T) => any;
  initialValues: T;
  actions?: ReactNode[];
  saveLabel?: string;
  onRemove?: Function | false;
  form: FormInstance<T>;
}>) {
  const { message } = App.useApp();
  const [isLoading, setIsLoading] = useState(false);

  const handleFinish = useCallback(async (values: T) => {
    setIsLoading(true);
    try {
      await onFinish(values);
    } catch (e) {
      message.error(e.message);
      return;
    } finally {
      setIsLoading(false);
    }
  }, [message, onFinish]);

  const handleRemove = useCallback(async () => {
    if (!onRemove) {
      return;
    }
    setIsLoading(true);
    try {
      await onRemove?.();
    } catch (e) {
      message.error('Ошибка при удалении');
      console.error('error remove object', e);
      setIsLoading(false);
    }
  }, [message, onRemove]);

  const handleRemoveClick = useLayoutConfirmRemove(handleRemove);

  return (
    <AntForm
      className="w-3/4"
      layout="vertical"
      initialValues={initialValues}
      form={form}
      onFinish={handleFinish}
      disabled={isLoading}
    >
      {/* form content */}
      {children}

      {/* actions */}
      <Flex gap="large" className="mt-6">
        {actions
          ? actions
          : (
            <Button type="primary" htmlType="submit">
              {saveLabel || 'Сохранить'}
            </Button>
          )}
        {onRemove && (
          <Button
            icon={<DeleteOutlined />}
            disabled={isLoading}
            onClick={handleRemoveClick}
            danger
          >
            Удалить
          </Button>
        )}
      </Flex>
    </AntForm>
  );
}
