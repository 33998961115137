import { configureStore } from '@reduxjs/toolkit';
import { reducer } from './App.reducer';
import { catalogApi } from './features/Catalog/Catalog.api';
import { directorateApi } from './features/Directorate/Directorate.api';
import { newsApi } from './features/News/News.api';
import { pageApi } from './features/Page/Page.api';
import { serviceBaseApi } from './features/Service/ServiceBase.api';
import { stationApi } from './features/Station/Station.api';
import { userApi } from './features/User/User.api';

export const appStore = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([
    userApi.middleware,
    newsApi.middleware,
    stationApi.middleware,
    serviceBaseApi.middleware,
    directorateApi.middleware,
    catalogApi.middleware,
    pageApi.middleware,
  ]),
});

export type AppState = ReturnType<typeof appStore.getState>;
