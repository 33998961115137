import { IServiceBase, IServiceBaseInfo, IServiceFormBase, IServiceMutationBodyBase, serviceFormBaseEmpty, ServiceParentSourcesTypes } from 'features/Service';

export interface ISimple extends IServiceBase {
  parent_source: ServiceParentSourcesTypes;
}

export const simpleFormEmpty: IServiceFormBase = {
  ...serviceFormBaseEmpty,
  externalLink: '',
  icon: [],
};

export interface ISimpleMutationBody extends IServiceMutationBodyBase {
  parent_source: ServiceParentSourcesTypes;
  external_link: string;
  icon: string;
}

export interface ISimpleCreateRequest extends ISimpleMutationBody {
  slug: string;
}

export interface ISimpleUpdateRequest extends Partial<ISimpleMutationBody> {
  slug: string;
}

export interface ISimpleInfoUpdateRequest extends IServiceBaseInfo {
  slug: string;
}
