import { App } from 'antd';
import { LayoutContent } from 'features/Layout';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useNewsTagLoadQuery, useTagUpdateMutation } from '../../News.api';
import { INewsTag, newsTagTabUrl } from '../../News.interfaces';
import { NewsTagForm } from '../NewsTagForm/NewsTagForm';

export function NewsTagEdit() {
  const { id } = useParams();
  const { message } = App.useApp();
  const navigate = useNavigate();
  const { data, isLoading } = useNewsTagLoadQuery(Number(id));
  const [tagUpdate] = useTagUpdateMutation();

  const handleFinish = useCallback(async (values: INewsTag) => {
    await tagUpdate({
      title: values.title,
      tag_id: values.id,
    });
    message.success('Тэг обновлен');
    navigate(newsTagTabUrl);
  }, [message, navigate, tagUpdate]);

  return (
    <LayoutContent
      title="Редактирование тэга"
      backUrl={newsTagTabUrl}
      isLoading={isLoading}
      render={() => (
        <NewsTagForm
          model={data}
          onFinish={handleFinish}
        />
      )}
    />
  );
}
