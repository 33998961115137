import { DeleteOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import classNames from 'classnames';
import { PropsWithChildren, useCallback } from 'react';
import { ReactEditor, useSelected, useSlate, RenderElementProps } from 'slate-react';
import { ISlateElement } from '../../RichText.interface';

export function RichTextVoid({ attributes, element, children }: PropsWithChildren<{
  element: ISlateElement;
  attributes: RenderElementProps['attributes'];
}>) {
  const editor = useSlate();
  const selected = useSelected();

  const handleRemove = useCallback(() => {
    const path = ReactEditor.findPath(editor, element);
    editor.removeNodes({ at: path });
    // important: back focus after action
    ReactEditor.focus(editor);
  }, [editor, element]);

  return (
    <div className="my-2" contentEditable={false} {...attributes}>
      <div className={classNames('relative outline-blue-500 outline-4 inline-block', {
        outline: selected,
      })}>
        {children}
        <Button
          className="absolute left-2 top-2"
          onClick={handleRemove}
          icon={<DeleteOutlined />}
        />
      </div>
    </div>
  );
}
