import { useKeycloak } from '@react-keycloak/web';
import { Navigate } from 'react-router-dom';
import { AuthLoginButton } from '../AuthLoginButton/AuthLoginButton';

export function AuthLoginPage() {
  const { keycloak } = useKeycloak();

  if (keycloak.authenticated) {
    return <Navigate to="/" />;
  }

  return (
    <div className="h-full flex justify-center items-center">
      <AuthLoginButton />
    </div>
  );
}
