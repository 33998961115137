import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { formFilterOptionByLabel, FormOption } from 'features/Form';
import { useMemo } from 'react';
import { IStation } from '../../../Station/Station.interface';
import { useStationServiceListLoadQuery } from '../../../StationService/StationService.api';
import { useServiceListLoad } from '../../Service.hook';
import { ServiceType } from '../../Service.interface';

export function ServiceSelector({ serviceType, station, ...props }: {
  serviceType: ServiceType;
  station?: IStation;
} & SelectProps) {
  const { data: stationServiceAllList = [], isLoading: isStationServiceAllListLoading } = useServiceListLoad(serviceType);
  const { data: stationServiceList = [], isLoading: isStationServiceListLoading } = useStationServiceListLoadQuery({
    station_slug: station?.slug,
    service_type: serviceType,
  });

  const options = useMemo(() => {
    if (isStationServiceAllListLoading || isStationServiceListLoading) {
      return [];
    }
    const idList = stationServiceList.map((item) => item.service_id);

    return stationServiceAllList
      .filter((item) => !idList.includes(item.id))
      .map((item): FormOption => ({
        label: item.info.name || item.info.title || 'Без названия',
        value: item.id,
      }));
  }, [isStationServiceAllListLoading, isStationServiceListLoading, stationServiceAllList, stationServiceList]);

  return (
    <Select
      options={options}
      filterOption={formFilterOptionByLabel}
      showSearch
      loading={isStationServiceAllListLoading || isStationServiceListLoading}
      {...props}
    />
  );
}
