import { ColumnsType } from 'antd/lib/table';
import { KeycloakRoles } from 'keycloak-js';
import { Link } from 'react-router-dom';
import { createURL } from 'utils/url';
import { IUser, UserFormTabTypes, UserRoleTypes, UserTabTypes } from './User.interface';

export const userFormBackUrl = (tab?: UserTabTypes) => createURL('/users', {
  tab,
});

export const userFormUrl = (id: IUser['id'], tab?: UserFormTabTypes) => createURL(`/users/${id}`, {
  tab,
});

export const userTableColumnList: ColumnsType<IUser> = [
  {
    title: 'Username',
    dataIndex: 'username',
    render: (username, item) => (
      <Link to={`/users/${item.id}`}>{username}</Link>
    ),
  },
  {
    title: 'E-mail',
    dataIndex: 'email',
  },
];

export function canViewUsers(roles: KeycloakRoles['roles']) {
  return roles.includes(UserRoleTypes.Admin);
}
