import { App } from 'antd';
import { LANGUAGE_DEFAULT } from 'features/Language';
import { LayoutContent } from 'features/Layout';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useNewsCreateMutation } from '../../News.api';
import { NewsMutationBase } from '../../News.interfaces';
import { NewsForm } from '../NewsForm/NewsForm';

export function NewsCreate() {
  const navigate = useNavigate();
  const { message } = App.useApp();
  const [newsCreate] = useNewsCreateMutation();

  const handleFinish = useCallback(async (values: NewsMutationBase) => {
    await newsCreate({
      ...values,
      lang: LANGUAGE_DEFAULT,
    }).unwrap();
    message.success('Новость создана');
    navigate('/news');
  }, [message, navigate, newsCreate]);

  return (
    <LayoutContent
      title="Создать новость"
      backUrl="/news"
    >
      <NewsForm onFinish={handleFinish} />
    </LayoutContent>
  );
}
