import { Flex, Form, Input, Space } from 'antd';
import { type FormListProps } from 'antd/es/form/FormList';
import { createFormList, FormListItemProps } from 'features/Form';
import { ContactBlockTypes, ContactGroups } from '../../Contact.interface';
import { createContactList } from '../ContactItem/ContactItem';

function ContactGroupItem({ field }: FormListItemProps) {
  return (
    <Flex gap={16}>
      <Form.Item name={[field.name, 'title']} className="mb-0">
        <Input.TextArea placeholder="Заголовок" />
      </Form.Item>

      <Space>
        <Form.List name={[field.name, 'contacts']}>
          {createContactList}
        </Form.List>
      </Space>
    </Flex>
  );
}

const createContactGroupList = createFormList<ContactGroups>(ContactGroupItem, {
  addLabel: 'Добавить группу',
  model: {
    title: '',
    sub_title: '',
    contacts: [
      {
        type: ContactBlockTypes.Address,
        text: '',
      },
    ],
  },
});

export function ContactGroupList({ ...props }: Omit<FormListProps, 'children'>) {
  return (
    <Form.List {...props}>
      {createContactGroupList}
    </Form.List>
  );
}
