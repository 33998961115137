import { MARK_BOLD, MARK_ITALIC, MARK_STRIKETHROUGH, MARK_UNDERLINE } from '@udecode/plate-basic-marks';
import { ELEMENT_IMAGE, ELEMENT_MEDIA_EMBED } from '@udecode/plate-media';
import React from 'react';
import { HeadingH1Button, HeadingH2Button, HeadingH3Button } from './heading-button';
import { Icons } from './icons';
import { LinkToolbarButton } from './link-toolbar-button';
import { OLButton, ULButton } from './list-buttons';
import { MarkToolbarButton } from './mark-toolbar-button';
import { MediaToolbarButton } from './media-toolbar-button';
import { TableDropdownMenu } from './table-dropdown-menu';
import { ToolbarGroup } from './toolbar';
import { TurnIntoDropdownMenu } from './turn-into-dropdown-menu';

export function FixedToolbarButtons() {
  return (
    <div className="w-full overflow-hidden">
      <div
        className="flex flex-wrap"
        style={{
          transform: 'translateX(calc(-1px))',
        }}
      >
        <ToolbarGroup>
          <MarkToolbarButton tooltip="Жирный (⌘+B)" nodeType={MARK_BOLD}>
            <Icons.Bold />
          </MarkToolbarButton>
          <MarkToolbarButton tooltip="Курсив (⌘+I)" nodeType={MARK_ITALIC}>
            <Icons.Italic />
          </MarkToolbarButton>
          <MarkToolbarButton tooltip="Подчеркнутый (⌘+U)" nodeType={MARK_UNDERLINE}>
            <Icons.Underline />
          </MarkToolbarButton>
          <MarkToolbarButton tooltip="Перечеркнутый (⌘+⇧+M)" nodeType={MARK_STRIKETHROUGH}>
            <Icons.Strikethrough />
          </MarkToolbarButton>
        </ToolbarGroup>

        <ToolbarGroup>
          <HeadingH1Button />
          <HeadingH2Button />
          <HeadingH3Button />
        </ToolbarGroup>

        <ToolbarGroup>
          <ULButton />
          <OLButton />
        </ToolbarGroup>

        <ToolbarGroup>
          <LinkToolbarButton />
          <MediaToolbarButton nodeType={ELEMENT_IMAGE} />
          <MediaToolbarButton nodeType={ELEMENT_MEDIA_EMBED} />
        </ToolbarGroup>

        <ToolbarGroup>
          <TableDropdownMenu />
        </ToolbarGroup>

        <div className="grow" />


        <ToolbarGroup noSeparator>
          <TurnIntoDropdownMenu />
        </ToolbarGroup>
      </div>
    </div>
  );
}
