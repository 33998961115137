import { FormRule } from 'antd';
import { stringAZRegExp } from 'utils/string';
import { urlRegExp } from 'utils/url';
import { FormOption } from './Form.interface';

export function formRuleRequired(message: string): FormRule {
  return {
    required: true,
    message,
  };
}

export function formRuleUrl(message: string): FormRule {
  return {
    pattern: urlRegExp,
    message,
  };
}

export function formRuleAZ(message: string): FormRule {
  return {
    pattern: stringAZRegExp,
    message,
  }
}

export function formRuleEmail(message: string): FormRule {
  return {
    required: true,
    type: 'email',
    message,
  };
}

export function formFilter(input: string = '', field: string = ''): boolean {
  return field.toLowerCase().includes(input.toLowerCase());
}

export function formFilterOptionByLabel(input: string, option?: FormOption): boolean {
  return formFilter(input, option?.label || '');
}

export class FormCommonError extends Error {
  constructor() {
    super('Ошибка при отправке формы');
  }
}
