import { TabsProps } from 'antd';
import { featureFlags } from 'App.environment';
import { LayoutContent } from 'features/Layout';
import { RoleTable } from 'features/Role';
import { UITabs } from 'features/UI';
import { useMemo } from 'react';
import { UserTabTypes } from '../../User.interface';
import { UserUserTable } from '../UserUserTable/UserUserTable';

function UserAdminTable() {
  return (
    <LayoutContent title="Пользователи">
      <UserUserTable />
    </LayoutContent>
  );
}

function UserDevTable() {
  const tabs = useMemo((): TabsProps['items'] => [
    {
      key: UserTabTypes.UserTable,
      label: 'Пользователи',
      children: <UserUserTable />,
    },
    {
      key: UserTabTypes.RoleTable,
      label: 'Роли',
      children: <RoleTable />,
    },
  ], []);

  return (
    <LayoutContent title="Пользователи">
      <UITabs
        defaultActiveKey={UserTabTypes.UserTable}
        items={tabs}
      />
    </LayoutContent>
  );
}

export function UserTable() {
  return featureFlags.isDevRoleList
    ? <UserDevTable />
    : <UserAdminTable />;
}
