import { createApi } from '@reduxjs/toolkit/query/react';
import { APP_API_ADMIN_URL, AppApiDevUrl, featureFlags } from 'App.environment';
import { authFetchBaseQuery } from 'features/Auth';
import { LANGUAGE_DEFAULT } from 'features/Language';
import { QueryMethodTypes } from 'features/Query';
import { INews, INewsTag, NewsCreateRequest, NewsListLoadRequest, NewsTagCreateRequest, NewsTagUpdateRequest, NewsUpdateRequest } from './News.interfaces';

enum NewsApiTagTypes {
  News = 'news',
  Tag = 'tags',
}

const NEWS_LIST_TAG = {
  type: NewsApiTagTypes.News,
  id: 'newsList',
};

const NEWS_TAG_TAG = {
  type: NewsApiTagTypes.Tag,
  id: 'tagList',
};

const baseUrl = featureFlags.isNewsDev
  ? AppApiDevUrl.News
  : APP_API_ADMIN_URL;

export const newsApi = createApi({
  reducerPath: 'newsApi',
  tagTypes: [
    NewsApiTagTypes.News,
    NewsApiTagTypes.Tag,
  ],
  baseQuery: authFetchBaseQuery(`${baseUrl}/news/api/v1`),
  endpoints: (builder) => ({
    // news list
    newsListLoad: builder.query<INews[], NewsListLoadRequest>({
      query: (params) => ({
        url: '/news',
        params,
      }),
      providesTags: (result = []) => [
        NEWS_LIST_TAG,
        ...result.map((item) => ({
          type: NewsApiTagTypes.News,
          id: String(item.id),
        })),
      ],
    }),
    // one news
    newsLoad: builder.query<INews, string>({
      query: (id) => `/news/${id}`,
      providesTags: (result) => [{
        type: NewsApiTagTypes.News,
        id: String(result.id),
      }],
    }),
    newsCreate: builder.mutation<INews, NewsCreateRequest>({
      query: (body) => ({
        url: '/news',
        method: QueryMethodTypes.POST,
        body,
      }),
      invalidatesTags: () => [NEWS_LIST_TAG],
    }),
    newsUpdate: builder.mutation<INews, NewsUpdateRequest>({
      query: ({ id, ...body }) => ({
        url: `/news/${id}`,
        method: QueryMethodTypes.PATCH,
        body,
      }),
      invalidatesTags: (result, err) => err
        ? []
        : [NEWS_LIST_TAG, {
          type: NewsApiTagTypes.News,
          id: String(result.id),
        }],
    }),
    newsRemove: builder.mutation<void, number>({
      query: (id) => ({
        url: `/news/${id}`,
        method: QueryMethodTypes.DELETE,
      }),
      invalidatesTags: () => [NEWS_LIST_TAG],
    }),

    // --- tags ---

    // tag list
    newsTagListLoad: builder.query<INewsTag[], void>({
      query: () => '/tags',
      providesTags: (result = []) => [
        NEWS_TAG_TAG,
        ...result.map((item) => ({
          type: NewsApiTagTypes.Tag,
          id: String(item.id),
        })),
      ],
    }),

    // tag one
    tagCreate: builder.mutation<INewsTag, NewsTagCreateRequest>({
      query: (body) => ({
        url: '/tags',
        method: QueryMethodTypes.POST,
        body,
      }),
      invalidatesTags: () => [NEWS_TAG_TAG],
    }),
    newsTagLoad: builder.query<INewsTag, number>({
      query: (id) => ({
        url: `/tags/${id}`,
        params: {
          lang: LANGUAGE_DEFAULT,
        },
      }),
      providesTags: (result) => [{
        type: NewsApiTagTypes.Tag,
        id: String(result.id),
      }],
    }),
    tagUpdate: builder.mutation<INewsTag, NewsTagUpdateRequest>({
      query: ({ tag_id, ...body }) => ({
        url: `/tags/${tag_id}`,
        method: QueryMethodTypes.PATCH,
        body,
      }),
      invalidatesTags: (result, err) => err ? [] : [NEWS_TAG_TAG, {
        type: NewsApiTagTypes.Tag,
        id: String(result.id),
      }],
    }),
    tagRemove: builder.mutation<void, number>({
      query: (id) => ({
        url: `/tags/${id}`,
        method: QueryMethodTypes.DELETE,
      }),
      invalidatesTags: () => [NEWS_TAG_TAG],
    }),
  }),
});

export const {
  // news list
  useNewsListLoadQuery,
  useLazyNewsListLoadQuery,
  // news one
  useNewsLoadQuery,
  useNewsRemoveMutation,
  useNewsUpdateMutation,
  useNewsCreateMutation,

  // tag list
  useNewsTagListLoadQuery,
  // tag one
  useNewsTagLoadQuery,
  useTagUpdateMutation,
  useTagRemoveMutation,
  useTagCreateMutation,
} = newsApi;
