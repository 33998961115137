import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { authClient } from './Auth.client';

export const authFetchBaseQuery = (baseUrl: string) => fetchBaseQuery({
  baseUrl,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  prepareHeaders: (headers: Headers) => {
    if (authClient.token) {
      headers.set('Authorization', `Bearer ${authClient.token}`);
    }
    return headers;
  },
});
