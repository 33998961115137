import { AuthLoginPage } from 'features/Auth';
import { Navigate, Route, RouteProps, Routes } from 'react-router-dom';
import { AuthGuard } from '../Auth/Auth.guards';
import { Layout } from 'features/Layout';
import { RoleGuard } from '../Role/Role.guard';

export function Router({ routes }: {
  routes: RouteProps[];
}) {
  return (
    <Routes>
      <Route path="/" element={(
        <AuthGuard>
          <RoleGuard>
            <Layout />
          </RoleGuard>
        </AuthGuard>
      )}>
        {routes.map((item) => (
          <Route key={item.path} {...item} />
        ))}
      </Route>

      <Route path="/login" element={<AuthLoginPage />} />

      {/* not found */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}
