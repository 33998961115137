import { createApi } from '@reduxjs/toolkit/query/react';
import { APP_API_ADMIN_URL } from 'App.environment';
import { authFetchBaseQuery } from 'features/Auth';
import { QueryMethodTypes } from 'features/Query';
import { IPage, IPageCreateRequest, IPageInfo, IPageInfoUpdateRequest, IPageMutationRequest, IPageUpdateRequest, PageViewTypes } from './Page.interface';

const PAGE_PAGE_LIST_TAG = {
  type: PageViewTypes.Page,
  id: 'pagePageList',
};

const PAGE_BLOCK_LIST_TAG = {
  type: PageViewTypes.Page,
  id: 'pageBlockList',
};

function getPageListTag(type: PageViewTypes) {
  switch (type) {
    case PageViewTypes.Page:
      return PAGE_PAGE_LIST_TAG;
    case PageViewTypes.Block:
      return PAGE_BLOCK_LIST_TAG;
  }
}

export const pageApi = createApi({
  reducerPath: 'pageApi',
  tagTypes: [
    PageViewTypes.Page,
    PageViewTypes.Block,
  ],
  baseQuery: authFetchBaseQuery(`${APP_API_ADMIN_URL}/pages/api/v1`),
  endpoints: (builder) => ({
    // page list
    pageListLoad: builder.query<IPage[], PageViewTypes>({
      query: (type) => ({
        url: '/pages',
        params: {
          view_type: type,
        },
      }),
      providesTags: (res = [], err, req) => [
        getPageListTag(req),
        ...res.map((item) => ({
          type: req,
          id: String(item.slug),
        })),
      ],
    }),
    // page crud
    pageLoad: builder.query<IPage, string>({
      query: (slug) => `/pages/${slug}`,
      providesTags: (res, err, req) => err ? [] : [{
        type: res.view_type,
        id: String(res.slug),
      }],
    }),
    pageCreate: builder.mutation<IPage, IPageCreateRequest>({
      query: (body) => ({
        url: '/pages',
        method: QueryMethodTypes.POST,
        body,
      }),
      invalidatesTags: (res, err, req) => err ? [] : [
        getPageListTag(req.view_type),
      ],
    }),
    pageRemove: builder.mutation<void, IPageMutationRequest>({
      query: ({ slug }) => ({
        url: `/pages/${slug}`,
        method: QueryMethodTypes.DELETE,
      }),
      invalidatesTags: (res, err, req) => err ? [] : [
        getPageListTag(req.view_type),
      ],
    }),
    pageUpdate: builder.mutation<IPage, IPageUpdateRequest>({
      query: ({ slug, ...body }) => ({
        url: `/pages/${slug}`,
        method: QueryMethodTypes.PATCH,
        body,
      }),
    }),
    pageInfoUpdate: builder.mutation<IPageInfo, IPageInfoUpdateRequest>({
      query: ({ slug, lang, view_type, ...body }) => ({
        url: `/pages/${slug}/info/${lang}`,
        method: QueryMethodTypes.PATCH,
        body,
      }),
      invalidatesTags: (res, err, req) => err ? [] : [
        getPageListTag(req.view_type),
        {
          type: req.view_type,
          id: req.slug,
        },
      ],
    }),
  }),
});


export const {
  // list
  usePageListLoadQuery,
  // one
  usePageLoadQuery,
  usePageCreateMutation,
  usePageRemoveMutation,
  usePageUpdateMutation,
  usePageInfoUpdateMutation,
} = pageApi;
