import { RouteProps } from 'react-router-dom';
import { StationServiceSimpleCreate } from './components/StationServiceSimpleCreate/StationServiceSimpleCreate';
import { StationServiceSimpleEdit } from './components/StationServiceSimpleEdit/StationServiceSimpleEdit';

export const stationServiceRouter: RouteProps[] = [
  {
    path: '/services/:serviceType/:serviceSlug/station/:stationSlug/simple/create',
    Component: StationServiceSimpleCreate,
  },
  {
    path: '/services/:serviceType/:serviceSlug/station/:stationSlug/simple/:simpleId',
    Component: StationServiceSimpleEdit,
  },
];
