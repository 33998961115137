import { useKeycloak } from '@react-keycloak/web';
import { App, Checkbox, Form, Typography } from 'antd';
import { DirectorateRoleSelector } from 'features/Directorate';
import { FormForm, useFormForm } from 'features/Form';
import { IRoleMap, roleEntityLabelMap, RoleEntityType } from 'features/Role';
import { UseParamsWithId } from 'features/Router';
import { StationRoleSelector } from 'features/Station';
import { Spinner } from 'features/UI';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { arraySortBy } from 'utils/array';
import { objectFlatValuesList } from 'utils/object';
import { DIRECTORATE_HEAD_ROLE_ADMIN } from '../../../Directorate/Directorate.model';
import { useRoleMapLoadQuery } from '../../../Role/Role.api';
import { ROLE_EDITOR_SUFFIX } from '../../../Role/Role.model';
import { STATION_ADMIN_ROLE } from '../../../Station/Station.model';
import { useUserRoleCreateMutation, useUserRoleListLoadQuery, useUserRoleRemoveMutation } from '../../User.api';
import { IUserRoleForm } from '../../User.interface';
import { userFormBackUrl } from '../../Users.model';

export function UserRoleForm() {
  const { id } = useParams<UseParamsWithId>();
  const navigate = useNavigate();
  const { message } = App.useApp();
  const { keycloak } = useKeycloak();
  const [form] = useFormForm<IUserRoleForm>();
  const { data: userRoleMap, isLoading } = useUserRoleListLoadQuery(id);
  const { data: roleMap = {} as IRoleMap, isLoading: isRoleMapLoading } = useRoleMapLoadQuery();
  const [userRoleCreate] = useUserRoleCreateMutation();
  const [userRoleRemove] = useUserRoleRemoveMutation();
  const { directorates, stations, ...roleMapRest } = roleMap;
  const [directorateList, setDirectorateList] = useState<string[]>([]);

  const roleRestGroupList = useMemo(() => {
    return Object
      .entries(roleMapRest)
      .map(([k, v]) => ({
        title: roleEntityLabelMap[k] || k,
        // field name
        name: k,
        // checkbox options
        options: v
          .filter((item) => !item.name.endsWith(`_${ROLE_EDITOR_SUFFIX}`))
          .map((item) => ({
            label: item.description || item.name,
            value: item.name,
          }))
          .sort(arraySortBy('label')),
      }))
  }, [roleMapRest]);

  const initialValues = useMemo(() => {
    const result = {};
    if (!userRoleMap) {
      return result;
    }
    Object.entries(userRoleMap).forEach(([k, v]) => {
      result[k] = v.map((item) => item.name);
    });
    return result;
  }, [userRoleMap]);

  const handleFinish = useCallback(async (values: IUserRoleForm) => {
    let prevValues = objectFlatValuesList(userRoleMap);
    const roleFlat = objectFlatValuesList(roleMap);
    const promisesCreate = [];

    const nextValues = Object
      .values(values)
      .filter((i) => i.length > 0)
      .reduce((memo, item) => [...memo, ...item], [])
      .map((name) => roleFlat.find((item) => item.name === name)!);

    // add new ones
    nextValues.forEach((next) => {
      const found = prevValues.find((item) => item.name === next.name);
      if (!found) {
        // create
        promisesCreate.push(userRoleCreate({
          user_id: id,
          id: next.id,
          name: next.name,
        }).unwrap());
      } else {
        // remove from delete list
        prevValues = prevValues.filter((item) => item.name !== next.name);
      }
    });

    // remove not selected
    const promisesRemove = prevValues.map((item) => userRoleRemove({
      user_id: id,
      id: item.id,
      name: item.name,
    }).unwrap());

    await Promise.all([
      ...promisesCreate,
      ...promisesRemove,
    ]);

    message.success('Роли обновлены');
    navigate(userFormBackUrl());
    // update for self
    if (keycloak.tokenParsed.sub === id) {
      window.location.reload();
    }
  }, [id, keycloak, message, navigate, roleMap, userRoleCreate, userRoleMap, userRoleRemove]);

  const handleDirectorateList = useCallback((value: string[], list: string[], isReset: boolean) => {
    setDirectorateList(list);

    if (!isReset) {
      return;
    }

    form.setFieldValue(RoleEntityType.Station, value.includes(DIRECTORATE_HEAD_ROLE_ADMIN)
      // super admin
      ? [STATION_ADMIN_ROLE]
      // reset
      : []);
  }, [form]);

  if (isLoading || isRoleMapLoading) {
    return <Spinner />;
  }

  return (
    <FormForm
      initialValues={initialValues}
      form={form}
      onFinish={handleFinish}
    >
      <Typography.Title level={3}>
        Подразделения
      </Typography.Title>
      <Form.Item name={RoleEntityType.Directorate}>
        <DirectorateRoleSelector roles={directorates} onChange={handleDirectorateList} />
      </Form.Item>

      <Typography.Title level={3}>
        Вокзалы
      </Typography.Title>
      <Form.Item name={RoleEntityType.Station}>
        <StationRoleSelector roles={stations} directorateList={directorateList} />
      </Form.Item>

      {roleRestGroupList.map((item) => (
        <div key={item.name}>
          <Typography.Title level={3}>
            {item.title}
          </Typography.Title>
          {item.options.length > 0
            ? (
              <Form.Item name={item.name}>
                <Checkbox.Group
                  className="w-2/3 flex-col gap-4"
                  options={item.options}
                />
              </Form.Item>
            )
            : (
              <div className="mb-4">
                <Typography.Text italic>Нет ролей</Typography.Text>
              </div>
            )}
        </div>
      ))}
    </FormForm>
  );
}
