import { Button } from 'antd';
import { formFilter } from 'features/Form';
import { UITable } from 'features/UI';
import { useNewsListLoadQuery } from '../../News.api';
import { INews } from '../../News.interfaces';
import { newsTableColumnList } from '../../News.model';

const filter = (search: string, item: INews) => formFilter(search, item.title);

export function NewsNewsTable() {
  const { data = [], isLoading } = useNewsListLoadQuery({});

  return (
    <UITable
      createButton={(
        <Button href="/news/news/create">
          Создать новость
        </Button>
      )}
      filter={filter}
      loading={isLoading}
      columns={newsTableColumnList}
      dataSource={data}
      className="pb-6"
    />
  );
}
