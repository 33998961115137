import { Form, Input, Select } from 'antd';
import { featureFlags } from 'App.environment';
import { FormCommonError, FormForm, formRuleAZ, formRuleRequired, useFormForm } from 'features/Form';
import { mediaArrayToUploadFile, mediaUploadFiles } from 'features/Media';
import { richText2SerializeContent } from 'features/RictText2';
import { useCallback, useMemo } from 'react';
import { createSlug } from 'utils/string';
import { IPagePageForm, PageFormProps, PagePageTemplateTypes } from '../../Page.interface';
import { PAGE_INTERNAL_SLUG_PREFIX, pagePageTemplateConfigMap } from '../../Page.model';

export function PagePageForm({ model, templateOptions, onFinish, onRemove }: PageFormProps) {
  const [form, handleFormUpdate] = useFormForm<IPagePageForm>();
  const template = useMemo(() =>  model?.template || PagePageTemplateTypes.Basic, [model]);
  const EditForm = useMemo(() => pagePageTemplateConfigMap[template]?.Form, [template]);

  const initialValues = useMemo((): IPagePageForm => {
    if (!model) {
      return {
        title: '',
        banner: [],
        content: '',
        template,
        link: '',
        slug: '',
      };
    }

    return {
      title: model.info.title,
      banner: mediaArrayToUploadFile(model.info.attachments || []),
      content: model.info.content,
      template: model.template,
      link: model.link,
      slug: model.slug,
    };
  }, [model, template]);

  const handleFinish = useCallback(async (values: IPagePageForm) => {
    const [attachments, content] = await Promise.all([
      mediaUploadFiles('pages', values.banner),
      richText2SerializeContent('pages', values.content),
    ]);

    const slug = model?.slug || createSlug(featureFlags.isDev
      ? `${PAGE_INTERNAL_SLUG_PREFIX}-${values.slug}`
      : values.title);

    try {
      await onFinish({
        slug,
        attachments: attachments.map((item) => item.link),
        template: values.template,
        link: values.link,
      }, {
        content,
        title: values.title,
      });
    } catch (e) {
      throw new FormCommonError();
    }
  }, [model, onFinish]);

  return (
    <FormForm
      initialValues={initialValues}
      form={form}
      onFinish={handleFinish}
      onRemove={model && onRemove}
    >
      {featureFlags.isDev && (
        <Form.Item
          label="ID"
          name="slug"
          validateTrigger="onBlur"
          rules={[
            formRuleAZ('Допустимы только английские символы и дефис (-)'),
            formRuleRequired('Введите ID'),
          ]}
        >
          <Input
            addonBefore={!model && `${PAGE_INTERNAL_SLUG_PREFIX}-`}
            disabled={!!model}
          />
        </Form.Item>
      )}

      <Form.Item
        label="Тип"
        name="template"
        required
        hidden={!featureFlags.isDev}
      >
        <Select options={templateOptions} disabled={!!model} />
      </Form.Item>

      {EditForm && (
        <EditForm model={model} form={form} initialValues={initialValues} onFormUpdate={handleFormUpdate} />
      )}
    </FormForm>
  );
}
