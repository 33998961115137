import { App } from 'antd';
import { LANGUAGE_DEFAULT } from 'features/Language';
import { LayoutContent } from 'features/Layout';
import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useServiceItemLoad } from '../../../Service/Service.hook';
import { useStationServiceLoad } from '../../StationService.hooks';
import { IStationServiceSimpleForm, StationServiceSimpleCreateParams } from '../../StationService.intefrace';
import { stationServiceSimpleFromBackUrl } from '../../StationService.model';
import { useStationServiceSimpleCreateMutation, useStationServiceSimpleUpdateMutation } from '../../StationServiceSimple.api';
import { StationServiceSimpleForm } from '../StationServiceSimpleForm/StationServiceSimpleForm';

export function StationServiceSimpleCreate() {
  const { message } = App.useApp();
  const navigate = useNavigate();
  const { stationSlug, serviceType, serviceSlug } = useParams<StationServiceSimpleCreateParams>();
  const backUrl = useMemo(() => stationServiceSimpleFromBackUrl(serviceType, serviceSlug, stationSlug), [serviceSlug, serviceType, stationSlug]);
  const { data: service } = useServiceItemLoad(serviceType, serviceSlug);

  const params = useMemo(() => !!service && ({
    station_slug: stationSlug,
    service_type: serviceType,
    service_id: service?.id,
  }), [service, serviceType, stationSlug]);
  const [stationService] = useStationServiceLoad(params);
  const [stationServiceSimpleCreate] = useStationServiceSimpleCreateMutation();
  const [stationServiceSimpleInfoUpdate] = useStationServiceSimpleUpdateMutation();

  const handleFinish = useCallback(async (values: IStationServiceSimpleForm) => {
    const result = await stationServiceSimpleCreate({
      station_service_id: stationService.id,
      simple_service_id: values.simpleId,
    }).unwrap();

    await stationServiceSimpleInfoUpdate({
      station_service_id: result.station_service_id,
      simple_service_id: result.simple_service_id,
      lang: LANGUAGE_DEFAULT,
      title: '',
      content: values.content,
    }).unwrap();

    message.success('Услуга добавлена');
    navigate(backUrl);
  }, [backUrl, message, navigate, stationService, stationServiceSimpleCreate, stationServiceSimpleInfoUpdate]);

  return (
    <LayoutContent
      title="Добавить услугу к флагманской"
      backUrl={backUrl}
      isLoading={!stationService}
      render={() => (
        <StationServiceSimpleForm
          stationService={stationService}
          onFinish={handleFinish}
        />
      )}
    />
  );
}
