import { LinkOutlined } from '@ant-design/icons';
import { App, Button, Form, Input } from 'antd';
import { useCallback } from 'react';
import { Editor } from 'slate';
import { createLinkNode } from './RichText.url';

interface RichTextLinkForm {
  url: string;
  text: string;
}

export function RichTextLinkButton({ editor, onClose }: {
  editor: Editor;
  onClose: Function;
}) {
  const { modal } = App.useApp();
  const [form] = Form.useForm<RichTextLinkForm>();

  const handleClose = useCallback(() => {
    form.resetFields();
    onClose?.();
  }, [form, onClose]);

  const handleSubmit = useCallback((values: RichTextLinkForm) => {
    if (!values.url) {
      handleClose();
      return;
    }

    editor.insertFragment([
      createLinkNode(values.url, values.text),
      { text: ' ' },
    ]);
    handleClose();
  }, [editor, handleClose]);

  const handleShowModal = useCallback(() => {
    modal.info({
      title: 'Добавить ссылку',
      okText: 'Добавить',
      onOk: form.submit,
      closable: true,
      afterClose: handleClose,
      content: (
        <Form form={form} onFinish={handleSubmit}>
          <Form.Item label="URL" name="url">
            <Input placeholder="https://" />
          </Form.Item>
          <Form.Item label="Текст" name="text">
            <Input />
          </Form.Item>
        </Form>
      ),
    });
  }, [form, handleClose, handleSubmit, modal]);

  return (
    <Button
      onClick={handleShowModal}
      icon={<LinkOutlined />}
      title="Добавить ссылку"
      size="small"
      type="text"
    />
  );
}
