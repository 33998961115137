import { withRef } from '@udecode/cn';
import { ELEMENT_IMAGE, ELEMENT_MEDIA_EMBED, useMediaToolbarButton } from '@udecode/plate-media';
import React, { useMemo } from 'react';
import { Icons } from './icons';
import { ToolbarButton } from './toolbar';

const iconMap = {
  [ELEMENT_IMAGE]: {
    Icon: Icons.Image,
    tooltip: 'Картинка',
  },
  [ELEMENT_MEDIA_EMBED]: {
    Icon: Icons.Embed,
    tooltip: 'Ролик',
  },
};

export const MediaToolbarButton = withRef<
  typeof ToolbarButton,
  {
    nodeType?: typeof ELEMENT_IMAGE | typeof ELEMENT_MEDIA_EMBED;
  }
>(({ nodeType, ...rest }, ref) => {
  const { props } = useMediaToolbarButton({ nodeType });
  const { Icon, tooltip } = useMemo(() => iconMap[nodeType], [nodeType]);

  return (
    <ToolbarButton
      tooltip={tooltip}
      ref={ref}
      {...props}
      {...rest}
    >
      <Icon />
    </ToolbarButton>
  );
});
