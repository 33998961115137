import { Plate } from '@udecode/plate-common';
import { useCallback, useMemo } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ISlateDescendant } from 'features/RichText';
import { createElementP, richText2FixP } from './RichText2.model';
import { plugins } from './RichText2.plugins';
import { Editor } from './ui/editor';
import { FixedToolbar } from './ui/fixed-toolbar';
import { FixedToolbarButtons } from './ui/fixed-toolbar-buttons';
import { TooltipProvider } from './ui/tooltip';

export function PlateEditor({ value, onChange, ...props }: {
  value?: string;
  onChange?: (value: string) => any;
}) {
  // parse value
  const initialValue = useMemo((): ISlateDescendant[] => {
    const empty = [createElementP()];
    if (!value) {
      return empty;
    }
    try {
      const result = JSON.parse(value);
      return richText2FixP(result);
    } catch (e) {
      return empty;
    }
  }, [value]);

  const handleChange = useCallback((v: any) => {
    let result = '';
    try {
      result = JSON.stringify(v);
    } catch (e) {
      console.error('error parsing rich text content', e);
    }
    onChange?.(result);
  }, [onChange]);

  return (
    <TooltipProvider>
      <DndProvider backend={HTML5Backend}>
        <Plate
          plugins={plugins}
          initialValue={initialValue}
          onChange={handleChange}
          {...props}
        >
          <FixedToolbar className="mb-0.5">
            <FixedToolbarButtons />
          </FixedToolbar>

          <Editor />
        </Plate>
      </DndProvider>
    </TooltipProvider>
  );
}
