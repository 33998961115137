import { TabsProps } from 'antd';
import { featureFlags } from 'App.environment';
import { LayoutContent } from 'features/Layout';
import { UITabs } from 'features/UI';
import { useMemo } from 'react';
import { PageViewTypes } from '../../Page.interface';
import { PagePageTable } from '../PagePageTable/PagePageTable';

export function PageTable() {
  const tabs = useMemo((): TabsProps['items'] => [
    {
      key: PageViewTypes.Page,
      label: 'Страницы',
      children: (
        <PagePageTable
          type={PageViewTypes.Page}
          isCreateButton
        />
      ),
    },
    {
      key: PageViewTypes.Block,
      label: 'Блоки',
      children: (
        <PagePageTable
          type={PageViewTypes.Block}
          isCreateButton={featureFlags.isDev}
        />
      ),
    },
  ], []);

  return (
    <LayoutContent title="Контент">
      <UITabs
        defaultActiveKey={PageViewTypes.Page}
        items={tabs}
      />
    </LayoutContent>
  );
}
