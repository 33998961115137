import { LayoutContent } from 'features/Layout';
import { DIRECTORATE_SLUG_HEAD } from '../../Directorate.model';
import { DirectorateManagerTable } from '../DirectorateManagerTable/DirectorateManagerTable';

export function DirectorateAdministrationTable() {
  return (
    <LayoutContent title="Руководство">
      <DirectorateManagerTable slug={DIRECTORATE_SLUG_HEAD} />
    </LayoutContent>
  )
}
