import { withRef } from '@udecode/cn';
import { focusEditor, insertEmptyElement, useEditorRef } from '@udecode/plate-common';
import { ELEMENT_OL, ELEMENT_UL, toggleList } from '@udecode/plate-list';
import { ELEMENT_PARAGRAPH } from '@udecode/plate-paragraph';
import React, { useCallback } from 'react';
import { Icons } from './icons';
import { ToolbarButton } from './toolbar';

export const ULButton = withRef<typeof ToolbarButton>((rest, ref) => {
  const editor = useEditorRef();

  const handleCLick = useCallback(() => {
    insertEmptyElement(editor, ELEMENT_PARAGRAPH, {
      select: true,
      nextBlock: true,
    });
    toggleList(editor, {
      type: ELEMENT_UL,
    });
    focusEditor(editor);
  }, [editor]);

  return (
    <ToolbarButton
      onClick={handleCLick}
      ref={ref}
      tooltip="Список"
      {...rest}
    >
      <Icons.UL />
    </ToolbarButton>
  );
});

export const OLButton = withRef<typeof ToolbarButton>((rest, ref) => {
  const editor = useEditorRef();

  const handleCLick = useCallback(() => {
    insertEmptyElement(editor, ELEMENT_PARAGRAPH, {
      select: true,
      nextBlock: true,
    });
    toggleList(editor, {
      type: ELEMENT_OL,
    });
    focusEditor(editor);
  }, [editor]);

  return (
    <ToolbarButton
      onClick={handleCLick}
      ref={ref}
      tooltip="Нумерованный список"
      {...rest}
    >
      <Icons.OL />
    </ToolbarButton>
  );
});
