import { RouteProps } from 'react-router-dom';
import { PageCreate } from './components/PageCreate/PageCreate';
import { PageEdit } from './components/PageEdit/PageEdit';
import { PageTable } from './components/PageTable/PageTable';

export const pageRouter: RouteProps[] = [
  {
    path: '/pages',
    Component: PageTable,
  },
  {
    path: 'pages/:type/create',
    Component: PageCreate,
  },
  {
    path: 'pages/:type/:slug',
    Component: PageEdit,
  },
];
